import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  Button,
  Box
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import useApplication from "hooks/useApplication";
import JobRow from "./components/JobRow";
import PaperBlock from "components/PaperBlock";
import SearchInput from "components/SearchInput";
import TableFooterWithPagination from "components/TableFooterWithPagination";
import useJobs from "hooks/useJobs";
import Swal from "sweetalert2";
import toast from "react-hot-toast";
import useQueue from "hooks/useQueue";
import CreateJobModal from "components/CreateJobModal";

export default function Members() {
  const { setPage } = useApplication();
  const {deleteJob} =  useJobs();
  const { changeTaskUser } = useQueue();
  const [open, setOpen] = useState(false);
  const {
    jobsReducer: { loading, query, results, meta },
    changePage,
    changePerPage,
    getJobs,
  } = useJobs();

  useEffect(() => {
    setPage({
      title: "Jobs Directory",
      path: "/app/jobs",
    });
    getJobs(query);
    // eslint-disable-next-line
  }, []);

  const handleSearch = (value) => {
    getJobs(value);
  };

  const handleChangePage = (_event, newPage) => {
    changePage(newPage);
    getJobs(query, newPage + 1, meta.per_page);
  };

  const handleChangeRowsPerPage = (event) => {
    changePerPage(event);
    getJobs(query, meta.current_page, parseInt(event.target.value));
  };
  const deleteJobData = async (params) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      customClass: {
        title: "swal-text",
        popup:"swal-text"
        
      },
      confirmButtonText: 'Yes, Delete Job'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteJob(params)
        .then(res => {
          getJobs(query);
       
    }).catch(err => {
        
        if (err.response) {
          toast.error('Job Deleted unSuccessful',{
            style: {
              fontFamily: ['Open Sans', 'sans-serif']
            }});
        }
})
      }
    })

    
  }

  return (
    <PaperBlock
      title="Jobs Directory"
      loading={loading}
      actions={
        <>
          <SearchInput
            defaultValue={query}
            onChange={handleSearch}
            placeholder="By ID, First Name, Last Name, Phone or Email"
          />
          <Button
            sx={{ ml: 2, whiteSpace: "nowrap",minWidth: 'auto' }}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setOpen(true)}
          >
            Add Job Request
          </Button>

          {open && (
            <CreateJobModal
              open={open}
              handleClose={() => setOpen(false)}
            />
          )}
        </>
      }
    >
      <Box sx={{overflow:"auto"}}>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>#ID</TableCell>
            <TableCell>Job Type</TableCell>
            <TableCell>Member name</TableCell>
            <TableCell>Member ID</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>Status</TableCell>
            <TableCell  sx={{paddingRight:5}}>Action</TableCell>
            {/* <TableCell align="right"></TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((job) => (
            <JobRow data={job} key={job.id}  deleteJobData={deleteJobData}/>
          ))}
          {!results.length && (
            <TableRow>
              <TableCell colSpan={7} align="center">
                No data
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        {results.length > 0 && (
          <TableFooterWithPagination
            count={meta.total}
            rowsPerPage={meta.per_page}
            page={meta.current_page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Table>
      </Box>
    </PaperBlock>
  );
}
