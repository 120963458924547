import React, { useEffect, useState } from "react";
import { Tab, Box } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import PaperBlock from "components/PaperBlock";
import useApplication from "hooks/useApplication";
import ServiceLocationsIndex from "./ServiceLocations/Index";
import PhoneNumbers from "./PhoneNumbers";

const Settings = () => {
  const [tabValue, setTabValue] = useState("0");
  const { setPage } = useApplication();

  useEffect(() => {
    setPage({
      title: "Settings",
      path: "/app/settings",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PaperBlock
        title="Settings"
      >
        <Box sx={{ flexGrow: 1, bgcolor: "background.paper" }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={(_event, newValue) => setTabValue(newValue)}>
                <Tab
                  label="Service Locations"
                  value="0"
                />
                {/* <Tab
                  label="Call Departments"
                  value="1"
                /> */}
              </TabList>
            </Box>
            <TabPanel value="0">
              <ServiceLocationsIndex />
            </TabPanel>
            <TabPanel value="1">
              <PhoneNumbers />
            </TabPanel>
          </TabContext>
        </Box>
      </PaperBlock>
    </>
  );
};

export default Settings;
