import React from "react";

import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CsvViewer from "components/FileManager/CsvViewer";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const FileViewer = ({ handleClose, file }) => {
  return (
    <Dialog
      onClose={handleClose}
      open={true}
      fullScreen
      TransitionComponent={Transition}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        color="primary"
      >
        <Box>
          <Typography fontWeight="bold" variant="subtitle1">
            {file.label}
          </Typography>
        </Box>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ textAlign: "center" }}>
        {file.content_type === "text/csv" ||
        file.content_type === "text/plain" ? (
          <CsvViewer file={file} />
        ) : (
          <object
            aria-label="Preview"
            data={`${process.env.REACT_APP_API_DOMAIN}/api/v1/file-preview/${file.uuid}`}
            type={file.content_type}
            width={file.content_type === "application/pdf" ? "100%" : "auto"}
            height="100%"
            style={{ overflow: "auto" }}
          ></object>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default FileViewer;
