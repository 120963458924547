import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ".././../../../utils/sweetalert.css"
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Paper,
  Box
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";
import useApplication from "hooks/useApplication";
import useVendors from "hooks/useVendors";
import PaperBlock from "components/PaperBlock";
import VendorBlocks from "components/VendorBlocks";
import SearchInput from "components/SearchInput";
import TableFooterWithPagination from "components/TableFooterWithPagination";
import VendorRow from "./components/VendorRow";
import { deleteVendorRequest } from "api/vendors";
import '../../../../utils/sweetalert.css'
import useAccessControl from "hooks/useAccessControl";

export default function Vendors() {
  const { setPage } = useApplication();
  const { 
    canCreateVendor } =  useAccessControl()
  
  const navigate = useNavigate();
  const {
    vendorsReducer: { results, query, loading, meta },
    getVendors,
    search,
    changePage,
    changePerPage,
  } = useVendors();

  useEffect(() => {
    setPage({
      title: "Vendors Directory",
      path: "/app/vendors",
    });
    search(query);
    // eslint-disable-next-line
  }, []);

  const handleSearch = (searchValue) => {
    search(searchValue);
  };

  const handleChangePage = (_event, newPage) => {
    changePage(newPage);
    getVendors({ search: query, page: newPage + 1, per_page: meta.per_page });
  };

  const handleChangeRowsPerPage = (event) => {
    changePerPage(event);
    getVendors({
      search: query,
      page: meta.current_page,
      per_page: parseInt(event.target.value),
    });
  };

  const onDeleteHandler = (venodrId) => {
    Swal.fire({
      title: "Delete vendor?",
      text: "You won't be able to revert this!",
      icon: "warning",
      customClass: {
        title: "swal-text",
        popup:"swal-text"
        
      },
      fontFamily: "Open Sans",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteVendorRequest(venodrId)
          .then(() => {
            search();
          })
          .catch((e) => {});
      }
    });
  };

  return (
    <VendorBlocks
      title="Vendors"
      loading={loading}
      actions={
        <Box display="flex" justifyContent="space-between" alignItems="center" className="memberSearchBox">
          <SearchInput
            defaultValue={query}
            onChange={handleSearch}
            placeholder="By ID, First Name, Last Name, Phone or Email"
          />
          { canCreateVendor() &&
            <Button
            sx={{ ml: 2, whiteSpace: "nowrap",minWidth: 'auto' }}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => navigate("/app/vendors/create")}
          >
            Add Vendor
          </Button>
          }
        </Box>
      }
    >
      {/* <Paper> */}
      <div style={{overflow:"auto"}}>
      <Table  size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>#ID</TableCell>
            <TableCell>Alpha</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Last check</TableCell>
            <TableCell>Last voucher</TableCell>
            <TableCell >Action</TableCell>
            {/* <TableCell align="right"></TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((user, index) => (
            <VendorRow
              key={user.id}
              rowNumber={(meta.current_page - 1) * meta.per_page + index + 1}
              data={user}
              onDelete={onDeleteHandler}
            />
          ))}
          {!results.length && (
            <TableRow>
              <TableCell colSpan={9} align="center">
                No data
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        {results.length > 0 && (
          <TableFooterWithPagination
            count={meta.total}
            rowsPerPage={meta.per_page}
            page={meta.current_page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Table>
      </div>
      {/* </Paper> */}
    </VendorBlocks>
  );
}
