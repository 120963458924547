import React from "react";
import { Box } from "@mui/system";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Badge,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

import Icon from "@mdi/react";
import { mdiChevronDown } from "@mdi/js";
import { useNavigate } from "react-router-dom";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import Popup from "components/Popup";
import useAuth from "hooks/useAuth";
import useStyles from "../Header/header-jss";

const UserMenu = ({ app }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const accountPopupRef = React.useRef(null);

  const handleClose = () => {
    accountPopupRef.current.hidePopup();
  };

  const handleClickUserProfile = () => {
    handleClose();
    navigate("/app/profile");
  };

  const handleLogout = () => {
    logout("/");
  };

  return (
    <div className={classes.userMenuRoot}>
      <IconButton
        color="inherit"
        className={classNames(
          classes.notifIcon,
          app.dark ? classes.dark : classes.light
        )}
      >
        <Badge>
          <i className="ion-ios-notifications-outline" />
        </Badge>
      </IconButton>
      <Hidden xsDown>
        <span className={classes.separatorV} />
      </Hidden>
      <Box
        ml={1}
        display="flex"
        alignItems="center"
        onClick={(event) => accountPopupRef.current.displayPopup(event)}
        className={classes.accountName}
      >
        <Avatar style={{ background: user.data.bgColor }}>{user.data.first_name[0]} {user.data.last_name[0]}</Avatar>&nbsp;
        <Typography
          className={classes.userNameText}
          component="p"
          variant="body1"
        >
          {`${user.data.first_name} ${user.data.last_name}`}
        </Typography>
        <Icon path={mdiChevronDown} size={0.8} color="primary" />
      </Box>

      <Popup ref={accountPopupRef}>
        <List component="nav">
          <ListItem button onClick={handleClickUserProfile}>
            <ListItemIcon className={classes.menuIcon}>
              <PermIdentityIcon />
            </ListItemIcon>
            <ListItemText primary={t("dashboard:user_profile_text")} />
          </ListItem>
          <ListItem button onClick={handleLogout}>
            <ListItemIcon className={classes.menuIcon}>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={t("dashboard:log_out_text")} />
          </ListItem>
        </List>
      </Popup>
    </div>
  );
};

export default UserMenu;
