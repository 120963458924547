import React from "react";
import Grid from "@mui/material/Grid";
import Swal from "sweetalert2";
import { useTheme } from "@mui/material/styles";
import AddNew from "components/AddNew";
import PropertyUpdate from "components/MembershipForm/FormParts/PropertyUpdate";
import useMembership from "hooks/useMembership";
import ".././../../../../../utils/sweetalert.css"

const special = ["1st", "2nd", "3rd", "4th", "5th"];

const PropertiesList = ({ form, setForm, addProperty }) => {
  const theme = useTheme();
  const { deleteMembershipProperty } = useMembership();
  const handleRemoveProperty = (propertyId) => {
    deleteMembershipProperty({ id: propertyId }).then(() => {
      removePropertyById(propertyId);
      Swal.fire({
        title: "Success",
        text: "Property successfully deleted!",
        customClass: {
          title: "swal-text",
          popup:"swal-text"
          
        },
        confirmButtonColor: theme.palette.primary.main,
      });
    });
  }

  const removePropertyById = (id) => {
    const properties = [...form.properties];
    const removeIndex = properties.findIndex(property => property.id === id);
    if (removeIndex) {
      properties.splice(removeIndex, 1);
      setForm({ properties });
    }
  };

  return (
    <Grid container pt={2} spacing={2}>
      {form.properties.map((property, index) => (
        <PropertyUpdate
          key={`property-${index}`}
          index={index}
          property={property}
          form={form}
          setForm={setForm}
          onRemove={() => handleRemoveProperty(property.id)}
          defaultLocation={{
            city: {
              id: form.properties[0].city.id,
              city: form.properties[0].city.label,
            },
            state: { code: form.properties[0].state },
            zip: form.properties[0].zip,
          }}
        />
      ))}
      {form.properties.length < 5 && (
        <Grid item md={12} sm={12} xs={12}>
          <AddNew
            onClick={addProperty}
            label={`Add ${special[form.properties.length]} Property Details`}
            nomargin
          />
        </Grid>
      )}
    </Grid>
  );
};

export default PropertiesList;
