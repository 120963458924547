import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

import { Typography, Button } from "@mui/material";
import { invoiceStatusColors } from "utils/statusColors";
import { useNavigate } from "react-router-dom";

import columns from "./tableData";
import useStyles from "./styles";

const Payments = ({ invoices }) => {
  const classes = useStyles();
  const [payments, setPayments] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const pmt = invoices.filter((invoice) => invoice.status === "PAID");
    const paymentsList = [];
    if (pmt.length > 0) {
      for (let i = 0; i < pmt.length; i++) {
        for (let j = 0; j < pmt[i].payments.length; j++) {
          paymentsList.push(pmt[i].payments[j]);
        }
      }
      setPayments(paymentsList);
    }
  }, [invoices]);

  const renderStatus = (data) => {
    return (
      <Typography
        style={{ backgroundColor: invoiceStatusColors[data.row.status] }}
        className={classes.status}
      >
        {data.row.status}
      </Typography>
    );
  };

  const renderAction = (data) => (
    <Button
      size="small"
      onClick={() => navigate(`/app/invoices/${data.row.invoice_id}`)}
    >
      View
    </Button>
  );

  return (
    <React.Fragment>
      <DataGrid
        rows={payments}
        columns={columns(renderStatus, renderAction)}
        disableSelectionOnClick
        autoHeight
        className={classes.dataGrid}
        density="compact"
      />
    </React.Fragment>
  );
};

export default Payments;
