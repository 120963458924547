import React, { useState } from "react";
import { Box, MenuItem, Typography } from "@mui/material";
import FormField from "components/FormField";
import useUtils from "hooks/useUtils";
import { viewMembershipRequest } from "api/membership";

const Members = ({ contact, property, setState, setMembershipId }) => {
  const { utils } = useUtils();

  const handleAddJobClick = (id) => {
    viewMembershipRequest(id).then((res) => {
      setState({
        contact: res.data.members[0],
        contact_id: res.data.members[0].id,
        property: res.data.properties,
        property_id: res.data.properties[0].id,
      });
      setMembershipId(id);
    });
  };

  return (
    <>
      <Box pb={2} pt={2}>
        <FormField
          required
          label="Member Contact"
          select
          // value={contact.id}
          // onChange={handleChange}
        >
          {utils.members && utils.members.map((item) => (
            <MenuItem value={item.id} key={item.id} onClick={() => handleAddJobClick(item.id)}>
              <strong>{item.name}</strong>{'('+item.email+ ')'}
            </MenuItem>
          ))}
        </FormField>
      </Box>
      {contact && (
        <Box pl={1} pb={2}>
          {contact.first_name && (<Typography gutterBottom variant="body2" >{`${contact.first_name} ${contact.last_name} / (No. ${contact.id})`}</Typography>)}
          {contact.email && (<Typography
            gutterBottom
            variant="body2"
          >{`${contact.email}`}</Typography>)}
          {contact.cell_phone && (<Typography
            gutterBottom
            variant="body2"
          >{`${contact.cell_phone}`}</Typography>)}
        </Box>
      )}

      <Box pb={2} pt={2}>
        <FormField
          required
          label="Property Address"
          select
          // value={property_id}
          // onChange={handleChange}
        >
          {property && Array.isArray(property) && property.map((item) => (
            <MenuItem key={`property-${item.id}`} value={item.id}>
              {item.address}
            </MenuItem>
          ))}
        </FormField>
      </Box>
    </>
  );
};

export default Members;
