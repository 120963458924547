import axios from "utils/axios";

const ROLES_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/roles`;

const getRolesRequest = (data) => axios.get(ROLES_ROUTE, data);

const createRolesRequest = (data) => axios.post(`${ROLES_ROUTE}/create`, data);

const updateRolesRequest = (data) => axios.post(`${ROLES_ROUTE}/update`, data);

export { getRolesRequest, createRolesRequest, updateRolesRequest };
