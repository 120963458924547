import {
  CHANGE_MODE,
  LOAD_PAGE,
  SET_ACTIVE_PAGE,
  TOGGLE_DRAWER,
} from "redux/actions";

const initialState = {
  color: "hoc",
  mode: "white",
  sidebarOpen: true,
  pageLoaded: false,
  activePage: {
    title: "",
    path: "/app",
  },
};

export default function ui(state = initialState, action = {}) {
  switch (action.type) {
    case CHANGE_MODE:
      return { ...state, mode: action.mode };
    case LOAD_PAGE: {
      return { ...state, pageLoaded: action.isLoaded };
    }
    case TOGGLE_DRAWER: {
      return { ...state, sidebarOpen: !state.sidebarOpen };
    }
    case SET_ACTIVE_PAGE: {
      return { ...state, activePage: action.data };
    }
    default:
      return state;
  }
}
