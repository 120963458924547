import { makeStyles } from "@mui/styles";

const rootWraper = {
  display: "flex",
  width: "100%",
  zIndex: 1,
  position: "relative",
};

const wrapper = (theme) => ({
  padding: theme.spacing(3),
  textAlign: "center",
  backgroundColor: theme.palette?.background?.paper,
  backgroundRepeat: "no-repeat",
  color: theme.palette.text.primary,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundAttachment: "fixed",
});

const styles = makeStyles((theme) => ({
  root: {
    ...rootWraper,
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      overflow: "hidden",
    },
  },
  userFormWrap: {
    maxWidth: 650,
    [theme.breakpoints.up("md")]: {
      width: 650,
    },
    [theme.breakpoints.down("sm")]: {
      with: "98%",
      marginBottom: theme.spacing(3),
    },
  },
  outer: {},
  brand: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px 10px",
    position: "relative",
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.primary,
    textDecoration: "none",
    "&$outer": {
      color: theme.palette?.common?.white,
    },
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(2),
    },
    "& img": {
      marginRight: 10,
    },
  },
  paperWrap: {
    ...wrapper(theme),
  },
  topBar: {
    display: "flex",
    justifyContent: "center",
    "& $icon": {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      marginBottom: theme.spacing(3),
      "& a": {
        display: "none",
      },
    },
  },
  buttonLink: {
    background: "none",
    padding: 0,
    textTransform: "none",
    transition: "color ease 0.3s",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: "0.875rem",
    "&:hover": {
      background: "none",
      color: theme.palette.secondary.main,
    },
  },
  title: {
    color: theme.palette.primary.main,
    marginTop: -10,
  },
  subtitle: {
    fontSize: 14,
  },
  formWrap: {
    [theme.breakpoints.up("sm")]: {
      padding: "0 30px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "0 30px",
    },
  },
  formControl: {
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  optArea: {
    display: "flex",
    justifyContent: "space-between",
    padding: `0 ${theme.spacing(0.5)}px`,
  },
  btnArea: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: 30,
    margin: `${theme.spacing(2)}px 0`,
    fontSize: 12,
    "& $label": {
      fontSize: 12,
      "& span": {
        fontSize: 12,
      },
    },
    "& button": {
      margin: `0 ${theme.spacing(1)}px`,
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      "& button": {
        width: "100%",
        margin: 5,
      },
    },
  },
  iconSmall: {
    fontSize: 20,
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  link: {
    fontSize: "0.875rem",
    color: theme.palette.secondary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  inputIcon: {
    marginRight: 10,
  },
}));

export default styles;
