import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Chip,
  Tab
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useLocation, useParams } from "react-router-dom";
import Refund from "pages/Refund/Index";
import Payments from "./Payments";
import BreadCrumb from "components/BreadCrumb/BreadCrumb";
import useApplication from "hooks/useApplication";

const Paymentsvalue = () => {
  const { setPage } = useApplication();
  const [tabValue, setTabValue] = useState(0);
  useEffect(() => {
    setPage({
      title: "payments",
      path: "/app/payments",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
    <BreadCrumb />
      <Box display="flex" alignItems="center">
        <Typography variant="h5"
						component="h5"
						fontWeight="bold"
						color="primary">
          {/* {state.invoice.description} No. {state.invoice.id} */}
          Payments
        </Typography>
      </Box>
      <Paper sx={{ p: 2, mt: 2 }} elevation="0">
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={(_event, newValue) => setTabValue(newValue)}>
              <Tab
                label="Payments"
                value={0}
              />
              <Tab label="Refunds" value={1} />
            </TabList>
          </Box>
          <TabPanel value={0}>
                  <Payments/>
          </TabPanel>
          <TabPanel value={1}>
            <Refund
            />
          </TabPanel>
        </TabContext>
      </Paper>
    </>
  );
};

export default Paymentsvalue;
