import React, { useEffect, useState } from "react";

import {
  Grid,
  Typography,
  Box,
  Chip,
  Button,
  CircularProgress,
  Alert,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import TaskStatus from "./TaskStatus";
import useQueue from "hooks/useQueue";
import { getContractorByEmail } from "api/contractors";
import JobPickerTable from "components/JobPickerTable";
import ContractorSelect from "components/ContractorSelect";
import { linkEstimateRoute } from "api/tasks";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import FileViewer from "components/FileViewer";
import "../../../utils/sweetalert.css"
const MessageToEstimate = ({
  data,
  handleChange,
  mail,
  handleClose,
  getTasks,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [noCt, setNoCt] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setNewEstimate, newEstimate } = useQueue();
  const [viewFile, setViewFile] = useState(false);

  useEffect(() => {
    setNewEstimate();
    getContractorByEmail(mail.from).then((res) => {
      if (res.data.contractor) {
        setNewEstimate("contractor", res.data.contractor);
      } else {
        setNoCt(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.task_type]);

  const renderAttachments = (attachments) => {
    return attachments.map((attachment) => {
      return (
        <Box key={attachment.id} m={1}>
          <Chip
            label={attachment.label}
            onClick={() => setViewFile(attachment)}
          />
        </Box>
      );
    });
  };

  const LinkEstimate = () => {
    setLoading(true);
    linkEstimateRoute({
      job_id: newEstimate.job_id,
      contractor_id: newEstimate?.contractor?.id,
      task_id: data.id,
    })
      .then(() => {
        Swal.fire({
          title: "Success",
          text: "Estimate was linked with the job #" + newEstimate.job_id,
          timer: 2000,
          customClass: {
            title: "swal-text",
            popup:"swal-text"
            
          },
          confirmButtonColor: theme.palette.primary.main,
          zIndex: 9999,
          showConfirmButton: false,
        });
        getTasks();
        handleClose();
        setLoading(false);
        navigate(`/app/jobs/${newEstimate.job_id}`);
      })
      .catch((e) => {
        getTasks();
        handleClose();
      });
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={8} md={9}>
        {newEstimate.contractor && (
          <Typography fontWeight="bold" variant="body2">
            <b>{newEstimate.contractor.name}</b> -{" "}
            <b>{newEstimate.contractor.email}</b>
            <br />
            <b>{newEstimate.contractor.phone}</b>
            <br />
          </Typography>
        )}
        {noCt && (
          <Box pt={2}>
            {!newEstimate.contractor && (
              <Alert severity="warning">
                We could not detect a contractor by the email address of the
                person who sent this email. Please choose a contractor below:
              </Alert>
            )}

            <ContractorSelect
              selected={newEstimate.contractor?.id}
              handleChange={(contractor) =>
                setNewEstimate("contractor", contractor)
              }
            />
          </Box>
        )}
        <Box>
          <Typography pt={2} pb={2} fontWeight="bold" variant="body2">
            Subject: {mail.subject}
          </Typography>
          <Box
            border="solid 1px #ddd"
            maxHeight={300}
            p={2}
            style={{ overflow: "auto" }}
            pt={2}
            dangerouslySetInnerHTML={{ __html: mail.content }}
          />
          {mail.attachments.length > 0 && (
            <>
              <Typography
                fontWeight="bold"
                pt={2}
                variant="body2"
                component="p"
              >
                Attachments:
              </Typography>
              <Box mt={1}>{renderAttachments(mail.attachments)}</Box>
            </>
          )}
          <JobPickerTable
            value={newEstimate.job_id}
            handleChange={(job_id) => setNewEstimate("job_id", job_id)}
          />
        </Box>
      </Grid>
      <Grid item sm={4} md={3}>
        <TaskStatus data={data} handleChange={handleChange} />
      </Grid>
      <Grid item sm={12} md={12}>
        <Box
          mt={3}
          mb={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {!loading && (
            <Button
              disabled={!newEstimate.job_id || !newEstimate.contractor}
              onClick={() => LinkEstimate()}
              variant="contained"
            >
              Link Estimate
            </Button>
          )}
          {loading && <CircularProgress />}
        </Box>
      </Grid>
      {viewFile && (
        <FileViewer handleClose={() => setViewFile(false)} file={viewFile} />
      )}
    </Grid>
  );
};

export default MessageToEstimate;
