import React from "react";
import { Box, Typography, Link, List, ListItem } from "@mui/material";

export default function MembershipInformationComponent() {
  return (
    <>
      <Typography variant="h6">Membership Information</Typography>
      <Box mb={2} mt={1}>
        <List dense disablePadding>
          <ListItem disableGutters sx={{ mb: 1 }}>
            <Typography
              variant="body2"
              component="span"
              fontWeight="bold"
              sx={{ mr: 1 }}
            >
              Primary Contact:
            </Typography>
            <Typography variant="body2" component="span" fontWeight="normal">
              Jim Tafur
            </Typography>
          </ListItem>
          <ListItem disableGutters>
            <Typography
              variant="body2"
              fontWeight="bold"
              component="span"
              sx={{ mr: 1 }}
            >
              Phone:
            </Typography>
            <Typography variant="body2" component="span" fontWeight="normal">
              205-555-1111
            </Typography>
          </ListItem>
          <ListItem disableGutters>
            <Typography
              variant="body2"
              component="span"
              fontWeight="bold"
              sx={{ mr: 1 }}
            >
              Email:
            </Typography>
            <Typography variant="body2" component="span" fontWeight="normal">
              email@email.com
            </Typography>
          </ListItem>
        </List>
      </Box>
      <Link href="#" underline="none">
        View profile
      </Link>
    </>
  );
}
