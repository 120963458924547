import React, { useState } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LockIcon from "@mui/icons-material/Lock";

// styles
import useStyles from "./styles";

const PasswordTextField = ({
  variant,
  name,
  label,
  value,
  onChange,
  error,
  helperText,
  fullWidth,
  withPreview,
  autoComplete,
  placeholder,
  size,
  color,
}) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  return (
    <TextField
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      type={showPassword ? "text" : "password"}
      variant={variant}
      error={error}
      color={color}
      fullWidth={fullWidth}
      helperText={helperText}
      autoComplete={autoComplete}
      placeholder={placeholder}
      size={size}
      InputProps={
        withPreview && {
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon color="secondary" className={classes.inputIcon} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword((prevPass) => !prevPass)}
              >
                {showPassword ? (
                  <VisibilityOff color="secondary" />
                ) : (
                  <Visibility color="secondary" />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }
      }
    />
  );
};

PasswordTextField.defaultProps = {
  variant: "outlined",
};

export default PasswordTextField;
