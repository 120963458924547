import { makeStyles } from "@mui/styles";

const rootWraper = {
  display: "flex",
  width: "100%",
  zIndex: 1,
  position: "relative",
};

const wrapper = (theme) => ({
  padding: theme.spacing(3),
  textAlign: "center",
  backgroundColor: theme.palette?.background?.paper,
  backgroundRepeat: "no-repeat",
  color: theme.palette.text.primary,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundAttachment: "fixed",
});

const styles = makeStyles((theme) => ({
  root: {
    ...rootWraper,
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      overflow: "hidden",
    },
  },
  userFormWrap: {
    maxWidth: 850,
    [theme.breakpoints.up("md")]: {
      width: 900,
    },
    [theme.breakpoints.down("sm")]: {
      with: "95%",
      marginBottom: theme.spacing(3),
    },
  },
  paperWrap: {
    ...wrapper(theme),
  },
  jobRequestWrap: {
    color: theme.palette.text.primary,
    height: "95vh",
    overflow: "auto",
  },
  headerSection: {
    backgroundColor: "#eeede9",
    padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
  },
  formWrap: {
    padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
  },
  brandColor: {
    color: theme.palette.primary.main,
  },
  title: {
    paddingBottom: theme.spacing(1),
  },
  sectionTitle: {
    fontWeight: "bold",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  formSection: {
    paddingBottom: theme.spacing(2),
  },
}));

export default styles;
