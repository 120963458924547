import React from "react";
import { TableRow, TableCell, IconButton } from "@mui/material";
import { sortableHandle } from "react-sortable-hoc";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

const DragHandle = sortableHandle(() => (
  <IconButton>
    <DragIndicatorIcon />
  </IconButton>
));

export default function BillingTableRowComponent({
  service,
  index,
  onDelete,
  withActions,
}) {
  return (
    <TableRow
      key={`xservice-item-${index}`}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      {withActions && (
        <TableCell component="th" scope="row" width="5%">
          <DragHandle />
        </TableCell>
      )}
      <TableCell component="th" scope="row" width="5%">
        {index + 1}
      </TableCell>
      <TableCell>{service.name}</TableCell>
      <TableCell>{service.description}</TableCell>
      {/* <TableCell>{`${service.due}% of `}</TableCell> */}
      <TableCell>{parseInt(service.qty)}</TableCell>
      <TableCell>{`$${(service.amount / 100).toFixed(2)}`}</TableCell>
      {withActions && (
        <TableCell
          component="th"
          scope="row"
          sx={{
            px: 1,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <IconButton onClick={() => onDelete(index)}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  );
}

BillingTableRowComponent.defaultProps = {
  withActions: false,
};
