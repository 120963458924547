import React, {
  useEffect,
  useState,
  useMemo,
  forwardRef,
  useImperativeHandle
} from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  CircularProgress,
  Box,
} from "@mui/material";
import useRoles from "hooks/useRoles";
import RolesManagementDialog from "./RolesManagementDialog";
import useUtils from "hooks/useUtils";
import useAccessControl from "hooks/useAccessControl";

const RolesList = forwardRef((_props, ref) => {
  const { getRoles } = useRoles();
  const { utils } = useUtils();
  const [roles, setRoles] = useState([]);
  const [selectedRole, selectRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const { canUpdateRole } = useAccessControl();

  useImperativeHandle(ref, () => ({
    setRoles: (roles) => setRoles(roles),
    roles,
  }));

  useEffect(() => {
    getRoles().then((res) => {
      setRoles(res.data);
      setLoading(false);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    selectRole(null);
    setOpen(false);
  };

  const handleOpen = (roleId) => {
    setOpen(true);
    selectRole(roleId);
  };

  const recommendationsTable =
  useMemo(
    () => {
      return (
        <TableBody>
          {roles.map((role, index) => (
            <TableRow
              key={role.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {index + 1}
              </TableCell>
              <TableCell component="th" scope="row">
                {role.name}
              </TableCell>
              <TableCell component="th" scope="row">
                {`${role?.permissions?.length} / ${utils?.permissions?.length}`}
              </TableCell>
              <TableCell align="right">
                {
                  canUpdateRole() &&  
                  <Button onClick={() => handleOpen(role.id)} variant="outlined">
                  Edit
                </Button>
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [roles]
  );

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <TableContainer sx={{ width: "100%" }}>
        <Table aria-label="simple table" sx={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCell width="10">NO.</TableCell>
              <TableCell width="200">Name</TableCell>
              <TableCell>Permissions</TableCell>
              <TableCell align="right" sx={{paddingRight:5}}>Action</TableCell>
            </TableRow>
          </TableHead>
          {recommendationsTable}
        </Table>
      </TableContainer>
      <RolesManagementDialog
        setRoles={setRoles}
        open={open}
        role={roles.find((role) => role.id === selectedRole)}
        onClose={handleClose}
      />
    </>
  );
});

export default RolesList;
