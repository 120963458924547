import React from "react";
import { TableRow, TableCell, Button } from "@mui/material";

const ServiceLocationRow = ({ rowNumber, data, onDelete, onEdit }) => {

  return (
    <TableRow key={data.id}>
      <TableCell component="th" scope="row" width="5%">
        {rowNumber}
      </TableCell>
      <TableCell component="th" scope="row">
        {data.name}
      </TableCell>
      <TableCell component="th" scope="row">
        {data?.city?.city}
      </TableCell>
      <TableCell component="th" scope="row">
        {data.enabled ? 'Yes' : 'No'}
      </TableCell>
      <TableCell align="right">
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => onEdit(data)}
        >
          Edit
        </Button>
        <Button
          sx={{ ml: 1 }}
          variant="contained"
          color="error"
          size="small"
          onClick={() => onDelete(data.id)}
        >
          Delete
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default ServiceLocationRow;
