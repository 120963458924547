import { useState, useContext } from "react";
import CallPending from "../components/CallPending";
import RingCentralContext from "./RingCentralContext";
import InCall from "WebR/components/InCall";

const initialState = {
    platform: null,
    extension: null,
    callData: {
      call_id: null,
      mode: "widget",
      state: null,
      type: "",
      //phoneNo: "+12488178889",
      phoneNo: "",
      contact: "",
      isUnknown: false,
      session: null,
      memberId: null,
      muted: false,
      onHold: false,
      recording: false,
      volume: 0.3,
      toVoicemail:false,
      toForward:false
    },
    webPhone: null,
    error: null,
    loading: false,
    user: {},
    states:"offline"
  }

const Provider = ({ children }) => {
const [ringCentral, _setRingCentral] = useState({...initialState});
const [callValue, setcallValue] = useState({
  type: 1,
});


  const setRingCentral = (values) => {
    _setRingCentral((prevRingCentral) => ({
      ...prevRingCentral,
      ...values,
    }));
  }

  const setCall = async (values) => {
    const newCall = await { ...ringCentral.callData, ...values };
    setRingCentral({ callData: newCall });
  };


  const reset = () => {
    setRingCentral({...initialState});
  };
  const updateCallData = (values) => {
    setcallValue(values);
  };

  return (
    <RingCentralContext.Provider
      value={{
        callService: ringCentral,
        setCallService: setRingCentral,
        setCall,
        reset,
        callValue,
        updateCallData
      }}
    >
      {children}
      <CallPending />
      <InCall/>
      <video id="remoteVideo" hidden="hidden"></video>
      <video id="localVideo" hidden="hidden" muted="muted"></video>
    </RingCentralContext.Provider>
  );
};

export default Provider;

export function useRcState() {
  const callData = useContext(RingCentralContext);
  if (!callData) {
    throw new Error("useRcState must be used within a RingCentralContext");
  }
  return callData;
}
