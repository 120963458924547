import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TableRow, TableCell, Button } from "@mui/material";
import dayjs from "dayjs";
import { callStausColors } from "utils/queue";
import axios from 'axios';
import '../../Callincoming/index.css'

const ContractorRow = ({ data, data11, accessToken }) => {
  const navigate = useNavigate();

  const [audioSrc, setAudioSrc] = useState(null);

  useEffect(() => {
    fetchAudio(data?.attachments[0]?.uri, accessToken);
  }, []);

  async function fetchAudio(uri, token) {
    const response = await fetch(uri, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    setAudioSrc(url);
  }

  return (
    <>
      <TableRow>
        <TableCell component="th" scope="row" width="20%">
          <p> {data.from?.name || 'N/A'}</p>
        </TableCell>
        <TableCell component="th" scope="row" width="20%">
          <p>{data.from?.phoneNumber || 'N/A'}</p>lastModifiedTime
        </TableCell>
        <TableCell component="th" scope="row" width="20%">
          <p>{data?.messageStatus || 'N/A'}</p>
        </TableCell>
        <TableCell component="th" scope="row" width="20%">
          {audioSrc && <audio controls src={audioSrc} />}
        </TableCell>
        <TableCell component="th" scope="row" width="20%">
         {/* <p>{data.creationTime}</p>  */}
        </TableCell>
      </TableRow>
    </>
  );
};

export default ContractorRow;


