import React from "react";
import { useNavigate } from "react-router-dom";
import { TableRow, TableCell, Button, Chip, CircularProgress } from "@mui/material";
import { membershipColors } from "utils/membership";
import useAccessControl from "hooks/useAccessControl";
import ServiceLocationManagementDialog from "pages/App/Settings/ServiceLocations/ServiceLocationManagementDialog";
import MemberDialog from "./MemberDialog";

const MemberRow = ({ data, loading }) => {
  const navigate = useNavigate();
  const { canUpdateMember } = useAccessControl();

  const [state, _setState] = React.useState({
    open: false,
  });
  const setState = (newState) => _setState({ ...state, ...newState });
  // React.useEffect(() => {

  // }, [data]);
  return (
    <>
      {loading ? ( // Display loader when data is being fetched
        <CircularProgress style={{ margin: "20px auto", display: "block" }} />
      ) : (
        <TableRow key={data.uuid}>
          <TableCell component="th" scope="row">
            {data.id}
          </TableCell>
          <TableCell component="th" scope="row">
            {data.name}
          </TableCell>
          <TableCell component="th" scope="row">
            {data.address}
          </TableCell>
          <TableCell component="th" scope="row">
            {data.cell_phone}
          </TableCell>
          <TableCell component="th" scope="row">
            {data.email}
          </TableCell>
          <TableCell component="th" scope="row">
            <Chip
              sx={{
                background: membershipColors[data.status],
                color: "white",
              }}
              label={data.status}
            />
          </TableCell>
          <TableCell align="right" >
            <div style={{ display: 'flex', mt: 1.5 }}>
            {canUpdateMember() && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                sx={{ mr: 0.5, ml: 1 }}
                onClick={() => navigate(`/app/members/${data.membership_id}`)}
              >
                View
              </Button>
            )
            }
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={() => setState({ open: true })}
            >
              Delete
            </Button>
            </div>
          </TableCell>
          <MemberDialog
            serviceLocation={state.serviceLocation}
            data={data}
            open={state.open}
            onClose={() => setState({ open: false, serviceLocation: null })}
          />
        </TableRow>
      )}
    </>
  );
};

export default MemberRow;
