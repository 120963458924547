import React from "react";
import { Typography, Box, Divider, Grid, Button } from "@mui/material";
import useStyles from "./style-jss";
import TaskStatus from "./TaskStatus";

const JobTask = ({ data, handleClose, handleChange, setJob }) => {
  const classes = useStyles();
  const { taskable: task } = data;
  return (
    <Grid container spacing={2}>
      <Grid item sm={8} md={9}>
        <Typography className={classes.jobTaskTitle} variant="h6">{`NO. ${
          task.id
        } - ${
          task.code_id
            ? task.code.description
            : task.id + " - Website Job Request"
        }`}</Typography>

        <Box>
          {task.contact && (
            <>
              <Typography
                paddingTop={1}
                fontWeight="bold"
                fontSize={14}
                color="darkgrey"
                component={"p"}
                variant="caption"
              >
                Member Contact
              </Typography>
              <Divider width={200} />
              <Typography
                pt={1}
                variant="body2"
              >{`${task.contact.first_name} ${task.contact.last_name} (No. ${task.contact.id})`}</Typography>
              <Typography variant="body2">{`${task.contact.email}`}</Typography>
              <Typography variant="body2">{`${task.contact.cell_phone}`}</Typography>
            </>
          )}
          {!task.contact && task.draft && (
            <>
              <Typography
                paddingTop={1}
                fontWeight="bold"
                fontSize={14}
                color="darkgrey"
                component={"p"}
                variant="caption"
              >
                Primary Contact
              </Typography>
              <Divider width={200} />
              <Typography
                pt={1}
                variant="body2"
              >{`${task.draft.first_name} ${task.draft.last_name}`}</Typography>
              {task.draft.contact_first && (
                <>
                  <Typography
                    paddingTop={1}
                    fontWeight="bold"
                    fontSize={14}
                    color="darkgrey"
                    component={"p"}
                    variant="caption"
                  >
                    Contact Details
                  </Typography>
                  <Divider width={200} />
                  <Typography
                    pt={1}
                    variant="body2"
                  >{`${task.draft.contact_first} ${task.draft.contact_last}`}</Typography>
                </>
              )}
              <Typography variant="body2">{`${task.draft.email}`}</Typography>
              <Typography variant="body2">{`${task.draft.phone_no}`}</Typography>
            </>
          )}
        </Box>

        <Box>
          <Typography
            paddingTop={1}
            fontWeight="bold"
            fontSize={14}
            color="darkgrey"
            component={"p"}
            variant="caption"
          >
            Property Address
          </Typography>
          <Divider width={200} />
          {task.property && (
            <>
              <Typography paddingTop={1} variant="body2">
                {task.property.address}
              </Typography>
              <Typography variant="body2">
                {task.property.city?.label}, {task.property.state}{" "}
                {task.property.zip}
              </Typography>
            </>
          )}
          {!task.property && task.draft && (
            <>
              <Typography paddingTop={1} variant="body2">
                {task.draft.service_address}
              </Typography>
              <Typography variant="body2">{task.draft.zip}</Typography>
            </>
          )}
        </Box>
        <Box>
          <Typography
            paddingTop={1}
            fontWeight="bold"
            fontSize={14}
            color="darkgrey"
            component={"p"}
            variant="caption"
          >
            Job Details
          </Typography>
          <Divider width={200} />
          <Typography paddingTop={1} variant="body2">
            {task.description}
          </Typography>
        </Box>
      </Grid>
      <Grid item sm={4} md={3}>
        <TaskStatus data={data} handleChange={handleChange} />
      </Grid>
      <Grid sx={{ mt: 3 }} sm={12} md={12}>
        {data.status === "PROCESSING" && data.task_type === "JOB_REQUEST" && (
          <Box
            mt={3}
            mb={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Button onClick={() => setJob(data.taskable)} variant="contained">
              Go To Job Sheet
            </Button>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default JobTask;
