import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PaperBlock from "components/PaperBlock";
import useApplication from "hooks/useApplication";
import SearchInput from "components/SearchInput";
import UserRow from "../Components/UserRow";
import useUser from "hooks/useUser";
import TableFooterWithPagination from "components/TableFooterWithPagination";
import useAccessControl from "hooks/useAccessControl";
import Swal from "sweetalert2";
import toast from "react-hot-toast";

export default function Users() {
  const navigate = useNavigate();
  const {deleteUser} = useUser();
  const { setPage } = useApplication();
  const {
    usersReducer: { results, query, loading, meta},
    search,
    getUsers,
    changePage,
    changePerPage,
  } = useUser();
  const { canCreateUser } = useAccessControl();

  useEffect(() => {
    setPage({
      title: "Users",
      path: "/app/users",
    });

    search(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (searchValue) => {
    search(searchValue);
  };

  const handleChangePage = (_event, newPage) => {
    changePage(newPage);
    getUsers({ search: query, page: newPage + 1, per_page: meta.per_page });
  };

  const handleChangeRowsPerPage = (event) => {
    changePerPage(event);
    getUsers({
      search: query,
      page: meta.current_page,
      per_page: parseInt(event.target.value),
    });
  };

  const deleteUserData = async (params) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      customClass: {
        title: "swal-text",
        popup:"swal-text"
        
      },
      confirmButtonText: 'Yes, Delete User'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUser(params)
        .then(res => {
          search(query);
              //window.location.reload();
       
    }).catch(err => {
        
        if (err.response) {
          toast.error('User Deleted unSuccessful',{
            style: {
              fontFamily: ['Open Sans', 'sans-serif']
            }});
        } 
})
      }
    })

    
  }

  return (
    <PaperBlock
      title="Users"
      loading={loading}
      actions={
        <>
          <SearchInput
            defaultValue={query}
            onChange={handleSearch}
            placeholder="By ID, First Name, Last Name, Phone or Email"
          />
          {canCreateUser() && (
            <Button
              sx={{ ml: 2, whiteSpace: "nowrap",minWidth: 'auto'}}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => navigate("/app/users/create")}
            >
              Add user
            </Button>
          )}
        </>
      }
    >
      <Box style={{overflow:"auto"}}>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>#ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Ext</TableCell>
            <TableCell sx={{paddingRight:5}} >Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((user, index) => (
            <UserRow
              key={user.id}
              data={user}
              rowNumber={(meta.current_page - 1) * meta.per_page + index + 1}
              deleteUserData={deleteUserData}
            />
          ))}
          {!results.length && (
            <TableRow>
              <TableCell colSpan={5} align="center">
                No data
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        {results.length > 0 && (
          <TableFooterWithPagination
            count={meta.total}
            rowsPerPage={meta.per_page}
            page={meta.current_page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Table>
      </Box>
    </PaperBlock>
  );
}
