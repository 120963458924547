import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  collapseTittle: {
    color: `${theme.palette.primary.main} !important`,
    fontSize: "12px!important",
  },
  jobStatus: {
    color: "#2e7d32",
    fontSize: 13,
  },
  jobAction: {
    color: "#196086",
    fontSize: 13,
  },
  dataGrid: {
    border: "0 !important",
  },
  primaryPersonalInfo: {
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
    },
  },
  containerInfo: {
    [theme.breakpoints.down("md")]: {
      display: "none !important",
    },
  },

  status: {
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    margin: theme.spacing(0.5),
    color: "white",
    borderRadius: theme.spacing(1),
  },
}));

export default useStyles;
