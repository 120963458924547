import React, { useEffect } from "react";
import useApplication from "hooks/useApplication";
import {
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  CircularProgress,
  Box
} from "@mui/material";
import PaperBlock from "components/PaperBlock";
import PaymentRow from "./components/PaymentRow";
import SearchInput from "components/SearchInput";
import usePayments from "hooks/usePayments";
import TableFooterWithPagination from "components/TableFooterWithPagination";

export default function Payments() {
  const { setPage } = useApplication();
  const {
    searchPayments,
    changePage,
    changePerPage,
    paymentsReducer: { results, meta, loading, search },
  } = usePayments();

  useEffect(() => {
    setPage({
      title: "Payments",
      path: "/app/payments",
    });
    searchPayments({
      search,
      page: meta.current_page,
      per_page: meta.per_page
    });
    // eslint-disable-next-line
  }, []);


  const handleSearch = (value) => {
    searchPayments({
      search: value,
      page: meta.current_page,
      per_page: meta.per_page
    });
  };

  const handleChangePage = (_event, newPage) => {
    changePage(newPage);
    searchPayments({
      search,
      page: newPage + 1,
      per_page: meta.per_page
    });
  };

  const handleChangeRowsPerPage = (event) => {
    changePerPage(event);
    searchPayments({
      search,
      page: meta.current_page,
      per_page: parseInt(event.target.value),
    });
  };

  return (

    // <PaperBlock
    //   title=""
    //   loading={loading}
    //   actions={
    //     <>
    //       <SearchInput
    //         defaultValue={search}
    //         onChange={handleSearch}
    //         placeholder="By ID, First Name, Last Name, Phone or Email"
    //       />
    //     </>
    //   }
    // >
    <>
      {loading ? ( // Display loader when data is being fetched
        <CircularProgress style={{ margin: "20px auto", display: "block" }} />
      ) : (
       <Box style={{overflow: "auto"}}>
         <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#ID</TableCell>
              <TableCell>Payer name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Company name</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Payment type</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell >Action</TableCell>
              {/* <TableCell align="right"></TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map((payment) => (
              <PaymentRow
                data={payment}
                key={payment.id}
              />
            ))}
            {!results.length && (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  No data
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooterWithPagination
            count={meta.total}
            rowsPerPage={meta.per_page}
            page={meta.current_page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Table>
       </Box>
      )}
    </>
    // </PaperBlock>
  );
}
