import React, { useEffect } from "react";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";

import FormField from "components/FormField";

import { searchZipDataRequest } from "api/utilities";
import StatePicker from "components/StatePicker";
import CityPicker from "components/CityPicker";

const MailingAddress = ({
  index,
  form,
  setForm,
  defaultLocation,
  noMailingAddress,
  // loggedIn
}) => {
  const setDefaults = () => {
    const mailing_address = { ...form.mailing_address };
    mailing_address.city = {
      id: defaultLocation.city.id,
      label: defaultLocation.city.city,
    };
    mailing_address.state = defaultLocation.state.code;
    mailing_address.zip = defaultLocation.zip;
    setForm({ mailing_address });
  };

  useEffect(() => {
    if (index === 0 && !noMailingAddress) setDefaults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  const handleChangeMailingAddress = (e) => {
    const _mailing_address = { ...form.mailing_address };
    _mailing_address[e.target.name] = e.target.value;
    setForm({ mailing_address: _mailing_address });
  };

  const handleChangeCity = (val) => {
    const _mailing_address = { ...form.mailing_address };
    _mailing_address.city = val;
    setForm({ mailing_address: _mailing_address });
  };

  const handleZipChange = (e) => {
    if (e.target.value.length <= 4) {
      return false;
    }
    searchZipDataRequest(e.target.value)
      .then(({ data }) => {
        const mailing_address = { ...form.mailing_address };
        mailing_address.city = {
          id: data.details.city.id,
          label: data.details.city.city,
        };
        mailing_address.state = data.details.state.code;
        mailing_address.zip = data.details.zip;
        setForm({ mailing_address });
      })
      .catch(() => {
        const mailing_address = { ...form.mailing_address };
        mailing_address.city = null;
        mailing_address.state = null;
        setForm({ mailing_address });
        // set errors
      });
  };

  const sameAsHomeAddress = (e) => {
    const membership = form.membership;
    membership.mailing_address_as_home = e.target.checked;
    setForm({ membership });
  };

  if (noMailingAddress) {
    return null;
  }

  return (
    <>
      <Grid item md={12} sm={12} xs={12}>
        {/* {loggedIn && ( */}
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              checked={form.membership.mailing_address_as_home}
              onChange={sameAsHomeAddress}
            />
          }
          label={"Mailing Address same as Home Address"}
        />
        {/* )} */}
      </Grid>
      {!form.membership.mailing_address_as_home && (
        <>
          <Grid item md={5} sm={12} xs={12}>
            <FormField
              label="Mailing Address"
              name="address"
              required
              value={form.mailing_address.address}
              onChange={handleChangeMailingAddress}
            />
          </Grid>
          <Grid item md={2} sm={12} xs={12}>
            <StatePicker
              InputLabelProps={{ shrink: true }}
              name="state"
              label="State"
              value={form.mailing_address.state}
              onChange={handleChangeMailingAddress}
            />
          </Grid>
          <Grid item md={3} sm={12} xs={12}>
            <CityPicker
              label="City"
              required
              InputLabelProps={{ shrink: true }}
              value={form.mailing_address.city}
              name="city"
              state_code={form.mailing_address.state}
              onChange={handleChangeCity}
            />
          </Grid>
          <Grid item md={2} sm={12} xs={12}>
            <FormField
              label="Zip Code"
              name="zip"
              value={form.mailing_address.zip}
              onChange={handleChangeMailingAddress}
              onBlur={handleZipChange}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default MailingAddress;
