import React, { useEffect, useState, useContext } from "react";
import {
  Grid,
  Box,
  Typography,
  Stack,
  Chip,
  Checkbox,
  FormControlLabel,
  Alert,
  AlertTitle,
  Button,
} from "@mui/material";

import TaskStatus from "../TaskStatus";
import DoneIcon from "@mui/icons-material/Done";
import { LoadingButton } from "@mui/lab";
import BroadcastingContext from "../../../Broadcasting/BroadcastingContext";
import { processBatchRequest } from "../../../../api/tasks";
import Processing from "./component/Processing";
import ImportedInvoices from "./component/ImportedInvoices";
import Toast from "components/Toast";

const InvoiceBatch = ({ data, handleChange, handleClose, getTasks }) => {
  const { taskable: mail } = data;
  const initialFeedback = {
    messages: [],
    doneTasks: [],
    total: 100,
    done: 0,
    completed: false,
    batch_id: null,
  };
  const channel = useContext(BroadcastingContext);
  const [state, _setState] = useState({
    file: null,
    withFeedback: false,
    id: data.id,
    loading: false,
    bindReady: false,
    errors: [],
  });

  const [feedback, _setFeedback] = useState({ ...initialFeedback });

  const setState = (newValues) => _setState({ ...state, ...newValues });

  const setFeedback = (newValues) =>
    _setFeedback({ ...feedback, ...newValues });

  const handleSubmit = () => {
    const newState = { loading: true, errors: [] };
    if (state.withFeedback) {
      newState.bindReady = true;
      setFeedback({ ...initialFeedback });
      if (!state.bindReady) {
        channel.bind(`batch.event.task.${data.id}`, function (res) {
          if (res.type === "info") {
            const messages = feedback.messages;
            messages.push(res.message);
            setFeedback({
              messages,
            });
          } else if (res.type === "invoices") {
            const doneTasks = feedback.doneTasks;
            doneTasks.push(res.content);
            const messages = feedback.messages;
            messages.push(res.message);
            setFeedback({
              doneTasks,
              done: res.progress,
              total: res.total,
            });
            if (res.progress === res.total) {
              setFeedback({
                completed: true,
              });
            }
          } else if (res.type === "done") {
            const messages = feedback.messages;
            messages.push(res.message);
            setFeedback({
              messages,
              batch_id: res.batch_id,
              completed: true,
            });
            getTasks();
          }
        });
      }
    }
    setState(newState);

    processBatchRequest({
      id: data.id,
      with_feedback: state.withFeedback,
      file_uuid: state.file,
    })
      .then(() => {
        if (!state.withFeedback) {
          handleClose();
          Toast.fire({
            title: "Batch process started! We will notify you when it's done.",
            icon: "success",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.data?.errors) {
          const errors = [];
          Object.keys(error.response.data.errors).forEach((field) => {
            if (error.response.data.errors[field][0] !== undefined) {
              errors.push(error.response.data.errors[field][0]);
            }
          });
          setState({ errors: errors, loading: false });
        } else {
          setState({ loading: false });
        }
      });
  };

  useEffect(() => {
    if (mail.attachments.length > 0) {
      setState({ file: mail.attachments[0].uuid });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mail.attachments.length]);

  useEffect(() => {
    if (!state.withFeedback) {
      setState({ bindReady: false });
    }

    return () => {
      if (state.bindReady) {
        channel.unbind(`batch.event.task.${data.id}`);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.withFeedback]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={8} md={9}>
        <Typography variant="body2">
          <Typography variant="body2">
            <b>{mail.from_name}</b> -{" "}
            <a rel="noreferrer" href={`mailto:${mail.from}`} target="_blank">
              {mail.from}
            </a>
          </Typography>
        </Typography>
        <Box>
          <Typography pt={2} pb={2} fontWeight="bold" variant="body2">
            Subject: {mail.subject}
          </Typography>
          <Box
            border="solid 1px #ddd"
            maxHeight={300}
            p={2}
            style={{ overflow: "auto" }}
            pt={2}
            dangerouslySetInnerHTML={{
              __html: mail.content,
            }}
          />
          {mail.attachments.length > 0 && (
            <>
              <Typography
                fontWeight="bold"
                pt={2}
                variant="body2"
                component="p"
              >
                Please select the attachment containing the invoice batch:
              </Typography>
              <Box mt={1}>
                <Stack direction="row" spacing={1}>
                  {mail.attachments.map((attachment) => (
                    <Chip
                      variant={
                        state.file === attachment.uuid
                          ? "contained"
                          : "outlined"
                      }
                      color="secondary"
                      clickable
                      label={attachment.label}
                      key={attachment.attachment_id}
                      onClick={() => setState({ file: attachment.uuid })}
                      icon={state.file === attachment.uuid && <DoneIcon />}
                    />
                  ))}
                </Stack>
              </Box>
            </>
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={state.withFeedback}
                onClick={(e) => setState({ withFeedback: e.target.checked })}
              />
            }
            label="Get Process Feedback"
          />
        </Box>
      </Grid>
      <Grid item sm={4} md={3}>
        <TaskStatus data={data} handleChange={handleChange} />
      </Grid>
      <Grid item sm={12} md={12}>
        <Box textAlign="center" mt={3} mb={2}>
          {state.errors.length > 0 && (
            <Typography mb={2} color="error" variant="body2">
              {state.errors.map((error) => (
                <span key={error}>{error}</span>
              ))}
            </Typography>
          )}
          {!state.loading && (
            <LoadingButton
              onClick={() => handleSubmit()}
              variant="contained"
              loading={state.loading}
            >
              Import Invoice Batch
            </LoadingButton>
          )}
          {feedback.completed && (
            <Alert
              sx={{ mt: 2, textAlign: "left" }}
              severity="success"
              action={
                <>
                  <Button
                    sx={{ mr: 2 }}
                    variant="contained"
                    size="small"
                    onClick={() =>
                      window.location.replace(
                        `/app/invoices/batch/${feedback.batch_id}/process`
                      )
                    }
                  >
                    View Invoices
                  </Button>
                  <Button
                    variant="text"
                    color="secondary"
                    size="small"
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </>
              }
            >
              <AlertTitle>
                The batch process has been completed. Closing the task.
              </AlertTitle>
            </Alert>
          )}
        </Box>
      </Grid>
      <Grid item sm={12} md={12}>
        {feedback.messages.length > 0 && (
          <>
            {!feedback.completed && (
              <Processing percent={(feedback.done / feedback.total) * 100} />
            )}
            <ImportedInvoices
              messages={feedback.messages}
              data={feedback.doneTasks}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default InvoiceBatch;
