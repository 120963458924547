import React from "react";
import { ListItemText, MenuItem } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import PriorityHigh from "@mui/icons-material/PriorityHigh";
import InputAdornment from "@mui/material/InputAdornment";
import FormField from "components/FormField";

const Priority = ({ value, onChange }) => {
  const renderPriority = () => {
    if (value === "HIGH") return <PriorityHigh color="error" />;
    else if (value === "MEDIUM") return <ExpandLessIcon color="warning" />;
    return <ExpandMoreIcon color="success" />;
  };

  return (
    <FormField
      select
      size="small"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{renderPriority()}</InputAdornment>
        ),
      }}
    >
      <MenuItem value={"LOW"}>
        <ListItemText>Low</ListItemText>
      </MenuItem>
      <MenuItem value={"MEDIUM"}>
        <ListItemText>Medium</ListItemText>
      </MenuItem>
      <MenuItem value={"HIGH"}>
        <ListItemText>High</ListItemText>
      </MenuItem>
    </FormField>
  );
};

export default Priority;
