import { useState, forwardRef, useImperativeHandle } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Box,
  Slide,
  IconButton,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CsvViewer from "./CsvViewer";
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PreviewFileDialog = forwardRef(
  ({ deleteFile, downloadFile, files }, ref) => {
    const [file, setFile] = useState(null);
    useImperativeHandle(ref, () => ({
      display: (selectedFileIndex) => {
        setFile(selectedFileIndex);
      },
    }));

    const handleClose = () => {
      setFile(null);
    };

    const handleNext = () => {
      if (files.length > file + 1 && files[file + 1].type === "file") {
        setFile(file + 1);
      }
    };

    const handlePrev = () => {
      if (file > 0 && files[file - 1].type === "file") {
        setFile(file - 1);
      }
    };

    const setFileUrl = () => {
      if (files[file].content_type === "application/pdf") {
        return `${process.env.REACT_APP_API_DOMAIN}/api/v1/file-preview/${files[file].uuid}#toolbar=0`;
      }
      return `${process.env.REACT_APP_API_DOMAIN}/api/v1/file-preview/${files[file].uuid}`;
    };

    if (file === null) return null;
    return (
      <Dialog
        onClose={handleClose}
        open={true}
        fullScreen
        TransitionComponent={Transition}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <DialogTitle color="primary">{files[file].label}</DialogTitle>
          <Box mr={2}>
            <Tooltip title="Download">
              <IconButton
                aria-label="download"
                onClick={() => downloadFile(files[file].uuid)}
                disabled={file?.loading}
              >
                <CloudDownloadIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                onClick={() => deleteFile(files[file].uuid)}
                disabled={file?.loading}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Close">
              <IconButton
                sx={{ ml: 2 }}
                aria-label="delete"
                onClick={() => setFile(null)}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <DialogContent sx={{ textAlign: "center" }}>
          {files[file].content_type === "text/csv" ? (
            <CsvViewer file={files[file]} />
          ) : (
            <object
              aria-label="Preview"
              data={setFileUrl()}
              type={files[file].content_type}
              width={
                files[file].content_type === "application/pdf" ? "100%" : "auto"
              }
              height={
                files[file].content_type === "application/pdf" ? "100%" : "auto"
              }
              style={{ overflow: "hidden" }}
            ></object>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between", mx: 2 }}>
          <Button
            startIcon={<ArrowBackIosIcon />}
            onClick={handlePrev}
            disabled={!(file - 1 > 0 && files[file - 1].type === "file")}
          >
            Previous
          </Button>
          <Button
            endIcon={<ArrowForwardIosIcon />}
            onClick={handleNext}
            disabled={
              !(file + 1 < files.length && files[file + 1].type === "file")
            }
          >
            Next
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export default PreviewFileDialog;
