import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce";
import {
  CHANGE_PAGE,
  CHANGE_PER_PAGE,
  SEARCH_JOBS,
  SEARCH_JOBS_RESULTS,
  CHANGE_JOB_STATUS
} from "redux/actions/jobsActions";
import {
  changeJobRequest,
  getJobsRequest,
  updateJobRequest,
  changeJobStatusRequest,
  deleteJobRequest
} from "api/jobs";

const useJobs = () => {
  const dispatch = useDispatch();
  const jobsReducer = useSelector((state) => state.jobs);

  const getJobs = debounce((query, page = 0, per_page = 10) => {
    dispatch({ type: SEARCH_JOBS, query });
    getJobsRequest({ search: query, page, per_page }).then((res) => {
      dispatch({ type: SEARCH_JOBS_RESULTS, response: res.data });
    });
  }, 600);

  const changeField = (job_id, field, value) => {
    const data = {};
    data[field] = value;
    changeJobRequest(job_id, data);
  };

  const updateJob = (jobId, data) => updateJobRequest(jobId, data);
  const deleteJob = (params) => deleteJobRequest(params);

  const changePage = (newPage) => {
    dispatch({ type: CHANGE_PAGE, page: newPage + 1 });
  };

  const changePerPage = (event) => {
    dispatch({ type: CHANGE_PER_PAGE, perPage: parseInt(event.target.value) });
  };

  const changeJobStatus = (job_id, status) => {
    dispatch({ type: CHANGE_JOB_STATUS, id: job_id, status });
    changeJobStatusRequest({ id: job_id, status });
  };

  return {
    jobsReducer,
    // search,
    getJobs,
    changeJobStatus,
    changeField,
    updateJob,
    changePage,
    changePerPage,
    deleteJob
  };
};

export default useJobs;
