import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme) => ({
  formSection: {
    paddingBottom: theme.spacing(2),
  },
  zipBtn: {
    marginLeft: 10,
  },
  successText: {
    color: "#388e3c",
  },
  errorText: {
    color: "#EB001B",
  },
}));

export default styles;
