import { useSelector } from "react-redux";
import { 
  EXECUTIVE_ROLE, 
  //SYSTEM_ADMINISTRATOR_ROLE 
} 
  from "utils/constants";
import {
  // CONNECT_VOICEMAIL,
  INVOICE_READ,
  INVOICE_VIEW,
  JOB_CREATE_ESTIMATE,
  JOB_READ,
  JOB_READ_ESTIMATE,
  MEMBERSHIP_CREATE,
  //MEMBERSHIP_DELETE,
  MEMBERSHIP_UPDATE,
  USER_CREATE,
  USER_MANAGE_SERVICE_LOCATION,
  USER_READ,
  USER_UPDATE,
  CONTRACTOR_CREATE,
  CONTRACTOR_UPDATE,
  CONTRACTOR_READ,
  CONTRACTOR_DELETE,
  VENDOR_CREATE,
  VENDOR_UPDATE,
  VENDOR_READ,
  VENDOR_DELETE,
  ROLE_CREATE,
  ROLE_UPDATE,
  ROLE_READ,
  DASHBORAD_READ,
  TASK_READ,
  PAYMENT_READ,
  MEMBERSHIP_READ,
  SETTING_READ,
  JOB_CREATE,
  CONNECT_READ,
  JOB_UPDATE,
  USER_DELETE,
  JOB_DELETE
} from "utils/permissions";

const useAccessControl = () => {

  const user = useSelector((store) => store.auth);

  const hasPermission = (permission_key) => user.permissions.findIndex(permission => permission.permission_key === permission_key) > -1;

//member
  const canCreateMember = () => hasPermission(MEMBERSHIP_CREATE);
  const canReadMember = () => hasPermission(MEMBERSHIP_READ);
  const canUpdateMember = () => hasPermission(MEMBERSHIP_UPDATE);

//User
  const canReadUser = () => hasPermission(USER_READ);
  const canCreateUser = () => hasPermission(USER_CREATE);
  const canUpdateUser = () => hasPermission(USER_UPDATE);
  const canDeleteUser = () => hasPermission(USER_DELETE);
  const canManageServiceLocationUser = () => hasPermission(USER_MANAGE_SERVICE_LOCATION);

//Job
  const canReadJob = () => hasPermission(JOB_READ);
  const canCreateJob = () => hasPermission(JOB_CREATE);
  const canUpdateJob = () => hasPermission(JOB_UPDATE);
  const canDeleteJob = () => hasPermission(JOB_DELETE);
  const canCreateJobEstimate = () => hasPermission(JOB_CREATE_ESTIMATE);
  const canReadJobEstimate = () => hasPermission(JOB_READ_ESTIMATE);

//Invoice
  const canReadInvoice = () => hasPermission(INVOICE_READ);
  const canViewInvoice = () => hasPermission(INVOICE_VIEW);

//Contractor
  const canCreateContractor = () => hasPermission(CONTRACTOR_CREATE);
  const canUpdateContractor = () => hasPermission(CONTRACTOR_UPDATE);
  const canReadContractor = () => hasPermission(CONTRACTOR_READ);
  const canDeleteContractor = () => hasPermission(CONTRACTOR_DELETE);
//Vendor
  const canCreateVendor = () => hasPermission(VENDOR_CREATE);
  const canUpdateVendor = () => hasPermission(VENDOR_UPDATE);
  const canReadVendor = () => hasPermission(VENDOR_READ);
  const canDeleteVendor = () => hasPermission(VENDOR_DELETE);

//Role
  const canCreateRole = () => hasPermission(ROLE_CREATE);
  const canUpdateRole = () => hasPermission(ROLE_UPDATE);
  const canReadRole = () => hasPermission(ROLE_READ);

//Payment
  const canReadPayment = () => hasPermission(PAYMENT_READ);

//Dashborad
  const canReadDashborad = () => hasPermission(DASHBORAD_READ);

//Task
  const canReadTask = () => hasPermission(TASK_READ);

//connect
const canReadConnect = () => hasPermission(CONNECT_READ);

//Setting
  const canReadSetting = () => hasPermission(SETTING_READ);



  return {
    isAdmin: user.data.role === EXECUTIVE_ROLE,
    hasPermission,
    canCreateMember,
    canUpdateMember,
    //canDeleteMember,
    canReadUser,
    canCreateUser,
    canUpdateUser,
    canManageServiceLocationUser,
    canReadJob,
    canDeleteJob,
    canReadInvoice,
    canViewInvoice,
    canCreateJobEstimate,
    canReadJobEstimate,
    canCreateContractor,
    canUpdateContractor,
    canReadContractor,
    canDeleteContractor,
    canCreateVendor,
    canUpdateVendor,
    canReadVendor,
    canDeleteVendor,
    canCreateRole,
    canUpdateRole,
    canReadRole,
    canReadDashborad,
    canReadTask,
    canReadPayment,
    canReadMember,
    canReadSetting,
    canCreateJob,
    canUpdateJob,
    canReadConnect,
    canDeleteUser
    
  };
};

export default useAccessControl;
