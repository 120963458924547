import { makeStyles } from "@mui/styles";

const styles = makeStyles(() => ({
  list: {
    listStyle: "none",
    margin: 0,
    padding: 0,
  },
}));

export default styles;
