import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  collapseTittle: {
    color: `${theme.palette.primary.main} !important`,
    fontSize: "12px!important",
  },
  jobStatus: {
    color: "#2e7d32",
    fontSize: 13,
  },
  jobAction: {
    color: "#196086",
    fontSize: 13,
  },
}));

const Collapse = ({ children, title }) => {
  const classes = useStyles();
  return (
    <Accordion sx={{ marginTop: "5px" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon color="primary" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.collapseTittle}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default Collapse;
