import React from "react";
import Swal from "sweetalert2";
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import dayjs from "dayjs";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ".././../utils/sweetalert.css"

export default function NotesList({ notes, onDelete, onEdit }) {
  const theme = useTheme();

  const deleteNote = (noteId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This note will be permanently deleted",
      icon: "warning",
      customClass: {
        title: "swal-text",
        popup:"swal-text"
        
      },
      showCancelButton: true,
      confirmButtonColor: theme.palette.primary.main,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete(noteId);
      }
    });
  };

  return (
    <Box>
      {notes.length > 0 && (
        <>
          <Typography variant="h6" color="secondary" sx={{ mb: 2 }}>
            Notes:
          </Typography>
          {notes.map((note, index) => (
            <Card
              sx={{ minWidth: 275, mb: 2 }}
              key={`note-${index}`}
              variant="outlined"
            >
              <CardContent>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  <b>{note.user.name}</b>
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  {note.updated_at
                    ? `Updated: ${dayjs(note.updated_at).format(
                        "MM-DD-YYYY hh:mm a"
                      )}`
                    : `Created: ${dayjs(note.updated_at).format(
                        "MM-DD-YYYY hh:mm a"
                      )}`}
                </Typography>
                <Divider />
                <div dangerouslySetInnerHTML={{ __html: note.content }}></div>
              </CardContent>
              <CardActions sx={{ justifyContent: "flex-end" }}>
                <Button
                  size="small"
                  color="secondary"
                  startIcon={<EditIcon />}
                  onClick={() => onEdit(note.id)}
                >
                  Update
                </Button>
                <Button
                  size="small"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={() => deleteNote(note.id)}
                >
                  Delete
                </Button>
              </CardActions>
            </Card>
          ))}
        </>
      )}
    </Box>
  );
}
