import React from "react";
import dayjs from "dayjs";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

const InvoiceDetailsComponent = ({ invoice }) => {
  return (
    <Table aria-label="simple table" size="small">
      <TableHead>
        <TableRow>
          <TableCell>Product</TableCell>
          <TableCell>Amount</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Payment method</TableCell>
          <TableCell>Date</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell component="th" scope="row">
            {invoice.description}
          </TableCell>
          <TableCell>{`${invoice.amount / 100}$`}</TableCell>
          <TableCell>{invoice.status}</TableCell>
          <TableCell>
            {invoice.payment_method === "STRIPE" ? "Card" : "Check"}
          </TableCell>
          <TableCell>
            {dayjs(invoice.created_at).format("YYYY-MM-DD")}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default InvoiceDetailsComponent;
