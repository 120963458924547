export const invoiceStatusColors = {
  PAID: "#01cd74",
  ISSUED: "#fbb034",
  DECLINED: "#e4002b",
};

export const tasksColors = [
	"#116089",
	"#f44336",
	"#e91e63",
	"#9c27b0",
	"#673ab7",
	"#3f51b5",
	"#00bcd4",
	"#cddc39",
	"#ffc107",
];
