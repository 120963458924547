import React, { useEffect, useState } from "react";
import {
  IconButton,
  Tooltip,
  FormControlLabel,
  Checkbox,
  Box,
  ClickAwayListener
} from "@mui/material";
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useFormik } from "formik";


export default function Filter({ onChange = () => {}, values = {} }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip sx={{ mr: 1 }} {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const formik = useFormik({
    initialValues: {
      qbeSent: false,
      qbeNotSent: false,
    },
  });

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      qbeSent: values.qbeSent,
      qbeNotSent: values.qbeNotSent
    });
  //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    onChange(formik.values);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  return (
    <>
      <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
        <div>
          <LightTooltip
            open={tooltipOpen}
            PopperProps={{
              disablePortal: true,
            }}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            leaveDelay={1000}
            title={<>
              {/* <Stack direction="row" spacing={1}>
                {formik.values.qbeSent && (
                  <Chip label="QBE sent" variant="outlined" onDelete={() => formik.setFieldValue('qbeSent', false)} />
                )}
                {formik.values.qbeNotSent && (
                  <Chip label="QBE not sent" variant="outlined" onDelete={() => formik.setFieldValue('qbeNotSent', false)} />
                )}
              </Stack> */}
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      name="accepted"
                      checked={formik.values.qbeSent}
                      onChange={(e) => formik.setFieldValue('qbeSent', e.target.checked)}
                    />
                  }
                  label="QBE sent"
                />
              </Box>
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      name="accepted"
                      checked={formik.values.qbeNotSent}
                      onChange={(e) => formik.setFieldValue('qbeNotSent', e.target.checked)}
                    />
                  }
                  label="QBE not sent"
                />
              </Box>
            </>}
          >
            <IconButton onClick={() => setTooltipOpen(true)}>
              <FilterAltIcon />
            </IconButton>
          </LightTooltip>
        </div>
      </ClickAwayListener>
    </>
  );
}
