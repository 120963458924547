import React, { useState } from "react";
import FormField from "components/FormField";
import { Box, Button, Checkbox } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import debounce from "lodash.debounce";
import { searchContractorsRequest } from "api/utilities";
import LinkBtn from "components/LinkBtn";

const ContractorsSelector = ({ selected, handleChange }) => {
  const [loading, setLoading] = useState(false);
  const [contractors, setContractors] = useState([]);

  const searchContractors = debounce((query) => {
    setLoading(true);
    searchContractorsRequest(query).then((res) => {
      setContractors(res.data.result);
      setLoading(false);
    });
  }, 600);

  const handleCheck = (contractor) => {
    const newSelection = [...selected];
    const exists = newSelection.findIndex(
      (c) => c.contractor.id === contractor.id
    );
    if (exists !== -1) {
      newSelection.splice(exists, 1);
    } else {
      newSelection.push({
        contractor: contractor,
        message: "",
        hasMessage: false,
      });
    }
    handleChange({ estimates_request: newSelection });
  };

  const onMessageClick = (cid) => {
    const contractorIndex = selected.findIndex((c) => c.contractor.id === cid);
    const _selected = [...selected];
    if (_selected[contractorIndex].hasMessage) {
      _selected[contractorIndex].hasMessage = false;
      _selected[contractorIndex].message = "";
    } else {
      _selected[contractorIndex].hasMessage = true;
    }
    handleChange({ estimates_request: _selected });
  };

  const columns = [
    {
      field: "id",
      headerName: "",
      flex: 0.01,
      renderCell: (data) => (
        <Checkbox
          checked={
            selected.findIndex((c) => c.contractor.id === data.row.id) !== -1
          }
          onClick={() => handleCheck(data.row)}
        />
      ),
    },
    { field: "name", headerName: "Contractor Name", flex: 1 },
    { field: "phone", headerName: "Phone", flex: 0.9 },
    {
      field: "active_jobs",
      headerName: "Active Jobs",
      type: "number",
      flex: 0.5,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (data) => {
        const isSelected = selected.findIndex(
          (c) => c.contractor.id === data.row.id
        );
        let label = "Message";
        if (isSelected !== -1 && selected[isSelected].hasMessage)
          label = "Cancel";
        return (
          <>
            <Button
              target={"_blank"}
              component={LinkBtn}
              to={`/app/contractors/${data.row.id}`}
              size="small"
            >
              Profile
            </Button>
            <Button
              disabled={isSelected === -1}
              onClick={() => onMessageClick(data.row.id)}
              size="small"
            >
              {label}
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <Box mt={3}>
      <Box mb={1}>
        <FormField
          label="Search Contractor"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => searchContractors(e.target.value)}
        />
      </Box>
      {contractors.length > 0 && (
        <DataGrid
          rows={contractors}
          columns={columns}
          loading={loading}
          pageSize={5}
          checkboxSelection={false}
          hideFooter
          autoHeight
          autoPageSize
          density="compact"
        />
      )}
    </Box>
  );
};

export default ContractorsSelector;
