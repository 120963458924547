import axios from "utils/axios";

const CHANGE_PRIORITY_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/tasks/priority`;
const SET_AS_BATCH_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/tasks/change-to-batch`;
const LINK_ESTIMATE_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/tasks/change-to-estimate`;
const TASKS_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/tasks`;

const getTasksRequest = (params) => {
  return axios.get(TASKS_ROUTE, {
    params,
  });
};

const changeTaskStatusRequest = (data) =>
  axios.post(`${TASKS_ROUTE}/change-status`, data);

const changeTaskUserRequest = (data) =>
  axios.post(`${TASKS_ROUTE}/change-user`, data);

const linkEstimateRoute = (data) => axios.post(LINK_ESTIMATE_ROUTE, data);

const processBatchRequest = (data) => axios.post(SET_AS_BATCH_ROUTE, data);

const changePriority = (taskId, value) =>
  axios.post(`${CHANGE_PRIORITY_ROUTE}/${taskId}`, { value });

export {
  getTasksRequest,
  changeTaskStatusRequest,
  changeTaskUserRequest,
  linkEstimateRoute,
  changePriority,
  processBatchRequest,
};
