import React from "react";
import { useNavigate } from "react-router-dom";
import { TableRow, TableCell, Button, Chip } from "@mui/material";
import { statuses } from "utils/jobStatuses";
import useAccessControl from "hooks/useAccessControl";

const JobRow = ({ data,deleteJobData }) => {
  const navigate = useNavigate();
  const {canDeleteJob} = useAccessControl();

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {data.id}
      </TableCell>
      <TableCell component="th" scope="row">
        {data.code?.description}
      </TableCell>
      <TableCell component="th" scope="row">
        {`${data.contact?.first_name || ''} ${data.contact?.last_name || ''}`}
      </TableCell>
      <TableCell component="th" scope="row">
        {data.membership_id}
      </TableCell>
      <TableCell component="th" scope="row">
        {data.property?.address}
      </TableCell>
      <TableCell component="th" scope="row">
        <Chip
          label={data.status}
          style={{ background: statuses[data.status], color: "white" }}
        />
      </TableCell>
      <TableCell component="th" scope="row" align="right">
       <div style={{display:"flex",gap: "8px",width:"100%"}}>
       { canDeleteJob() &&
         <Button
         variant="contained"
         color="error"
         size="small"
         sx={{ mr: 0.5}}
         onClick={() => deleteJobData(data.id)}
       >
         Delete
       </Button>
        }
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => navigate(`/app/jobs/${data.id}`)}
        >
          View
        </Button>
       </div>
      </TableCell>
    </TableRow>
  );
};

export default JobRow;
