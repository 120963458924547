import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Box, Modal, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "35%",
    height: "auto",
    transform: "translate(-50%, -50%)",
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    border: "2px solid #fff",
    backgroundColor: "#fff",
    [theme.breakpoints.down("lg")]: {
      width: "60% !important",
      overflowY: "auto",
      // height: '100%'
    },
    [theme.breakpoints.down("sm")]: {
      width: "80% !important",
      overflowY: "auto",
      height: "100%",
    },
  },
  mapImage: {
    width: "100%",
  },
  closeIcon: {
    float: "right",
  },
  emailLink: {
    color: theme.palette.secondary.light,
  },
  pdfLink: {
    color: theme.palette.secondary.light,
    marginTop: theme.spacing(2),
    fontSize: 14,
  },
  smallTextField: {
    width: "30% !important",
    [theme.breakpoints.down("sm")]: {
      width: "50% !important",
    },
  },
  pdfImage: {
    width: 25,
    marginRight: theme.spacing(1),
  },
  docLink: {
    color: theme.palette.secondary.light,
    marginLeft: theme.spacing(1),
  },
  modalCall: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "25%",
    height: "auto",
    transform: "translate(-50%, -50%)",
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    border: "2px solid #fff",
    backgroundColor: "#fff",
    [theme.breakpoints.down("lg")]: {
      width: "80% !important",
      overflowY: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80% !important",
      overflowY: "auto",
    },
  },
}));

const PrimaryServiceAreaModal = ({ open, handleClose }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Modal open={open} onClose={handleClose} disableAutoFocus>
      <Box className={classes.modal}>
        <IconButton onClick={handleClose} className={classes.closeIcon}>
          <Icon path={mdiClose} size={1} color="primary" />
        </IconButton>
        <Grid container>
          <Grid item sm={12} md={6}>
            <img src="/images/map.png" alt="HOC" className={classes.mapImage} />
          </Grid>
          <Grid item sm={12} md={6} sx={{ p: 2 }}>
            <Typography variant="subtitle1">
              {t("membershipApplication:modal_paragraph")}
            </Typography>
            <a href="mailto:staff@homeownersclub.org">
              staff@homeownersclub.org{" "}
            </a>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default PrimaryServiceAreaModal;
