import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import TaskStatus from "../TaskStatus";
import FormField from "components/FormField";
import useQueue from "hooks/useQueue";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import Toast from "components/Toast";

const CheckPaymentTask = ({ data, handleClose, handleChange }) => {
  const { addCheckNumber } = useQueue();
  const navigate = useNavigate();

  const { taskable: task } = data;
  const [checkNo, setCheckNo] = useState("");
  const [loading, setLoading] = useState(false);

  const acceptCheckPayment = () => {
    setLoading(true);
    addCheckNumber(data.id, {
      check_no: checkNo,
    })
      .then(() => {
        navigate(`/app/members/${task.membership.id}`);
        setLoading(true);
        Toast.fire({
          title: "Payment registered!",
          icon: "success",
        });
      })
      .catch((e) => {
        setLoading(false);
        alert(1);
      });
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={8} md={9}>
        <Typography variant="body2">
          <Typography variant="body2">
            <b>{data.title}</b>
          </Typography>
        </Typography>
        <Box mt={2}>
          <Typography variant="subtitle1">
            <b>Primary Member: </b>
            {`${task.membership.members[0].first_name} ${task.membership.members[0].last_name}`}{" "}
          </Typography>
          <Typography variant="subtitle1">
            <b>Property Details: </b>
            {`${task.membership.properties[0].address}, ${task.membership.properties[0].city.label} ${task.membership.properties[0].state} ${task.membership.properties[0].zip}`}{" "}
          </Typography>
          <Typography variant="subtitle1">
            <b>Amount: </b>
            {`$${task.amount / 100}`}{" "}
          </Typography>
          <Box mt={2}>
            <FormField
              value={checkNo}
              onChange={(e) => setCheckNo(e.target.value)}
              label="Check Number"
              fullWidth={false}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item sm={4} md={3}>
        <TaskStatus data={data} handleChange={handleChange} />
      </Grid>
      <Grid sx={{ mt: 3 }} sm={12} md={12}>
        {data.status === "PROCESSING" && (
          <Box
            mt={3}
            mb={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <LoadingButton
              disabled={!checkNo || loading}
              onClick={() => acceptCheckPayment()}
              variant="contained"
              loading={loading}
            >
              Add Check Payment
            </LoadingButton>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default CheckPaymentTask;
