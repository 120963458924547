/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CircularProgress, Typography, Box, Tabs, Tab, AppBar } from "@mui/material";

import PaperBlock from "components/ContentBlock";
import TabContainer from "components/TabContainer";
import FormField from "components/FormField";
import ContactList from "./ContactList";
import CallLog from "./CallLog";
import { getDashContacts } from "api/dashboard";
import debounce from "lodash.debounce";

function ContactWidget({ filterDate }) {
  const [state, _setState] = useState({
    contacts: [],
    loading: true,
    tab: 0,
  });
  const setState = (values) => _setState({ ...state, ...values });
  const getContacts = (query = '') => {
    setState({ loading: true });
    getDashContacts({ search: query, from: filterDate[0], to: filterDate[1] }).then((res) => {
      setState({
        contacts: res.data,
        loading: false,
      });
    });
  };

  useEffect(() => {
    getContacts();
  }, [filterDate]);

  const search = debounce((query) => {
    getContacts(query);
  }, 500);


  return (
    <Fragment>
      <PaperBlock
        title="Calls"
        icon="ion-ios-checkbox-outline"
        noMargin
        whiteBg
      >
        <AppBar style={{ background: "white" }} position="static">
          <Tabs variant="fullWidth"
            value={state.tab}
            onChange={(_e, tab) => setState({ tab: tab })}
          >
            <Tab id={`full-width-tab-0`} label="Contacts" />
            <Tab id={`full-width-tab-1`} label="Call Log" />
          </Tabs>
        </AppBar>
        {parseInt(state.tab) === 0 && (
          <TabContainer>
            <FormField
              label="Search Contact"
              onChange={(e) => search(e.target.value)}
            />
            {state.loading && (
              <Box
                p={2}
                pt={5}
                display="flex"
                justifyContent="center"
                alignContent="center"
              >
                <CircularProgress />
              </Box>
            )}
            {!state.loading && <ContactList contacts={state.contacts} />}
            {!state.loading && state.contacts.length === 0 && (
              <Box textAlign="center">
                <Typography fontWeight="bold" fontStyle="italic" variant="body1">
                  No Data
                </Typography>
              </Box>
            )}
          </TabContainer>
        )}
        {parseInt(state.tab) === 1 && (
        <TabContainer value={state.tab} index={1} >
          <CallLog filterDate={filterDate} />
        </TabContainer>
        )}
      </PaperBlock>
    </Fragment>
  );
}

ContactWidget.propTypes = {
  classes: PropTypes.object,
};

export default ContactWidget;
