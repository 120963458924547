import * as React from "react";
import { Button, Breadcrumbs } from "@mui/material";

const FsBreadcrumbs = ({ path, onChange }) => {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {path.map((item, index) => (
        <Button key={`bc_${index}`} onClick={() => onChange(item.uuid)}>
          {item.label}
        </Button>
      ))}
    </Breadcrumbs>
  );
};

export default FsBreadcrumbs;
