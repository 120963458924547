import React, { useState } from "react";
import {
  Button,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TableContainer,
  Paper,
} from "@mui/material";

import EstimateModal from "./EstimateModal";
import dayjs from "dayjs";

const Estimates = ({ job, onChange }) => {
  const { estimates } = job;
  const [estimate, setEstimate] = useState(false);

  const handleClose = () => {
    setEstimate(false);
    onChange && onChange();
  };

  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Contractor Name</TableCell>
              <TableCell>Contractor Email</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {estimates.length === 0 && (
              <TableRow>
                <TableCell style={{ textAlign: "center" }} colSpan={5}>
                  No Data
                </TableCell>
              </TableRow>
            )}
            {estimates.length > 0 &&
              estimates.map((est, index) => (
                <TableRow key={`${est.id}-estimation`}>
                  <TableCell sx={{ width: 200 }}>
                    {est.contractor?.name}
                  </TableCell>
                  <TableCell sx={{ width: 150 }}>
                    {est.contractor?.email}
                  </TableCell>
                  <TableCell sx={{ maxWidth: 300 }}>{est.notes}</TableCell>
                  <TableCell>
                    {dayjs(est.created_at).format("MM/DD/YYYY")}
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => setEstimate(index)} size="small">
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {estimate !== false && (
        <EstimateModal
          job={job}
          selected={estimate}
          handleClose={handleClose}
        />
      )}
    </React.Fragment>
  );
};
export default Estimates;
