import { makeStyles } from "@mui/styles";

const styles = makeStyles(() => ({
  root: {
    width: "100%",
    minHeight: "100%",
    marginTop: 0,
    zIndex: 1,
  },
  loading: {
    zIndex: 10,
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    opacity: 1,
    transition: "opacity .5s ease",
  },
  loadingWrap: {
    background: "none",
  },
  bar: {
    background: "rgba(255, 255, 255, 0.7)",
  },
  hide: {
    display: "none",
  },
}));

export default styles;
