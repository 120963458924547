import React, { useState } from "react";
import FormField from "components/FormField";
import { Box, Checkbox } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import debounce from "lodash.debounce";
import { searchContractorsRequest } from "api/utilities";

const ContractorSelect = ({ selected, handleChange }) => {
  const [loading, setLoading] = useState("");
  const [contractors, setContractors] = useState([]);

  const searchContractors = debounce((query) => {
    setLoading(true);
    searchContractorsRequest(query).then((res) => {
      setContractors(res.data.result);
      setLoading(false);
    });
  }, 600);

  const columns = [
    {
      field: "id",
      headerName: "",
      flex: 0.01,
      renderCell: (data) => (
        <Checkbox
          checked={selected === data.row.id}
          onChange={(e) =>
            e.target.checked ? handleChange(data.row) : handleChange(null)
          }
        />
      ),
    },
    { field: "name", headerName: "Contractor Name", flex: 1 },
    { field: "phone", headerName: "Phone", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "ytd_jobs", headerName: "YTD Jobs", type: "number", flex: 0.2 },
    {
      field: "active_jobs",
      headerName: "Active Jobs",
      type: "number",
      flex: 0.3,
    },
  ];

  return (
    <Box mt={3}>
      <Box mb={1}>
        <FormField
          label="Search Contractor"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => searchContractors(e.target.value)}
        />
      </Box>
      {contractors.length > 0 && (
        <DataGrid
          rows={contractors}
          columns={columns}
          loading={loading}
          pageSize={5}
          checkboxSelection={false}
          hideFooter
          autoHeight
          autoPageSize
          density="compact"
        />
      )}
    </Box>
  );
};

export default ContractorSelect;
