import React, { useEffect, useState } from "react";
import ContentBlock from "components/ContentBlock";
import useApplication from "hooks/useApplication";
import { Grid, Box, Typography, CircularProgress } from "@mui/material";
import CheckoutForm from "components/CheckoutForm";
import { useParams } from "react-router-dom";
import { getInvoiceDetails } from "api/utilities";

export default function Checkout() {
  const { setPage } = useApplication();
  const { order_uuid } = useParams();
  const [invoice, setInvoice] = useState({});
  const [paymentMethod, setPaymentMethod] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setPage({
      title: "Checkout",
      path: "/app/members",
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getInvoiceDetails(order_uuid).then((res) => {
      setInvoice(res.data.invoice);

      const property = {};
      const primaryPropertyIndex = res.data?.invoice?.membership?.properties.findIndex((property) => property.is_primary === 1)
      if(primaryPropertyIndex > -1) {
        const primaryProperty = res.data?.invoice?.membership?.properties[primaryPropertyIndex];
        property.address = primaryProperty.address;
        property.city = primaryProperty.city;
        property.city_id = primaryProperty.city.id;
        property.state_code = primaryProperty.state;
        property.zip = primaryProperty.zip;
      }

      const member = {};
      const primaryMemberIndex = res.data?.invoice?.membership?.members.findIndex((member) => member.is_primary === 1)
      if(primaryMemberIndex > -1) {
      const primaryMember = res.data?.invoice?.membership?.members[primaryMemberIndex];
        member.company_name = primaryMember.company;
      }

      setPaymentMethod({ ...res.data.payment_method, ...property, ...member});
      setLoading(false);
    });
  }, [order_uuid]);

  return (
    <ContentBlock
      title="Member Application"
      desc="Checkout"
      icon="ion-ios-contacts-outline"
    >
      <Box p={14}>
        {loading && (
          <Box
            height={200}
            justifyContent="center"
            display="flex"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        )}
        {!loading && (
          <Grid container spacing={2}>
            <Grid item md={5}>
              <Box textAlign="center">
                <Typography variant="h6" color="primary">
                  HOC Membership Application Checkout
                </Typography>
                <Typography variant="subtitle1">
                  HOC Membership Application Checkout
                </Typography>
                <Typography variant="h5">
                  ${(invoice.items[0].amount/100).toFixed(2)} USD
                </Typography>
                <img width="80" src={"/images/checkout.png"} alt="checkout" />
              </Box>
            </Grid>
            <Grid item md={7}>
              <CheckoutForm
                invoice={invoice}
                data={paymentMethod}
                handleChange={(values) =>
                  setPaymentMethod({ ...paymentMethod, ...values })
                }
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </ContentBlock>
  );
}
