import axios from "utils/axios";

const useFileManager = () => {
  // Files
  const getFiles = (data) =>
    axios.post(`${process.env.REACT_APP_API_DOMAIN}/api/v1/files`, data);

  const deleteFile = (uuid) => {
    //
  };

  const deleteFiles = (uuids) => {
    //
  };

  const vieFile = (uuids) => {};

  const downloadFile = (uuid) => {};

  const downloadFiles = (uuids) => {};

  const uploadFile = (data) =>
    axios.post(`${process.env.REACT_APP_API_DOMAIN}/api/v1/files/upload`, data);

  return {
    getFiles,
    deleteFile,
    deleteFiles,
    vieFile,
    downloadFile,
    downloadFiles,
    uploadFile,
  };
};

export default useFileManager;
