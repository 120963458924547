import {
  MENU_CONTRACTORS,
  MENU_DASH,
  MENU_JOBS,
  MENU_MEMBERS,
  MENU_TASKS,
  MENU_INVOICES,
  MENU_PAYMENTS,
  MENU_USERS,
  MENU_VENDORS,
  MENU_ROLES_AND_PERMISSIONS,
  MEMBERSHIP_READ,
  INVOICE_READ,
  JOB_READ,
  USER_READ,
  MENU_CONNECT,
  CONNECT_READ,
  MENU_SETTINGS,
  ROLE_READ,
  PAYMENT_READ,
  VENDOR_READ,
  CONTRACTOR_READ,
  TASK_READ,
  DASHBORAD_READ,
  SETTING_READ
} from "utils/permissions";

import {
  AssignmentTurnedIn,
  Dashboard,
  AssignmentInd,
  ListAlt,
  Work,
  Article,
  CreditCard,
  Group,
  DeveloperBoard,
  VerifiedUser,
  SettingsSuggest,
} from "@mui/icons-material";
import CallIcon from '@mui/icons-material/Call';


const menu = [
  {
    key: "dashboard",
    name: "Dashboard",
    icon: <Dashboard color="primary" />, //'ion-ios-stats-outline',
    link: "/app",
    permission: MENU_DASH,
    read_permission: DASHBORAD_READ,
  },
  {
    key: "task_queue",
    name: "Task Queue",
    icon: <AssignmentTurnedIn color="primary" />,
    link: "/app/task-queue",
    permission: MENU_TASKS,
    read_permission: TASK_READ,
  },
  {
    key: "members",
    name: "Members",
    icon: <AssignmentInd color="primary" />,
    link: "/app/members",
    permission: MENU_MEMBERS,
    read_permission: MEMBERSHIP_READ,
  },
  {
    key: "contractors",
    name: "Contractors",
    icon: <ListAlt color="primary" />,
    link: "/app/contractors",
    permission: MENU_CONTRACTORS,
    read_permission: CONTRACTOR_READ,
  },
  {
    key: "vendors",
    name: "Vendors",
    icon: <DeveloperBoard color="primary" />,
    link: "/app/vendors",
    permission: MENU_VENDORS,
    read_permission: VENDOR_READ,
  },
  {
    key: "jobs",
    name: "Jobs",
    icon: <Work color="primary" />,
    link: "/app/jobs",
    permission: MENU_JOBS,
    read_permission: JOB_READ,
  },
  {
    key: "invoices",
    name: "Invoices",
    icon: <Article color="primary" />,
    link: "/app/invoices",
    permission: MENU_INVOICES,
    read_permission: INVOICE_READ,
  },
  {
    key: "payments",
    name: "Payments",
    icon: <CreditCard color="primary" />,
    link: "/app/payments",
    permission: MENU_PAYMENTS,
    read_permission: PAYMENT_READ,
  },
  {
    key: "users",
    name: "Users",
    icon: <Group color="primary" />,
    link: "/app/users",
    permission: MENU_USERS,
    read_permission: USER_READ,
  },
  {
    key: "roles",
    name: "Roles",
    icon: <VerifiedUser color="primary" />,
    link: "/app/roles",
    permission: MENU_ROLES_AND_PERMISSIONS,
    read_permission: ROLE_READ,
  },
  {
    key: "Connect",
    name: "Connect",
    icon: <CallIcon color="primary" />,
    link: "/app/Connect",
    permission: MENU_CONNECT,
    read_permission: CONNECT_READ,
  },
  {
    key: "settings",
    name: "Settings",
    icon: <SettingsSuggest color="primary" />,
    link: "/app/settings",
    permission: MENU_SETTINGS,
    read_permission: SETTING_READ,
  }
];

export default menu;