import { makeStyles } from "@mui/styles";
import { queueColors } from "utils/queue";

const styles = makeStyles((theme) => ({
  jobTaskTitle: {
    color: "blue",
    paddingBottom: theme.spacing(0.5),
    fontWeight: 400,
    fontSize: 16,
  },
  modalSubtitle: {
    color: "gray",
  },
  dialogContent: {
    overflow: "auto",
  },
  actionsLabel: {
    color: "gray",
  },
  whited: {
    "& div": {
      color: "white",
    },
  },
  OPEN: { background: queueColors.OPEN },
  PROCESSING: { background: queueColors.PROCESSING },
  CLOSED: { background: queueColors.CLOSED },
  NEW_MESSAGE: { background: "darkgray" },
  NEW_ESTIMATE: { background: "#1a6187" },
  NEW_MESSAGE_EST: { background: "#1a6187" },
}));

export default styles;
