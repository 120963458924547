import React from "react";
import CountUp from "react-countup";
import { Paper, Typography } from "@mui/material";
import useStyles from "./styles-jss";

function CounterWidget(props) {
  const classes = useStyles();
  const {
    color,
    start,
    end,
    duration,
    title,
    children,
    unitBefore,
    unitAfter,
  } = props;
  return (
    <Paper className={classes.root} style={{ backgroundColor: color }}>
      <div>
        <Typography className={classes.counter}>
          {unitBefore}
          <CountUp start={start} end={end} duration={duration} useEasing />
          {unitAfter}
        </Typography>
        <Typography className={classes.title} variant="subtitle1">
          {title}
        </Typography>
      </div>
      <div className={classes.customContent}>{children}</div>
    </Paper>
  );
}

CounterWidget.defaultProps = {
  unitBefore: "",
  unitAfter: "",
};

export default CounterWidget;
