import React from "react";
import { Box, Typography, Link } from "@mui/material";

export default function JobDescriptionComponent({ description, jobId }) {
  return (
    <>
      <Typography variant="h6">Job Description</Typography>
      <Box mb={2} mt={1}>
        <Typography variant="body2">{description}</Typography>
      </Box>
      <Link href={`/app/jobs/${jobId}`} target="_blank" underline="none">
        View details
      </Link>
    </>
  );
}
