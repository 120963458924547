import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles";

const drawerWidth = 240;

const styles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
  },
  drawerPaper: {
    position: "relative",
    height: "100% !important",
    overflow: "hidden",
    border: "none",
    background: "none",
    color: theme.palette.text.primary,
    borderRight: 'none !important',
  },
  swipeDrawerPaper: {
    width: drawerWidth,
  },
  drawerInner: {
    // Make the items inside not wrap when transitioning:
    height: "100%",
    width: drawerWidth,
    position: "fixed",
  },
  drawerInnerMobile: {
    // Make the items inside not wrap when transitioning:
    height: "100%",
  },
  drawerHeader: {
    padding: "0",
    zIndex: 1,
    position: "relative",
    ...theme.mixins.toolbar,
  },
  active: {
    // border: `solid 1px ${theme.palette.primary.main}`,
    borderRight: `solid 3px ${theme.palette.primary.main}`,
    background: alpha(theme.palette.primary.main, 0.2),
    margin: theme.spacing(0.1),
    fontWeight: "bold",
    "& i": {
      color: theme.palette.primary.main,
    },
  },
  brand: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 10px 5px",
    height: 64,
    position: "relative",
    textDecoration: "none",
    fontSize: 16,
    margin: 0,
    fontWeight: 500,
    color: theme.palette.text.primary,
    "& img": {
      width: 150,
      marginRight: 10,
    },
  },
  user: {
    justifyContent: "center",
  },
  avatar: {
    margin: 10,
  },
  bigAvatar: {
    width: 80,
    height: 80,
    boxShadow: theme.glow.light,
  },
  menuContainer: {
    overflow: "auto",
    height: "calc(100% - 64px)",
    width: drawerWidth,
    position: "relative",
    display: "block",
    padding: `${theme.spacing(5)}px 0`,
    paddingTop: theme.spacing(5),
    "&$rounded": {
      paddingRight: theme.spacing(1.5),
      "& a": {
        borderRadius: `0 ${theme.spacing(3)}px ${theme.spacing(3)}px 0`,
      },
    },
    "&::-webkit-scrollbar": {
      width: 8,
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 12,
      backgroundColor: "rgba(0,0,0,0)",
    },
    "&:hover": {
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,0.3)",
        border: "1px solid rgba(255,255,255,0.4)",
      },
    },
  },
  rounded: {},
  withProfile: {},
  iconed: {},
  primary: {
    whiteSpace: "nowrap",
  },
  head: {
    padding: `${theme.spacing(1)} 0px`,
    margin: `${theme.spacing(1)} 0px`,
    paddingLeft: theme.spacing(),
    "&$iconed": {
      paddingLeft: theme.spacing(3),
    },
    '& svg[class^="MuiSvgIcon"]': {
      left: -10,
      position: "relative",
    },
  },
  icon: {
    fontSize: 24,
    minWidth: "30px!important",
  },
}));

export default styles;
