import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import PaperBlock from "components/PaperBlock";
import useApplication from "hooks/useApplication";
import { createVendorRequest } from "api/vendors";
import VendorForm from "./components/Form";
import '../../../../utils/sweetalert.css'

export default function Create() {
  const { setPage } = useApplication();
  const navigate = useNavigate();

  useEffect(() => {
    setPage({
      title: "Add Vendor",
      path: "/app/vendors",
    });

    // eslint-disable-next-line
  }, []);

  const onSubmit = async (values) => {
    try {
      const response = await createVendorRequest(values);
      if (response.data) {
        Swal.fire({
          icon: "success",
          text: "Vendor added",
          title: "Success!",
          timer: 4000,
          customClass: {
            title: "swal-text",
            popup:"swal-text"
            
          },
          timerProgressBar: true,
        }).then((result) => {
          navigate("/app/vendors");
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: "Try again later",
        title: error.response.data.message,
        title: error.response.data.message,
        timer: 4000,
        customClass: {
          title: "swal-text",
          popup:"swal-text"
          
        },
        timerProgressBar: true,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/app/vendors");
        }
      });
    }
  };

  return (
    <PaperBlock title="Add Vendor">
      <VendorForm onSubmit={onSubmit} />
    </PaperBlock>
  );
}
