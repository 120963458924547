import React, { useEffect, useState } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material"
import LinkBtn from "components/LinkBtn";
import brand from "theme/brand";
import FormField from "components/FormField";
import PrimaryServiceAreaModal from "components/MembershipForm/PrimaryServiceAreaModal";

import MembershipForm from "components/MembershipForm/MembershipForm";
import LoadingButton from "components/LoadingButton";
import { checkZipRequest } from "api/utilities";
import MemberApplicationContext from "components/MembershipForm/MemberApplicationFormContext";
import {
  contact,
  initialValues,
  property,
} from "components/MembershipForm/formConfig";
import { saveApplicationWebRequest } from "api/membership";
import useUtils from "hooks/useUtils";
import FormCompleted from "components/MembershipForm/FormCompleted";
import useStyles from "./style-jss";

export default function MembershipApplication() {
  const classes = useStyles();
  const { getDefaults } = useUtils();
  const [state, _setState] = useState({
    validZip: false,
    zip: "98112",
    zipLoading: false,
    showModal: false,
    zipData: {},
    formLoading: false,
    completed: false,
  });

  const [form, _setForm] = useState(initialValues);

  const setForm = (values) => _setForm({ ...form, ...values });

  const setState = (values) => _setState({ ...state, ...values });

  const addMember = () => {
    const _members = form.members;
    _members.push({ ...contact });
    setForm({ members: _members });
  };

  const addProperty = () => {
    const _properties = form.properties;
    _properties.push({ ...property });
    setForm({ properties: _properties });
  };

  const checkZip = () => {
    if (state.zip) {
      setState({ zipLoading: true });
      checkZipRequest({ zip: state.zip })
        .then((resp) => {
          setState({
            zipData: resp.data.details,
            validZip: true,
            zipLoading: false,
          });
        })
        .catch((error) => {
          setState({
            zipData: error.response.data,
            validZip: false,
            zipLoading: false,
          });
        });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setState({ formLoading: true });
    saveApplicationWebRequest(form).then((res) => {
      setState({ formLoading: false, completed: res.data.membership });
    });
  };

  useEffect(() => {
    getDefaults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { validZip } = state;

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.userFormWrap}>
          <Paper className={classes.jobRequestWrap}>
            <div className={classes.headerSection}>
              <Grid
                container
                alignItems="center"
                spacing={1}
                className={classes.headerContent}
              >
                <Grid item md={3}>
                  <LinkBtn to="/">
                    <img
                      src={brand.logo}
                      className={classes.logo}
                      alt="hocLogo"
                    />
                  </LinkBtn>
                </Grid>
                <Grid item md={9}>
                  <Box>
                    <Typography textAlign="right" color="primary" variant="h6">
                      MEMBERSHIP APPLICATION
                    </Typography>
                    <Typography
                      textAlign="right"
                      variant="caption"
                      component="p"
                    >
                      PO Box 19073, Seattle, WA 98109-1073, 206-622-3500
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </div>
            <section className={classes.formWrap}>
              {!validZip && (
                <section className={classes.formSection}>
                  <Typography
                    className={classes.sectionTitle}
                    variant="h6"
                    color="primary"
                  >
                    Become a Member
                  </Typography>
                  <Grid container>
                    <Grid item md={12}>
                      <Typography
                        variant="subtitle2"
                        className={classes.contentText}
                      >
                        For over 60 years, local homeowners have trusted the
                        Home Owners Club for quality upkeep, repairs and
                        improvements at fair prices.
                      </Typography>
                    </Grid>
                    <Grid item md={9}>
                      <Typography
                        variant="subtitle2"
                        className={classes.contentText}
                      >
                        Membership in the Club costs just $60 per year.
                        Additional residential or commercial properties you own
                        may be included for $10 each.
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.contentText}
                      >
                        Membership for condominium associations is $80 per year
                        (with individual unit owners within the association
                        eligible to then join for only $10 each.)
                      </Typography>
                    </Grid>
                    <Grid item md={3}>
                      <img
                        src="/images/bbb.png"
                        alt="HOC"
                        className={classes.logoImage}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Typography
                        variant="subtitle2"
                        className={classes.contentText}
                      >
                        To be eligible for membership, all properties you want
                        covered must be within the Club's{" "}
                        <LinkBtn
                          to="#"
                          onClick={() => setState({ showModal: true })}
                        >
                          primary service area
                        </LinkBtn>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.contentText}
                      >
                        To become a member:
                      </Typography>
                    </Grid>
                  </Grid>
                </section>
              )}
              <section className={classes.formSection}>
                <Grid container>
                  <Grid item md={12}>
                    <Typography variant="subtitle2">
                      Enter your zip code to confirm your property is in the
                      Club's primary service area:
                    </Typography>
                  </Grid>
                  <Grid item md={12}>
                    <Box alignItems="center" display="flex" pt={1}>
                      <Box mr={2}>
                        <FormField
                          value={state.zip}
                          onChange={(e) => setState({ zip: e.target.value })}
                          fullWidth={false}
                          size="small"
                          label="Zip Code"
                        />
                      </Box>
                      <Box>
                        <LoadingButton
                          loading={state.zipLoading}
                          onClick={checkZip}
                          className={classes.zipBtn}
                          size="small"
                          variant="contained"
                          color="primary"
                        >
                          Check Zip Code
                        </LoadingButton>
                      </Box>
                    </Box>
                  </Grid>
                  {!validZip && state.zipData.hasOwnProperty("errors") && (
                    <Grid item md={12}>
                      <Typography
                        paddingTop={1}
                        className={classes.errorText}
                        variant="subtitle2"
                      >
                        {state.zipData.errors.zip[0]}
                      </Typography>
                    </Grid>
                  )}
                  {validZip && (
                    <Grid item md={12}>
                      <Typography
                        paddingTop={1}
                        className={classes.successText}
                        variant="subtitle2"
                      >
                        Your property in (input value) appears to be located
                        Partial from the Club's primary service area
                      </Typography>
                      <Typography paddingTop={1} variant="subtitle2">
                        You can download, print and mail your membership
                        application or use the web form below to complete the
                        member application online.
                      </Typography>
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <img
                          src="/images/pdf_icon.svg"
                          alt="HOC"
                          className={classes.pdfImage}
                        />
                        <a
                          href="https://www.homeownersclub.com/n/app.php"
                          rel="noreferrer"
                          target="_blank"
                          className={classes.pdfLink}
                        >
                          Print or Download PDF Application
                        </a>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </section>
              <section className={classes.formSection}>
                {validZip && (
                  <MemberApplicationContext.Provider
                    value={{
                      form,
                      setForm,
                      defaultLocation: state.zipData,
                      addMember,
                      addProperty,
                      handleSubmit,
                      formLoading: state.formLoading,
                    }}
                  >
                    <MembershipForm />
                  </MemberApplicationContext.Provider>
                )}
              </section>
            </section>
          </Paper>
        </div>
      </div>
      <PrimaryServiceAreaModal
        open={state.showModal}
        handleClose={() => setState({ showModal: false })}
      />
      {state.completed !== false && <FormCompleted {...state.completed} />}
    </div>
  );
}
