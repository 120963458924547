const columns = (renderStatus, renderAction) => [
  {
    field: "id",
    headerName: "ID#",
    sortable: true,
    type: "string",
    flex: 0.2,
  },
  {
    field: "name",
    headerName: "Name",
    sortable: true,
    flex: 2,
  },
  {
    field: "phone",
    headerName: "Phone",
    type: "string",
    editable: false,
    sortable: true,
    flex: 1,
  },
  {
    field: "job_id",
    headerName: "Job id",
    editable: false,
    type: "number",
    sortable: true,
    flex: 1,
  },
  {
    field: "job_category",
    headerName: "Job category",
    type: "string",
    flex: 1,
  },
  {
    field: "job_code",
    headerName: "Job code",
    type: "string",
    flex: 0.5,
  },
  {
    field: "job_status",
    headerName: "Job status",
    type: "date",
    editable: false,
    sortable: true,
    flex: 1.5,
    renderCell: renderStatus,
  },
  {
    field: "action",
    headerName: "Action",
    type: "string",
    flex: 2,
    sortable: false,
    renderCell: renderAction,
  },
];

export default columns;
