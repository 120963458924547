import { useDispatch, useSelector } from "react-redux";
import { SET_UTILS } from "redux/actions";
import {
  defaultsRequest,
  getServiceLocationsRequest,
  getStatesRequest
} from "api/utilities";
import { getCategoriesRequest, getCodesRequest } from "api/jobs";

function useUtils() {
  const dispatch = useDispatch();

  const utils = useSelector((store) => store.utils);

  const setValues = (prop, values) =>
    dispatch({ type: SET_UTILS, prop, values });
    
  const getDefaults = () => {
    defaultsRequest().then((res) => {
      setValues("states", res.data.states);
      setValues("users", res.data.users);
      setValues("roles", res.data.roles);
      setValues("vendor_types", res.data.vendor_types);
      setValues("categories", res.data.categories);
      setValues("communication_prefs", res.data.communication_prefs);
      setValues("terms", res.data.terms);
      setValues("permissions", res.data.permissions);
      setValues("members", res.data.members);
    });
  };

  const getStates = (params) => {
    getStatesRequest(params).then((res) => {
      setValues("states", res.data.states);
    });
  };

  const getJobCategories = () => {
    //TODO: save in reducer
    return getCategoriesRequest();
  };

  const getCategoryCodes = (category_id) => {
    return getCodesRequest(category_id);
  };

  const getServiceLocations = () => {
    return getServiceLocationsRequest().then((res) => {
      setValues("serviceLocations", res.data.serviceLocations);
    });
  };

  const userNameById = (user_id) => {
    const userIndex = utils.users.findIndex((u) => u.id === parseInt(user_id));
    return userIndex !== -1 ? `${utils.users[userIndex].name}` : "Unassigned";
  };

  const userById = (user_id) => {
    const userIndex = utils.users.findIndex((u) => u.id === parseInt(user_id));
    return userIndex !== -1 ? utils.users[userIndex] : false;
  };

  return {
    utils,
    setValues,
    getStates,
    getDefaults,
    getJobCategories,
    getServiceLocations,
    getCategoryCodes,
    userNameById,
    userById,
  };
}

export default useUtils;
