/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Box from "@mui/material/Box";

/* Tab Container */
function TabContainer(props) {
  const { children } = props;
  return <Box mt={2}>{children}</Box>;
}

export default TabContainer;
