import { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  Paper,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import axios from "utils/axios";

const CsvViewer = ({ file }) => {
  const [csvData, setCsvData] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}/api/v1/file-preview/${file.uuid}`
      )
      .then((response) => {
        setCsvData(response.data);
      });
  }, [file.uuid]);

  if (csvData.length === 0) return null;
  const csvTableHeader = csvData.split("\n")[0].split(";");

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              "& td, & th": { border: 1 },
            }}
          >
            {csvTableHeader.map((header, index) => (
              <TableCell key={index}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {csvData.split("\n").map((csvRow, rowIndex) => {
            if (rowIndex === 0) return null;
            return (
              <TableRow
                key={`row-${rowIndex}`}
                sx={{ "& td, & th": { border: 1 } }}
              >
                {csvRow.split(";").map((csvCell, cellIndex) => (
                  <TableCell key={`element-${cellIndex}`}>{csvCell}</TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CsvViewer;
