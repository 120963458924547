import React from "react";
import FormField from "components/FormField";
import { Box, Button } from "@mui/material";
import ContractorsSelector from "../ContractorsSelector";
import EstimateMessage from "./EstimateMessage";
import useAccessControl from "hooks/useAccessControl";

const NewEstimates = ({
  status,
  state,
  setState,
  handleSubmit,
  handleChangeEstimateMessage,
}) => {
  const { canCreateJobEstimate } = useAccessControl();
  return (
    <>
      <ContractorsSelector
        handleChange={setState}
        selected={state.estimates_request ?? []}
      />
      <Box mt={3}>
        <FormField
          value={state.note}
          onChange={(e) => setState({ note: e.target.value })}
          placeholder="#add note"
        />
      </Box>
      <Box mt={3}>
        {state.estimates_request.map((estimate) => (
          <EstimateMessage
            key={`c_estimate-${estimate.contractor.id}`}
            estimate={estimate}
            handleChange={handleChangeEstimateMessage}
          />
        ))}
      </Box>
      {handleSubmit && status === "ESTIMATE" && canCreateJobEstimate() && (
        <Box textAlign={"center"} mt={3}>
          <Button
            onClick={handleSubmit}
            disabled={
              !state.estimates_request?.length || state.loadingEstimates
            }
            role="submit"
            type="submit"
            variant="contained"
            color="primary"
          >
            {state.loadingEstimates ? "Please wait" : "Send Estimate Request"}
          </Button>
        </Box>
      )}
    </>
  );
};

export default NewEstimates;
