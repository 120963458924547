import React, { forwardRef } from "react";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import useAccessControl from "hooks/useAccessControl";
import useStyles from "./Sidebar-jss";
import menuItems from "./menuItems";

const LinkBtn = forwardRef((props, ref) => {
  return <NavLink to={props.to} {...props} />; // eslint-disable-line
});

const Menu = ({ app }) => {
  const dataMenu = menuItems;
  const classes = useStyles();
  const { hasPermission } = useAccessControl();

  const renderMenu = (menuArray) =>
    menuArray.map((item, index) => {
      
      if (item.read_permission && !hasPermission(item.read_permission)) {
        return false;
      }
      return (
        <div
          className={app.activePage.path === item.link ? classes.active : ""}
          key={index.toString()}
        >
          <ListItem
            button
            component={LinkBtn}
            dense
            to={item.link ? item.link : "#"}
            className={classNames(classes.head, classes.iconed)}
          >
            {item.icon && (
              <ListItemIcon className={classes.icon}>{item.icon}</ListItemIcon>
              
            )}
            <ListItemText
              classes={{ primary: classes.primary }}
              variant="inset"
              primary={item.name}
            />
          </ListItem>
        </div>
      );
    });

  return <div>{renderMenu(dataMenu)}</div>;
};

export default Menu;
