import axios from "utils/axios";
import { serialize } from "utils/functions";
import {
  CHECK_ZIPCODE_ROUTE,
  MEMBERSHIP_TYPES_ROUTE,
  SEARCH_ZIP_ROUTE,
  DEFAULTS_ROUTE,
  CITIES_BY_STATE_ROUTE,
  CALCULATE_PRICE_ROUTE,
  SEARCH_CONTRACTOR_ROUTE,
  VIEW_INVOICE_ROUTE,
  SERVICE_LOCATIONS_ROUTE,
  STATES_ROUTE
} from "./routes";

const checkZipRequest = (data) => axios.post(CHECK_ZIPCODE_ROUTE, data);
const membershipTypesRequest = () => axios.get(MEMBERSHIP_TYPES_ROUTE);

const searchZipDataRequest = (zip) => axios.post(SEARCH_ZIP_ROUTE, { zip });

const defaultsRequest = (data) => axios.post(DEFAULTS_ROUTE, data);

const getStatesRequest = (params) => axios.get(`${STATES_ROUTE}?${serialize(params)}`);

const getCitiesByStateRequest = (data) =>
  axios.post(CITIES_BY_STATE_ROUTE, data);

const getServiceLocationsRequest = (data) =>
  axios.post(SERVICE_LOCATIONS_ROUTE, data);

const calculatePriceRequest = (data) => axios.post(CALCULATE_PRICE_ROUTE, data);

const searchContractorsRequest = (search, limit = 10) =>
  axios.get(SEARCH_CONTRACTOR_ROUTE, { params: { search, limit } });

const getInvoiceDetails = (invoice) =>
  axios.get(`${VIEW_INVOICE_ROUTE}/${invoice}`);

export {
  checkZipRequest,
  getStatesRequest,
  membershipTypesRequest,
  searchZipDataRequest,
  defaultsRequest,
  getServiceLocationsRequest,
  getCitiesByStateRequest,
  calculatePriceRequest,
  searchContractorsRequest,
  getInvoiceDetails,
};
