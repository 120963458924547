import {
  Box,
  Grid,
  TextField,
} from "@mui/material";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import dayjs from "dayjs";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import { DesktopDatePicker } from "@mui/lab";

const AdditionalFields = ({
  invoice = {},
  onChange = () => {},
  withActions = false,
  errors = {}
}) => {
  return (
    <>
      <Box mb={4}>
        <Box mb={4}>Additional Details<hr /></Box>
        <Grid container spacing={2}>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              size="small"
              label="RefNumber"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={invoice.ref_number}
              onChange={(e) => onChange({ ref_number: e.target.value })}
              error={Boolean(errors.ref_number)}
              helperText={errors.ref_number}
              disabled={!withActions}
            />
          </Grid>
          {/* <Grid item lg={3} md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              size="small"
              label="PO Number"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={invoice.po_number}
              onChange={(e) => onChange({ po_number: e.target.value })}
              error={Boolean(errors.po_number)}
              helperText={errors.po_number}
              disabled={!withActions}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Class"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={invoice.class}
              onChange={(e) => onChange({ class: e.target.value })}
              error={Boolean(errors.class)}
              helperText={errors.class}
              disabled={!withActions}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Template Name"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={invoice.template_name}
              onChange={(e) => onChange({ template_name: e.target.value })}
              error={Boolean(errors.template_name)}
              helperText={errors.template_name}
              disabled={!withActions}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Rep"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={invoice.rep}
              onChange={(e) => onChange({ rep: e.target.value })}
              error={Boolean(errors.rep)}
              helperText={errors.rep}
              disabled={!withActions}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Cust. Tax Code"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={invoice.cus_tax_code}
              onChange={(e) => onChange({ cus_tax_code: e.target.value })}
              error={Boolean(errors.cus_tax_code)}
              helperText={errors.cus_tax_code}
              disabled={!withActions}
            />
          </Grid> */}
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Item"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={invoice.item}
              onChange={(e) => onChange({ item: e.target.value })}
              error={Boolean(errors.item)}
              helperText={errors.item}
              disabled={!withActions}
            />
          </Grid>
          {/* <Grid item lg={3} md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Item Line Class"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={invoice.item_line_class}
              onChange={(e) => onChange({ item_line_class: e.target.value })}
              error={Boolean(errors.item_line_class)}
              helperText={errors.item_line_class}
              disabled={!withActions}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Service Date"
                inputFormat="MM/dd/yyyy"
                value={invoice.service_date}
                onChange={(date) =>onChange({ service_date: dayjs(date).format("YYYY-MM-DD") })}
                renderInput={(params) => (
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    fullWidth
                    size="small"
                    disabled={!withActions}
                    error={Boolean(errors.service_date)}
                    helperText={errors.service_date}
                    {...params}
                  />
                )}
                // onChange={(e) => onChange({ ship_date: e.target.value })}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              size="small"
              label="FOB"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={invoice.fob}
              onChange={(e) => onChange({ fob: e.target.value })}
              error={Boolean(errors.fob)}
              helperText={errors.fob}
              disabled={!withActions}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Customer Acct No"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={invoice.customer_acct_no}
              onChange={(e) => onChange({ customer_acct_no: e.target.value })}
              error={Boolean(errors.customer_acct_no)}
              helperText={errors.customer_acct_no}
              disabled={!withActions}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Sales Tax Item"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={invoice.sales_tax_item}
              onChange={(e) => onChange({ sales_tax_item: e.target.value })}
              error={Boolean(errors.sales_tax_item)}
              helperText={errors.sales_tax_item}
              disabled={!withActions}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              size="small"
              label="To Be E-Mailed"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={invoice.to_be_emailed}
              onChange={(e) => onChange({ to_be_emailed: e.target.value })}
              error={Boolean(errors.to_be_emailed)}
              helperText={errors.to_be_emailed}
              disabled={!withActions}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Other"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={invoice.other}
              onChange={(e) => onChange({ other: e.target.value })}
              error={Boolean(errors.other)}
              helperText={errors.other}
              disabled={!withActions}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Other1"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={invoice.other_1}
              onChange={(e) => onChange({ other_1: e.target.value })}
              error={Boolean(errors.other_1)}
              helperText={errors.other_1}
              disabled={!withActions}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Other2"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={invoice.other_2}
              onChange={(e) => onChange({ other_2: e.target.value })}
              error={Boolean(errors.other_2)}
              helperText={errors.other_2}
              disabled={!withActions}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Unit of Measure"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={invoice.unit_of_measure}
              onChange={(e) => onChange({ unit_of_measure: e.target.value })}
              error={Boolean(errors.unit_of_measure)}
              helperText={errors.unit_of_measure}
              disabled={!withActions}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              size="small"
              label="AR Account"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={invoice.ar_account}
              onChange={(e) => onChange({ ar_account: e.target.value })}
              error={Boolean(errors.ar_account)}
              helperText={errors.ar_account}
              disabled={!withActions}
            />
          </Grid> */}
        </Grid>
      </Box>
    </>
  );
};


export default AdditionalFields;
