import React, { useEffect } from 'react';
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useActivityTracking from 'hooks/useActivityTracking';
import TableFooterWithPagination from 'components/TableFooterWithPagination';

const ActivityTrackingJob = ({ user }) => {
  const {
    getActivityTracking,
    activityTrackingReducer : { data, meta },
    setActivityTrackingPerPage,
    setActivityTrackingPage
  } = useActivityTracking();
  const navigate = useNavigate();
  const { current_page, per_page } = meta;

  useEffect(() => {
    getActivityTracking({ entity: 'job', page: 1, per_page, userId: user.id });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (page) => {
    setActivityTrackingPage(page);
    getActivityTracking({ entity: 'job', page: page, per_page, userId: user.id });
  }
  const handleChangePerPage = (perPage) => {
    setActivityTrackingPerPage(parseInt(perPage));
    getActivityTracking({ entity: 'job', page: current_page, per_page: perPage, userId: user.id });
  }

  return (
    <Box sx={{ width: { sx: "100%", md: "100%" } }}>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>#ID</TableCell>
            <TableCell>Job Type</TableCell>
            <TableCell>Member name</TableCell>
            <TableCell>Member ID</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((activityTrackingItem, index) => {
            const entityData = JSON.parse(activityTrackingItem.entity.data);

            return (
              <TableRow key={`joblog-${index}`}>
                <TableCell component="th" scope="row">
                  <Link href="#" onClick={() => navigate(`/app/jobs/${entityData.id}`)}>
                    {entityData.id}
                  </Link>
                </TableCell>
                <TableCell component="th" scope="row">
                  {entityData?.code?.description}

                </TableCell>
                <TableCell component="th" scope="row">
                  {entityData?.contact && (
                    `${entityData?.contact?.first_name} ${entityData?.contact?.last_name}`
                  )}
                </TableCell>
                <TableCell component="th" scope="row">
                  {entityData?.contact?.id}
                </TableCell>
                <TableCell component="th" scope="row">
                  {entityData?.property?.address}
                </TableCell>
                <TableCell component="th" scope="row">
                  {entityData?.status}
                </TableCell>
                <TableCell component="th" scope="row">
                  {activityTrackingItem?.entity?.action}
                </TableCell>
              </TableRow>
            )
          })}
          {!data.length && (
            <TableRow>
              <TableCell colSpan={7} align="center">
                No data
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        {data.length > 0 && (
          <TableFooterWithPagination
            count={meta.total}
            rowsPerPage={meta.per_page}
            page={meta.current_page - 1}
            onPageChange={(_ev, newPage) => handleChangePage(newPage + 1)}
            onRowsPerPageChange={(e) => handleChangePerPage(parseInt(e.target.value))}
          />
        )}
      </Table>
    </Box>
  )
}

export default ActivityTrackingJob;