import { 
  TableRow, 
  TableFooter, 
  TablePagination 
} from "@mui/material";

const TableFooterWithPagination = ({
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
  colSpan,
}) => {
  return (
    <TableFooter
    >
        <TableRow>
          <TablePagination
            // component="div"
            sx={{
              '.MuiTablePagination-toolbar': {
                alignItems: 'baseline',
              },
            }}
            colSpan={colSpan}
            rowsPerPageOptions={[2, 10, 25, 
              // { label: "All", value: 100 }
            ]}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: {
                "aria-label": "rows per page",
              },
              native: true,
            }}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </TableRow>
    </TableFooter>
  );
};

export default TableFooterWithPagination;
