import axios from "utils/axios";
import { SETUP_INTENT_ROUTE, PAY_INVOICE_ROUTE } from "./routes";

const GET_PAYMENTS_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/payments`;

export const setupIntentRequest = (inv_id, data) =>
  axios.post(`${SETUP_INTENT_ROUTE}/${inv_id}`, data);
export const payInvoice = (inv_id, data) =>
  axios.post(`${PAY_INVOICE_ROUTE}/${inv_id}`, data);

export const getPaymentsRequest = (params) =>
  axios.get(GET_PAYMENTS_ROUTE, { params });

export const viewPaymentRequest = (id) =>
  axios.get(`${GET_PAYMENTS_ROUTE}/${id}`);
