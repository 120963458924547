import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce";
import {
  SEARCH_SERVICE_LOCATIONS,
  SEARCH_SERVICE_LOCATIONS_RESULTS,
  CHANGE_PAGE,
  CHANGE_PER_PAGE,
  SEARCH_SERVICE_LOCATIONS_LOADING,
} from "redux/actions/serviceLocations";
import {
  getServiceLocationsRequest,
  getServiceLocationRequest,
  createServiceLocationRequest,
  updateServiceLocationRequest,
  deleteServiceLocationRequest
} from "api/serviceLocations";

const useServiceLocations = () => {
  const dispatch = useDispatch();
  const serviceLocationsReducer = useSelector((state) => state.serviceLocations);

  const getServiceLocations = (params) => {
    dispatch({ type: SEARCH_SERVICE_LOCATIONS_LOADING, loading: true });
    return getServiceLocationsRequest(params)
      .then((res) => {
        dispatch({ type: SEARCH_SERVICE_LOCATIONS_RESULTS, results: res.data });
      })
      .finally(() => {
        if (serviceLocationsReducer.loading) {
          dispatch({ type: SEARCH_SERVICE_LOCATIONS_LOADING, loading: false });
        }
      });
  };

  const search = debounce((query) => {
    dispatch({ type: SEARCH_SERVICE_LOCATIONS, query });
    getServiceLocationsRequest({ search: query }).then((res) => {
      dispatch({ type: SEARCH_SERVICE_LOCATIONS_RESULTS, results: res.data });
    });
  }, 600);

  const changePage = (newPage) => {
    dispatch({ type: CHANGE_PAGE, page: newPage + 1 });
  };

  const changePerPage = (event) => {
    dispatch({ type: CHANGE_PER_PAGE, perPage: parseInt(event.target.value) });
  };

  const getServiceLocation = (serviceLocationId) => getServiceLocationRequest(serviceLocationId);

  const createServiceLocation = (data) => createServiceLocationRequest(data);
  const updateServiceLocation = (data) => updateServiceLocationRequest(data);
  const deleteServiceLocation = (serviceLocationId) => deleteServiceLocationRequest(serviceLocationId);

  return {
    serviceLocationsReducer,
    getServiceLocations,
    getServiceLocation,
    createServiceLocation,
    updateServiceLocation,
    deleteServiceLocation,
    search,
    changePage,
    changePerPage,
  };
};

export default useServiceLocations;
