import React from "react";
import { Typography, MenuItem } from "@mui/material";
import FormField from "components/FormField";
import { statusList } from "utils/jobStatuses";
import useStyles from "./style-jss";

const JobStatus = ({ job, changeJobStatus, showLabel }) => {
  const classes = useStyles();
  return (
    <div>
      {showLabel && (
        <Typography variant="subtitle2" className={classes.actionsLabel}>
          Status
        </Typography>
      )}
      <FormField
        select
        fullWidth={false}
        value={job.status}
        className={classes.whited}
        classes={{ root: classes[job.status] }}
        onChange={(e) => changeJobStatus(e.target.value)}
      >
        {job.status === "NEW" && <MenuItem value={"NEW"}>NEW</MenuItem>}
        {statusList.map((status) => (
          <MenuItem key={`status-${status}`} value={status}>
            {status}
          </MenuItem>
        ))}
      </FormField>
    </div>
  );
};

JobStatus.defaultProps = {
  showLabel: true,
};

export default JobStatus;
