import React, { useEffect, useState } from "react";
import { Tab, Box } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import PaperBlock from "components/PaperBlock";
import useApplication from "hooks/useApplication";
// import ServiceLocationsIndex from "./ServiceLocations/Index";
// import PhoneNumbers from "./PhoneNumbers";
import Member from "./components/Members";
import DeletedMembers from "./components/DeletedMembers";

const Members = () => {
  const [tabValue, setTabValue] = useState("0");
  const { setPage } = useApplication();

  useEffect(() => {
    setPage({
      title: "Members Directory",
      path: "/app/members",
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PaperBlock
        title="Members"
      >
        <Box sx={{ flexGrow: 1, bgcolor: "background.paper" }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={(_event, newValue) => setTabValue(newValue)}>
                <Tab
                  label="Members"
                  value="0"
                />
                <Tab
                  label="Deleted Members"
                  value="1"
                />
              </TabList>
            </Box>
            <TabPanel value="0">
              <Member />
            </TabPanel>
            <TabPanel value="1">
              <DeletedMembers />
            </TabPanel>
          </TabContext>
        </Box>
      </PaperBlock>
    </>
  );
};

export default Members;
