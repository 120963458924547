import debounce from "lodash.debounce";
import { useDispatch, useSelector } from "react-redux";
import { searchRequest, getVoiceMailContractorsRequest } from "api/voiceCallMail";

import {
    CHANGE_PAGE_VOICE_MAIL,
    CHANGE_PER_PAGE_VOICE_MAIL,
    SEARCH_MEMBERS_VOICE_MAIL,
    SEARCH_MEMBERS_LOADING_VOICE_MAIL,
    SEARCH_MEMBERS_RESULTS_VOICE_MAIL
} from "redux/actions/voiceCallMailAction";

const useVoiceCallMail = () => {
    const dispatch = useDispatch();
    const voiceCallMailReducer = useSelector((state) => state.voicecallMail);

    const getVoiceCallMail = (params) => {
        dispatch({ type: SEARCH_MEMBERS_LOADING_VOICE_MAIL, loading: true });
        return searchRequest({ ...params })
            .then((res) => {
                dispatch({ type: SEARCH_MEMBERS_RESULTS_VOICE_MAIL, results: { results: res.data[0].result.records, meta: res.data[0].meta, accessToken: res.data[0].access_token } });
            })
            .finally(() => {
                if (voiceCallMailReducer.loading) {
                    dispatch({ type: SEARCH_MEMBERS_LOADING_VOICE_MAIL, loading: false });
                }
            });
    };

    const search = debounce((query) => {
        dispatch({ type: SEARCH_MEMBERS_VOICE_MAIL, query });
    }, 600);

    const getVoiceMailContractors = (data) =>
        getVoiceMailContractorsRequest(data);

    const changePage = (newPage) => {
        dispatch({ type: CHANGE_PAGE_VOICE_MAIL, page: newPage + 1 });

    };

    const changePerPage = (event) => {
        dispatch({ type: CHANGE_PER_PAGE_VOICE_MAIL, perPage: parseInt(event.target.value) });
    };

    return {
        voiceCallMailReducer,
        search,
        getVoiceMailContractors,
        changePage,
        changePerPage,
        getVoiceCallMail,
    };
};

export default useVoiceCallMail;
