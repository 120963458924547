import React from "react";
import { useNavigate } from "react-router-dom";
import { TableRow, TableCell, Button } from "@mui/material";
import dayjs from "dayjs";
import useAccessControl from "hooks/useAccessControl";

const InvoiceRow = ({ data }) => {
  const navigate = useNavigate();
  const { canViewInvoice } = useAccessControl();

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {data.id}
      </TableCell>
      <TableCell component="th" scope="row">
        {data.description}
      </TableCell>
      <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
        ${(data.items[0].amount / 100).toFixed(2)
        }
      </TableCell>
      <TableCell component="th" scope="row">
        {data.payment_method}
      </TableCell>
      <TableCell component="th" scope="row">
        {dayjs(data.created_at).format("YYYY-MM-DD")}
      </TableCell>
      <TableCell component="th" scope="row">
        {data.qbe_sent ? 'Yes' : 'No'}
      </TableCell>
      <TableCell component="th" scope="row">
        {data.status}
      </TableCell>
      <TableCell component="th" scope="row" align="right">
        {canViewInvoice() && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => navigate(`/app/invoices/${data.id}`)}
          >
            View
          </Button>
        )}
        {data.status !== "REFUND" && data.status !== "PAID" && (
          <Button
            variant="contained"
            color="error"
            sx={{ ml: 2 }}
            size="small"
            onClick={() => navigate(`/app/members/checkout/${data.uuid}`)}
          >
            Pay
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

export default InvoiceRow;
