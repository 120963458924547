import React from "react";
import { Typography, Paper, Box, CircularProgress } from "@mui/material";
import BreadCrumb from "components/BreadCrumb/BreadCrumb";

function ContractorBlocks({
    title,
		loading,
    actions,
		children
}) {
  return (
    <>
    	<BreadCrumb />
		{/* style={{"overflow":"auto"}} */}
      <Paper elevation={2} sx={{ p: 2, mt: 2 }} style={{"overflow":"auto"}}>
				<Box justifyContent="space-between" alignItems="center" className="custmFlex" px={2}>
					<Typography
						variant="h5"
						component="h5"
						fontWeight="bold"
						color="primary"
					>
						{title}
					</Typography>
					<Box display="flex" alignItems="center" justifyContent="flex-end">
						{actions}
					</Box>
				</Box>
				<Box mt={4}>
					{loading && (
						<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							<CircularProgress />
						</Box>
					)}
					{!loading && children}
				</Box>
      </Paper>
    </>
  );
}

ContractorBlocks.defaultProps = {
  title: '',
  actions: null,
  children: null,
};

export default ContractorBlocks;
