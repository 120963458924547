import React, { useEffect } from 'react';
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useActivityTracking from 'hooks/useActivityTracking';
import TableFooterWithPagination from 'components/TableFooterWithPagination';

const ActivityTrackingMembership = ({ user }) => {
  const {
    getActivityTracking,
    activityTrackingReducer : { data, meta },
    setActivityTrackingPerPage,
    setActivityTrackingPage
  } = useActivityTracking();
  const { current_page, per_page } = meta;
  const navigate = useNavigate();

  useEffect(() => {
    getActivityTracking({ entity: 'membership', page: 1, per_page, userId: user.id });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (page) => {
    setActivityTrackingPage(page);
    getActivityTracking({ entity: 'membership', page: page, per_page, userId: user.id });
  }
  const handleChangePerPage = (perPage) => {
    setActivityTrackingPerPage(parseInt(perPage));
    getActivityTracking({ entity: 'membership', page: current_page, per_page: perPage, userId: user.id });
  }

  return (
    <Box sx={{ width: { sx: "100%", md: "100%" } }}>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>#ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((activityTrackingItem, index) => {
            const entityData = JSON.parse(activityTrackingItem.entity.data);
            const primaryMemberIndex = entityData?.contacts?.findIndex((contact) => contact.contacts_pivot.is_primary === 1)
            let contact = null;
            let property = null;
            if(primaryMemberIndex > -1) {
              contact = entityData?.contacts[primaryMemberIndex];
            }
            const primaryPropertyIndex = entityData?.properties?.findIndex((property) => property.is_primary === 1)
            if(primaryPropertyIndex > -1) {
              property = entityData?.properties[primaryPropertyIndex];
            }

            return (
              <TableRow key={`membershiplog-${index}`}>
                <TableCell component="th" scope="row">
                  <Link href="#" onClick={() => navigate(`/app/members/${entityData.id}`)}>
                    {entityData.id}
                  </Link>
                </TableCell>
                <TableCell component="th" scope="row">
                  {contact && (
                    `${contact?.first_name} ${contact?.last_name}`
                  )}
                </TableCell>
                <TableCell component="th" scope="row">
                  {property?.address}
                </TableCell>
                <TableCell component="th" scope="row">
                  {contact?.cell_phone}
                </TableCell>
                <TableCell component="th" scope="row">
                  {contact?.email}
                </TableCell>
                <TableCell component="th" scope="row">
                  {entityData.status}
                </TableCell>
                <TableCell component="th" scope="row">
                  {activityTrackingItem?.entity?.action}
                </TableCell>
              </TableRow>
            )
          })}
          {!data.length && (
            <TableRow>
              <TableCell colSpan={7} align="center">
                No data
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        {data.length > 0 && (
          <TableFooterWithPagination
            count={meta.total}
            rowsPerPage={meta.per_page}
            page={meta.current_page - 1}
            onPageChange={(_ev, newPage) => handleChangePage(newPage + 1)}
            onRowsPerPageChange={(e) => handleChangePerPage(parseInt(e.target.value))}
          />
        )}
      </Table>
    </Box>
  )
}

export default ActivityTrackingMembership;