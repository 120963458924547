import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  Box
} from "@mui/material";
import useApplication from "hooks/useApplication";
import useInvoices from "hooks/useInvoices";
import PaperBlock from "components/PaperBlock";
import SearchInput from "components/SearchInput";
import TableFooterWithPagination from "components/TableFooterWithPagination";
import InvoiceRow from "./components/InvoiceRow";
import Filter from "../components/Filter";

export default function Invoices() {
  const { setPage } = useApplication();
  const {
    invoicesReducer: { filter, search, results, loading, meta },
    searchInvoices,
    changePage,
    changePerPage,
    getInvoices,
  } = useInvoices();

  useEffect(() => {
    setPage({
      title: "Invoices",
      path: "/app/invoices",
    });
    searchInvoices({ search, page: meta.current_page, per_page: meta.per_page });
    // eslint-disable-next-line
  }, []);

  const handleSearch = (value) => {
    searchInvoices({ search: value, page: meta.current_page, per_page: meta.per_page });
  };

  const handleChangePage = (_event, newPage) => {
    changePage(newPage);
    getInvoices({ search, page: newPage + 1, per_page: meta.per_page });
  };

  const handleChangeRowsPerPage = (event) => {
    changePerPage(event);
    getInvoices({
      search,
      page: meta.current_page,
      per_page: parseInt(event.target.value)
    });
  };

  const handleFilter = (values) => {
    getInvoices({
      filter: values,
      search,
      page: meta.current_page,
      per_page: meta.per_page
    });
  };

  return (
    <PaperBlock
      title="Invoices"
      loading={loading}
      actions={
        <>
          <Filter values={filter} onChange={handleFilter} />
          <SearchInput
            defaultValue={search}
            onChange={handleSearch}
            placeholder="By ID, First Name, Last Name, Phone or Email"
          />
        </>
      }
    >
      <Box sx={{overflow: "auto"}}>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>#ID</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell sx={{whiteSpace:"nowrap"}}>Payment Type</TableCell>
            <TableCell>Date</TableCell>
            <TableCell sx={{whiteSpace:"nowrap"}}>QBE Sent</TableCell>
            <TableCell>Status</TableCell>
            <TableCell align="right" sx={{paddingRight:15}}>Action</TableCell>
            {/* <TableCell align="right"></TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((invoice) => (
            <InvoiceRow data={invoice} key={invoice.id} />
          ))}
          {!results.length && (
            <TableRow>
              <TableCell colSpan={7} align="center">
                No data
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        {results.length > 0 && (
          <TableFooterWithPagination
            count={meta.total}
            rowsPerPage={meta.per_page}
            page={meta.current_page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Table>
      </Box>
    </PaperBlock>
  );
}
