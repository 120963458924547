import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Typography
} from '@mui/material';
import LoadingButton from "@mui/lab/LoadingButton";
import dayjs from "dayjs";
import useQuickBooks from 'hooks/useQuickBooks';


const QuickBooksConnect = () => {
  const [connectedAt, setConnectedAt] = useState(null);
  const [externalPopup, setExternalPopup] = useState(null);
  const [loadingAuthorization, setLoadingAuthorization] = useState(false);

  const left = (window.screen.width / 2) - 300;
  const top = (window.screen.height / 2) - 300;
  const {
    getQuickBooksAuthorizationCodeURL,
    getQuickBooksRefreshTokenStatus,
    quickBooksConnectCallback
  } = useQuickBooks();

  useEffect(() => {
    getQuickBooksRefreshTokenStatus().then((resp) => {
      setConnectedAt(resp.data.result);
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (!externalPopup) {
      return;
    }

    const timer = setInterval(() => {
      if (!externalPopup) {
        timer && clearInterval(timer);
        return;
      }
      let currentUrl = null;
      let queryString = null;
      try {
        currentUrl = externalPopup.location.href;
        queryString = externalPopup.location.search;
      } catch (err) {
        return;
      }
      if (!currentUrl) {
        return;
      }
      const searchParams = new URL(currentUrl).searchParams;
      const code = searchParams.get('code');

      if (code) {
        externalPopup.close();
        quickBooksConnectCallback({
          queryString,
        }).then(() => {
          getQuickBooksRefreshTokenStatus().then((resp) => {
            setConnectedAt(resp.data.result);
          });
        }).finally(() => {
          setExternalPopup(null);
          timer && clearInterval(timer);
        });
      }
    }, 1000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalPopup]);


  const handleGetQuickBooksAuthorizationCodeURL = () => {
    setLoadingAuthorization(true);
    getQuickBooksAuthorizationCodeURL().then((resp) => {
      setExternalPopup(window.open(resp.data.result, 'popup', 'status=no, menubar=no, scrollbars=no, width=600, height=600, top=' + top + ', left=' + left));
    }).finally(() => {
      setLoadingAuthorization(false);
    });
  }

  const connectedDaysLeft = () => {
    // days till conected at expires
    return connectedAt ? (100 - dayjs().diff(dayjs(connectedAt), 'day')) : 0;
  }
  const daysLeft = connectedDaysLeft();
  return (
    <Box sx={{ width: { sx: "100%", md: "40%" } }}>
      <Alert severity="info">In order to run QuickBooks fidaybilling jobs, the platform should have an active connection with QuickBooks.</Alert>
      <Typography variant="h6" fontWeight="bold" color="primary" sx={{ mb: 2 }}>
        QuickBooks Connect
      </Typography>
      <LoadingButton
        loading={loadingAuthorization}
        variant="contained"
        onClick={handleGetQuickBooksAuthorizationCodeURL}
      >
        Connect to QuickBooks
      </LoadingButton>
      <Typography mt={2}>Connection:&nbsp;
        {daysLeft > 0
          ? <Box component="span" color={(daysLeft > 20 ? "green" : (daysLeft > 3 ? "orange" : "red" ))}>Expires in {daysLeft} days</Box>
          : <Box component="span" color="red">{connectedAt === null ? 'Not Connected' : 'Expired'}</Box>}
      </Typography>
    </Box>
  )
}

QuickBooksConnect.defaultProps = {
  readOnly: false
};

export default QuickBooksConnect;
