import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box
} from "@mui/material";
import Swal from "sweetalert2";
import AddIcon from "@mui/icons-material/Add";
import useApplication from "hooks/useApplication";
import useContractors from "hooks/useContractors";
import SearchInput from "components/SearchInput";
import PaperBlock from "components/PaperBlock";
import ContractorRow from "./components/ContractorRow";
import { deleteContractor } from "api/contractors";
import TableFooterWithPagination from "components/TableFooterWithPagination";
import ContractorBlocks from "components/ContractorBlocks";
import ".././../../../utils/sweetalert.css";
import useAccessControl from "hooks/useAccessControl";

export default function Contractors() {
  const { setPage } = useApplication();
  const { 
    canCreateContractor,
    canUpdateContractor,
    canReadContractor,
    canDeleteContractor } =  useAccessControl()

  const {
    search,
    contractorsReducer: { results, query, loading, meta },
    getContractors,
    changePage,
    changePerPage,
  } = useContractors();
  const navigate = useNavigate();

  useEffect(() => {
    setPage({
      title: "Contractors",
      path: "/app/contractors",
    });
    search(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (value) => {
    search(value);
  };


  const deleteContractorHandler = async (contractorId) => {
    try {
      Swal.fire({
        title: "Delete contractor?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        customClass: {
          title: "swal-text",
          popup:"swal-text"
          
        },
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteContractor(contractorId)
            .then(() => {
              search(query);
            })
            .catch((e) => {});
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleChangePage = (_event, newPage) => {
    changePage(newPage);
    getContractors({
      search: query,
      page: newPage + 1,
      per_page: meta.per_page,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    changePerPage(event);
    getContractors({
      search: query,
      page: meta.current_page,
      per_page: parseInt(event.target.value),
    });
  };

  return (
    <ContractorBlocks
      title="Contractors"
      loading={loading}
      actions={
        <Box display="flex" justifyContent="space-between" alignItems="center" className="memberSearchBox">
          <SearchInput
          
            defaultValue={query}
            onChange={handleSearch}
            placeholder="By ID, First Name, Last Name, Phone or Email"
          />
          { canCreateContractor() && 
              <Button
                sx={{ ml: 2, whiteSpace: "nowrap",minWidth: 'auto' }}
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate("/app/contractors/create")}
              >
                Add contractor
              </Button>
          }
        </Box>
      }
    >
      <Box sx={{overflow:"auto"}}>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>#ID</TableCell>
            <TableCell>Contractor name</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Email</TableCell>
            <TableCell >Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((contractor) => (
            <ContractorRow
              key={contractor.id}
              data={contractor}
              deleteContractor={deleteContractorHandler}
            />
          ))}
          {!results.length && (
            <TableRow>
              <TableCell colSpan={5} align="center">
                No data
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        {results.length > 0 && (
          <TableFooterWithPagination
            count={meta.total}
            rowsPerPage={meta.per_page}
            page={meta.current_page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Table>
      </Box>
    </ContractorBlocks>
  );
}
