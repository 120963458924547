import axios from "utils/axios";
import { LOGIN_ROUTE, PERSONAL_DATA_ROUTE, VALIDATE_MFA_ROUTE, LOGOUT_ROUTE, FORGOT_PASSWORD_ROUTE, RESET_PASSWORD_ROUTE,RESEND_MFA_OTP } from "./routes";

const loginRequest = (data) => axios.post(LOGIN_ROUTE, data);

const forgotPasswordRequest = (data) => axios.post(FORGOT_PASSWORD_ROUTE, data);

const resetPassword = (data) => axios.post(RESET_PASSWORD_ROUTE, data);

const logoutRequest = () => axios.post(LOGOUT_ROUTE, {});

const personalDataRequest = () => axios.get(PERSONAL_DATA_ROUTE);

const validateMfaRequest = (data) => axios.post(VALIDATE_MFA_ROUTE, data);
const resendOtpForMfa = (data) => axios.post(RESEND_MFA_OTP, data);

export { loginRequest, personalDataRequest, validateMfaRequest, logoutRequest, forgotPasswordRequest, resetPassword,resendOtpForMfa };
