import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import brand from "theme/brand";

const useStyles = makeStyles((theme) => ({
  link: {
    color: "blue",
  },
  logo: {
    maxWidth: 150,
  },
}));

const FormCompleted = ({ email, membership_id }) => {
  const [counter, setCounter] = useState(5);
  const classes = useStyles();

  useEffect(() => {
    let i = 0;
    const interval = setInterval(() => {
      i++;
      setCounter(5 - i);
      if (i >= 5) {
        window.location.replace("/");
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Dialog open={true}>
      <DialogContent>
        <Box p={2} textAlign="center">
          <img className={classes.logo} src={brand.logo} alt="Hoc Logo" />
        </Box>
        <Typography pb={2} variant="h6" textAlign="center">
          Almost Done!
        </Typography>
        <Typography variant="subtitle2" textAlign="center">
          Your membership application (no.{" "}
          <span className={classes.link}>{membership_id}</span>) has been
          received.
        </Typography>
        <Typography variant="subtitle2" textAlign="center">
          Please check your inbox for{" "}
          <span className={classes.link}>{email}</span> for instructions on how
          to complete your registration.
        </Typography>
        <Typography textAlign="center" variant="h6">
          {counter}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default FormCompleted;
