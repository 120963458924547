import { useState } from "react";
import { Box, Typography, Link, List, ListItem } from "@mui/material";
import MapDialog from "components/MapDialog";

export default function JobLocationComponent({ location }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Typography variant="h6">Job Location</Typography>
      <Box mb={2} mt={1}>
        <Typography variant="body2" sx={{ mb: 2 }}>
          {location.street_line} <br /> {location.state_line}
        </Typography>
        <List dense disablePadding>
          <ListItem disableGutters sx={{ mb: 1 }}>
            <Typography
              variant="body2"
              component="span"
              fontWeight="bold"
              sx={{ mr: 1 }}
            >
              Property Type:
            </Typography>
            <Typography variant="body2" component="span" fontWeight="normal">
              {location.type}
            </Typography>
          </ListItem>
        </List>
      </Box>
      <Link href="#" underline="none" onClick={handleClickOpen}>
        View map
      </Link>
      <MapDialog open={open} handleClose={handleClose} location={location} />
    </>
  );
}
