import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Paper,
  Grid,
  Button,
  CircularProgress,
  Divider,
  Alert,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate, useParams } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import useApplication from "hooks/useApplication";
import useBatch from "hooks/useBatch";
import JobPickerTable from "components/JobPickerTable";
import {
  ContractorInformation,
  MemberInformation,
  Notes,
  Job,
} from "./components";
import Invoice from '../../Invoices/components/Invoice';

export default function Process({ job }) {
  const { setPage } = useApplication();
  const { batch_id } = useParams();
  const {
    processBatch,
    approveBatch,
    rejectBatch
  } = useBatch();
  const [aproveBatchLoading, setAproveBatchLoading] = useState(false);
  const [processingErrors] = useState([]);
  const navigate = useNavigate();
  const [state, _setState] = useState({
    loading: true,
    batch: {},
    invoices: [],
    currentIndex: 0,
    invoice: {},
    errors: {}
  });
  const [errors, setErrors] = useState({});

  const setState = (newState) => _setState({ ...state, ...newState });

  const setInvoice = (newState) =>
    _setState({
      ...state,
      invoice: { ...state.invoice, ...newState },
    });

  const nextIndex = () => {
    if (state.currentIndex < state.invoices.length - 1) {
      setState({
        currentIndex: state.currentIndex + 1,
        invoice: state.invoices[state.currentIndex + 1],
      });
    } else {
      setState({ currentIndex: 0 });
    }
  };

  const prevIndex = () => {
    if (state.currentIndex > 0) {
      setState({
        currentIndex: state.currentIndex - 1,
        invoice: state.invoices[state.currentIndex - 1],
      });
    } else {
      setState({ currentIndex: state.invoices.length - 1 });
    }
  };

  useEffect(() => {
    processBatch(batch_id)
      .then((res) => {
        const invoices = [];
        if (res.data.invoices.length > 0) {
          res.data.invoices.forEach((invoice) => {
            invoices.push({
              // ...invoice,
              id: invoice.id,
              discountType: invoice.discount_type,
              discountValue: invoice.discount_amount,
              attachpath: invoice.attachpath,
              contractor_id: invoice.contractor_id,
              invoice_term_id: invoice.invoice_term_id,
              batch_id: invoice.id,
              job: invoice.job,
              inv_total_material: invoice.inv_total_material,
              created_by: invoice.created_by,
              description: invoice.description,
              membership_id: invoice.membership_id,
              recommended_contractor_id: invoice.recommended_contractor_id,
              external_invoice_number: invoice.external_invoice_number,
              con_addy1: invoice.csv_con_addy1,
              con_addy2: invoice.csv_con_addy2,
              con_biz: invoice.csv_con_biz,
              con_csz: invoice.csv_con_csz,
              con_lic: invoice.csv_con_lic,
              contractor: invoice.csv_contractor,
              contractor_alpha: invoice.csv_contractor_alpha,
              created_at: invoice.csv_created_at,
              invoice_date: invoice.csv_inv_date,
              inv_net_sale: invoice.csv_inv_net_sale,
              inv_total_labor: invoice.csv_inv_total_labor,
              job_id: invoice.csv_job_id,
              mem_city: invoice.csv_mem_city,
              mem_name: invoice.csv_mem_name,
              mem_street: invoice.csv_mem_street,
              modified_at: invoice.csv_modified_at,
              rate: invoice.csv_rate,
              remarks: invoice.csv_remarks,
              tax: invoice.csv_tax,
              time_arrive: invoice.csv_time_arrive,
              time_total: invoice.csv_time_total,
              total: invoice.csv_total,
              items: invoice.items,
              ref_number: invoice.ref_number,
              item: invoice.item,
            });
          });
        } else {
          navigate("/app/invoices/batches");
        }
        setState({
          invoice: invoices[0],
          currentIndex: 0,
          invoices: invoices,
          loading: false,
        });
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batch_id]);

  useEffect(() => {
    setPage({
      title: "Invoice for Job No. 1",
      path: "/app/invoices",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchJob = (jobId, job = null) => {
    setInvoice({
      job_id: jobId,
      membership_id: job?.membership_id,
      contractor_id: job?.contractor_id
    });
  };

  if (state.loading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress size={16} />
      </Box>
    );
  }

  const handleApproveBatch = () => {
    setAproveBatchLoading(true);
    approveBatch(state.invoice, batch_id).then((resp) => {
      if (resp.data.result.id) {
        navigate(`/app/invoices/${resp.data.result.id}`);
      }
    }).finally(() => {
      setAproveBatchLoading(false);
    }).catch((err) => {
      if (err?.response?.data?.errors) {
        Object.keys(err.response.data.errors).forEach((field) => {
          if (err.response.data.errors[field][0] !== undefined)
          setErrors({ ...errors, [field]: err.response.data.errors[field][0]});
        })
      }
    });
  };

  const handleRejectBatch = () => {
    if (state.invoice.id) {
      rejectBatch(state.invoice.id).then((resp) => {
        if(resp.data.result.id) {
          const newInvoices = state.invoices.filter(invoice => invoice.id !== resp.data.result.id);
          if(newInvoices.length > 0) {
            let newIndex = state.currentIndex;
            if(newInvoices.length - 1 < state.currentIndex) {
              newIndex = newInvoices.length - 1;
            }
            setState({
              invoices: newInvoices,
              invoice: newInvoices[newIndex],
              currentIndex: newIndex
            });
          } else {
            navigate("/app/invoices/batches");
          }
        }
      })
    }
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={2}
      >
        <Typography variant="h5">
          {state.invoice?.job_id
            ? `Invoice for Job No. ${state.invoice.job_id}`
            : "Invoice"}
        </Typography>
        <Box display="flex" alignItems="center">
          <Typography>{state.invoices.length} Invoices remaining</Typography>
          <Box ml={1}>
            <IconButton disabled={state.currentIndex === 0} onClick={() => prevIndex()}>
              <ChevronLeftIcon />
            </IconButton>
              <Box display="inline-flex" m={1}>
                {state.currentIndex + 1}
              </Box>
            <IconButton disabled={state.invoices.length -1 <= state.currentIndex} onClick={() => nextIndex()}>
              <ChevronRightIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Paper sx={{ p: 2, mt: 2 }}>
        <Typography sx={{ mb: 2 }} variant="h6">
          Errors
        </Typography>
        <Box mb={2}>
          {processingErrors && processingErrors.map((processionError) => {
            if(['contractor_id_not_found', 'contractor_id_not_match'].includes(processionError.code)) {
              return (
                <Alert severity="warning">
                  We could not detect a contractor by the email address of the person
                  who sent this email. Please choose a contractor below:
                </Alert>
              );
            } else if(['job_id_not_found', 'job_id_not_match'].includes(processionError.code) ) {
              return (
                <Alert severity="warning">
                  We could not detect a job by the email address of the person
                  who sent this email. Please choose a job below:
                </Alert>
              );
            }
            return '';
          })}
          <JobPickerTable
            handleChange={handleSearchJob}
            value={state.invoice.job_id}
            error={Boolean(errors.job_id) || Boolean(errors.contractor_id)}
            helperText={errors.job_id || errors.contractor_id}
          />
        </Box>
        <Divider sx={{ my: 2 }} />
        <Grid container spacing={4}>
          <Grid item sx={12} sm={4} md={3}>
            <Box mb={4}>
              <Job job={job} />
            </Box>
            <Box mb={4}>
              <ContractorInformation />
            </Box>
            <Box mb={4}>
              <MemberInformation />
            </Box>
            <Box>
              <Notes />
            </Box>
          </Grid>
          <Grid item sx={12} sm={8} md={9}>
            <Invoice
              invoice={state.invoice}
              onChange={(values) => setInvoice(values)}
              withActions
              onChangeTotal={(total) => setInvoice({ total })}
              errors={errors}
            />
          </Grid>
        </Grid>
        <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
          <Box display="flex" alignItems="center" mb={1}>
            <LoadingButton loading={aproveBatchLoading} variant="contained" color="success" sx={{ mr: 2 }} onClick={() => handleApproveBatch()}>
              Approve
            </LoadingButton>
            <Button variant="contained" color="secondary" onClick={handleRejectBatch}>
              Reject
            </Button>
          </Box>
          <Button onClick={() => nextIndex()} disabled={state.invoices.length -1 <= state.currentIndex}>Skip Invoice</Button>
        </Box>
      </Paper>
    </>
  );
}
