import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import cookie from "js-cookie";
import {
  AuthTranslations,
  JobRequestTranslations,
  MembershipTranslations,
  DashboardTranslations,
  TaskQueueTranslations,
  MemberDirectoryTranslations,
  ContractorDirectoryTranslations,
  JobsDirectoryTranslations,
  VendorDirectoryTranslations,
} from "./languages/en";

import {
  AuthTranslations as EsAuthTranslations,
  MembershipTranslations as EsMembershipTranslations,
  JobRequestTranslations as EsJobRequestTranslations,
  DashboardTranslations as EsDashboardTranslations,
  TaskQueueTranslations as EsTaskQueueTranslations,
  MemberDirectoryTranslations as EsMemberDirectoryTranslations,
  ContractorDirectoryTranslations as EsContractorDirectoryTranslations,
  JobsDirectoryTranslations as EsJobsDirectoryTranslations,
  VendorDirectoryTranslations as EsVendorDirectoryTranslations,
} from "./languages/es";

const resources = {
  en: {
    auth: AuthTranslations,
    membershipApplication: MembershipTranslations,
    jobRequest: JobRequestTranslations,
    dashboard: DashboardTranslations,
    taskQueue: TaskQueueTranslations,
    memberDirectory: MemberDirectoryTranslations,
    contractorDirectory: ContractorDirectoryTranslations,
    jobsDirectory: JobsDirectoryTranslations,
    vendorDirectory: VendorDirectoryTranslations,
  },
  es: {
    auth: EsAuthTranslations,
    membershipApplication: EsMembershipTranslations,
    jobRequest: EsJobRequestTranslations,
    dashboard: EsDashboardTranslations,
    taskQueue: EsTaskQueueTranslations,
    memberDirectory: EsMemberDirectoryTranslations,
    contractorDirectory: EsContractorDirectoryTranslations,
    jobsDirectory: EsJobsDirectoryTranslations,
    vendorDirectory: EsVendorDirectoryTranslations,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: cookie.get("lang") || "en",
    defaultNS: "common",
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
      transKeepBasicHtmlNodesFor: ["br", "b", "a", "strong"], // don't convert to <1></1> if simple react element
      wait: true,
    },
  });

export default i18n;
