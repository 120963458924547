import React, { useEffect } from "react";
import { Box, Button, Typography, MenuItem, CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import FormField from "components/FormField";
import useUtils from "hooks/useUtils";
import useUser from "hooks/useUser";
import useAccessControl from "hooks/useAccessControl";
import useAuth from "hooks/useAuth";
import ServiceLocationPicker from "components/ServiceLocationPicker";
import useStyles from "./styles";
import ".././../utils/sweetalert.css"

export default function AccountDetailsComponent({ readOnly, user }) {
  const classes = useStyles();
  const { utils } = useUtils();
  const { updateUser } = useUser();
  const { user: authUser } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [authenticateUser, setAuthenticateUser] = React.useState(null);
  const { isAdmin, canUpdateUser, canManageServiceLocationUser } = useAccessControl();

  const AccountDetailsValidationSchema = Yup.object().shape({
    role: Yup.string().required("Required"),
    first_name: Yup.string().required("Required"),
    service_location_id: Yup.number().nullable(),
    last_name: Yup.string().nullable(),
    email: Yup.string().email("Invalid email").required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      role: "EMPLOYEE",
      first_name: "",
      last_name: "",
      email: "",
      service_location_id: null,
      service_location: null,
    },
    validationSchema: AccountDetailsValidationSchema,
    onSubmit: (values) => {
      setLoading(true);
      updateUser(values, user?.uuid)
        .then(res => {
          setLoading(false);
          Swal.fire({
            icon: "success",
            text: 'User record updated',
            toast: true,
            position: 'top-right',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((error) => {
          console.log(error?.response,'error');
          Swal.fire({
            icon: "error",
            text: error?.response?.data?.message || 'Something went wrong while updating user! Try again',
            toast: true,
            position: 'top-right',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  });

  useEffect(() => {
    if (user) {
      const { first_name, last_name, email, role, service_location: { id, name } } = user;
      formik.setValues({
        first_name,
        last_name,
        role,
        email,
        service_location_id: id,
        service_location: {
          id: id,
          label: name
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ width: { sx: "100%", md: "40%" } }}>
      <Typography variant="h6" fontWeight="bold" color="primary" sx={{ mb: 2 }}>
        Account details
      </Typography>
      <form onSubmit={formik.handleSubmit} className={classes.formContainer}>
        <FormField
          name="role"
          label="Role"
          select
          disabled={authUser.data.email != user.email ? !isAdmin : 'disabled'}
          value={formik.values.role}
          onChange={formik.handleChange}
          error={formik.touched.role && Boolean(formik.errors.role)}
        >
          {utils.roles.map((role) => (
            <MenuItem key={`role-${role.id}`} value={role.id}>
              {role.name}
            </MenuItem>
          ))}
        </FormField>
        <FormField
          type="text"
          name="first_name"
          disabled={readOnly}
          label="First Name"
          value={formik.values?.first_name}
          onChange={(e) => formik.setFieldValue("first_name", e.target.value)}
        />
        <FormField
          type="text"
          name="last_name"
          disabled={readOnly}
          label="Last Name"
          value={formik.values?.last_name}
          onChange={(e) => formik.setFieldValue("last_name", e.target.value)}
        />
        {canManageServiceLocationUser() && (
          <ServiceLocationPicker
            label="Service Location"
            disabled={readOnly}
            InputLabelProps={{ shrink: true }}
            value={formik.values?.service_location}
            name="service_location_id"
            onChange={(val) => {
              formik.setFieldValue('service_location', val);
              formik.setFieldValue('service_location_id', val.id);
            }}
          />
        )}
        <FormField
          type="text"
          name="email"
          label="Email"
          disabled={readOnly}
          value={formik.values?.email}
          onChange={(e) => formik.setFieldValue("email", e.target.value)}
        />
        {(canUpdateUser() || user?.uuid === authUser.data.uuid) && (
          <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
            Update account details
            {loading && (
              <Box pl={2}>
                <CircularProgress color="secondary" size={16} />{" "}
              </Box>
            )}
          </Button>
        )}
      </form>
    </Box>
  );
}

AccountDetailsComponent.defaultProps = {
  readOnly: false
};
