import ContentBlock from "components/ContentBlock";
import React from "react";

export default function Layout({ children, title, desc, icon }) {
  return (
    <ContentBlock title={title} desc={desc} icon={icon}>
      {children}
    </ContentBlock>
  );
}
