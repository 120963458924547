import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme) => ({
  cardProfile: {
    backgroundColor: "#EEEDE9",
  },
  changePass: {
    color: `${theme.palette.text.secondary} !important`,
    fontWeight: "600 !important",
  },
  formContainer: {
		'& .MuiFormControl-root': {
			marginBottom: theme.spacing(2)
		}
	}
}));

export default styles;
