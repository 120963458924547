const darkPalette = {
  hoc: {
    palette: {
      primary: {
        light: "#ECEFF1",
        main: "#90A4AE",
        dark: "#455A64",
        contrastText: "#ffffff",
        brand: "#930723",
      },
      secondary: {
        light: "#E0E0E0",
        main: "#757575",
        dark: "#424242",
        contrastText: "#ffffff",
      },
    },
  },
};

export default darkPalette;
