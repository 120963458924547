import React from "react";
import {
  Box,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  MenuItem,
} from "@mui/material";
import useStyles from "./styles";
import { calculateSum } from "utils/functions";
import { DISCOUNT_TYPE_FIXED_AMOUNT, DISCOUNT_TYPE_PERCENTAGE } from "utils/constants";

export default function InvoiceAmountComponent({
  invoice,
  onChange,
  withActions
}) {
  const classes = useStyles();
  const total = (calculateSum(invoice.items, 'amount') / 100).toFixed(2) || 0;
  return (
    <Box className={classes.root}>
      <TableContainer>
        <Table size="small" aria-label="simple table">
          <TableBody>
            <TableRow key="key" sx={{ "& td": { border: 0 } }}>
              <TableCell width="60%"></TableCell>
              <TableCell align="right" sx={{ fontWeight: "bold" }}>
                Subtotal
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: "bold" }}>
                ${total}
              </TableCell>
            </TableRow>
            <TableRow key="key" sx={{ "& td": { border: 0 } }}>
              <TableCell width="60%">
                {/* <TextField
                  size="small"
                  select
                  fullWidth
                  value={invoice?.discountType}
                  onChange={(e) => onChange({ discountType: e.target.value, discountValue: 0 })}
                  disabled={!withActions}
                >
                  <MenuItem key={1} value={DISCOUNT_TYPE_PERCENTAGE}>
                    Discount percentage
                  </MenuItem>
                  <MenuItem key={2} value={DISCOUNT_TYPE_FIXED_AMOUNT}>
                    Fixed amount
                  </MenuItem>
                </TextField> */}
              </TableCell>
              <TableCell align="right">
                {invoice?.discountType === DISCOUNT_TYPE_PERCENTAGE ? (
                  <TextField
                    variant="outlined"
                    size="small"
                    type="number"
                    InputProps={{
                      inputProps: {
                        min: 0, max: 100
                      },
                      endAdornment: "%"
                    }}
                    value={invoice?.discountValue}
                    onChange={(e) => {
                      let discountValue = parseInt(e.target.value, 10) > 100 ? 100 : parseInt(e.target.value, 10)
                      if(discountValue < 0) {
                        discountValue = 0;
                      }
                      onChange({ discountValue: discountValue || 0 })
                    }}
                    disabled={!withActions}
                  />
                ) : ''}
                {invoice?.discountType === DISCOUNT_TYPE_FIXED_AMOUNT ? (
                  <TextField
                    variant="outlined"
                    size="small"
                    type="number"
                    InputProps={{
                      inputProps: {
                        min: 0, max: total
                      },
                    }}
                    placeholder="amount"
                    onChange={(e) => {
                      let discountValue = (parseInt(e.target.value, 10) / 100) > total ? total : parseInt(e.target.value, 10);
                      if(discountValue < 0) {
                        discountValue = 0;
                      }
                      onChange({ discountValue: discountValue || 0 });

                    }}
                    disabled={!withActions}
                  />
                ) : ''}
              </TableCell>
              {/* <TableCell align="right" sx={{ fontWeight: "bold" }}>
                {invoice?.discountValue ? '-' : ''}
                ${invoice?.discountType === DISCOUNT_TYPE_PERCENTAGE && ((total * (invoice?.discountValue / 100)).toFixed(2) || 0)}
                {invoice?.discountType === DISCOUNT_TYPE_FIXED_AMOUNT && (invoice?.discountValue / 100 || 0)}
                {![DISCOUNT_TYPE_PERCENTAGE, DISCOUNT_TYPE_FIXED_AMOUNT].includes(invoice?.discountType) && 0}
              </TableCell> */}
            </TableRow>
            <TableRow key="key" sx={{ "& td": { border: 0 } }}>
              <TableCell width="60%">
                {/* <TextField
                  size="small"
                  fullWidth
                  label="Factor"
                  disabled={!withActions}
                  // value={invoice.address}
                  onChange={(event) => {}}
                /> */}
              </TableCell>
              <TableCell align="right">
                {/* <TextField
                  variant="outlined"
                  size="small"
                  disabled={!withActions}
                /> */}
              </TableCell>
              {/* <TableCell align="right" sx={{ fontWeight: "bold" }}>
                62.50
              </TableCell> */}
            </TableRow>
            <TableRow key="key" sx={{ "& td": { border: 0 } }}>
              <TableCell width="60%"></TableCell>
              <TableCell align="right" sx={{ fontWeight: "bold" }}>
                Total
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: "bold" }}>
              ${total}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={2}></Box>
    </Box>
  );
}
