import { makeStyles } from "@mui/styles";
import { darken } from "@mui/system";

export const gradientBgLight = (theme) =>
  `linear-gradient(-45deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 33%, ${theme.palette.secondary.main} 100%);`;
export const gradientBgDark = (theme) =>
  `linear-gradient(-45deg, ${darken(
    theme.palette.primary.main,
    0.4
  )} 0%, ${darken(theme.palette.primary.main, 0.4)} 33%, ${darken(
    theme.palette.secondary.main,
    0.4
  )} 100%);`;

const appFrame = {
  display: "flex",
  width: "100%",
  minHeight: "100%",
  zIndex: 1,
};

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: 0,
    zIndex: 1,
    overflow: "auto",
  },
  appFrameAuth: {
    color: theme.palette.text.primary,
    ...appFrame,
  },
  gradientBg: {
    background: "url('/images/bck.jpg') no-repeat center center fixed",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
  },
  outerContent: {
    width: "100%",
    backgroundSize: "cover",
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    background: "url('/images/layerfull.svg') no-repeat center center fixed",
  },
}));
