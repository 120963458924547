import axios from "utils/axios";

const GET_INVOICES_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/invoices`;

export const getInvoicesRequest = (params) =>
  axios.post(`${GET_INVOICES_ROUTE}/index`, params);

export const viewInvoiceRequest = (id) =>
  axios.get(`${GET_INVOICES_ROUTE}/${id}`);

export const emailInvoiceRequest = (id) =>
  axios.get(`${GET_INVOICES_ROUTE}/send-email/${id}`);

export const downloadInvoiceRequest = (id) =>
  axios.get(`${GET_INVOICES_ROUTE}/get-pdf/${id}`, {
    responseType: 'blob',
  });

export const createInvoiceRequest = (params) =>
  axios.post(`${GET_INVOICES_ROUTE}`, params);
