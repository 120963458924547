import { useEffect, useState } from "react";
import { Box, Tab } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import useApplication from "hooks/useApplication";
import ContentBlock from "components/ContentBlock";
import Notes from "components/Notes";
import { getContractor, updateContractor } from "api/contractors";
import ContractorsForm from "../components/Form";
import ".././../../../utils/sweetalert.css"

export default function Create() {
  const [tabValue, setTabValue] = useState("0");
  const { setPage } = useApplication();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [contractor, setContractor] = useState(null);

  useEffect(() => {
    setPage({
      title: "Contractors",
      path: "/app/contractors",
    });
    setLoading(true);
    getContractor(id)
      .then((response) => {
        if (response.data) {
          setContractor(response.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {

    if(location?.state?.notes) {
      setTabValue("1");
    }
  }, [location]);

  const onSubmit = async (values) => {
    try {
      if (typeof values.city_id === "object") {
        values.city_id = values.city_id?.id;
      }
      const response = await updateContractor(id, values);
      if (response.data) {
        Swal.fire({
          icon: "success",
          text: "Contractor updated",
          title: "Success!",
          customClass: {
            title: "swal-text",
            popup:"swal-text"
            
          },
          timer: 4000,
          timerProgressBar: true,
        }).then((result) => {
          navigate("/app/contractors");
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: "Try again later",
        title: "Something went wrong!",
        customClass: {
          title: "swal-text",
          popup:"swal-text"
          
        },
        timer: 4000,
        timerProgressBar: true,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/app/contractors");
        }
      });
    }
  };

  return (
    <ContentBlock title="Update Contractor" icon="ion-ios-clipboard-outline">
      {loading && <div>Loading...</div>}
      {!loading && contractor && (
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={(_event, newValue) => setTabValue(newValue)}>
              <Tab
                label="Contractor information"
                value="0"
              />
              <Tab label="Notes" value="1" />
            </TabList>
          </Box>
          <TabPanel value="0">
            <ContractorsForm withHeadingLine={false} onSubmitHandler={onSubmit} form={contractor} />
          </TabPanel>
          <TabPanel value="1">
            <Notes
              notableType="Contractors"
              notableId={contractor.id}
            />
          </TabPanel>
        </TabContext>
      )}

    </ContentBlock>
  );
}
