import { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  FormControlLabel,
  Checkbox,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Swal from "sweetalert2";
import FormField from "components/FormField";
import useServiceLocations from "hooks/useServiceLocations";
import CityPicker from "components/CityPicker";
import StatePicker from "components/StatePicker";
import "../../../../../utils/sweetalert.css"
import RefundC from "components/RefundC";
import { DeletedMemberRequestv } from "api/membership";
import useMembership from "hooks/useMembership";
import Toast from "components/Toast";



const MemberDialog = ({ open, serviceLocation,data, onClose }) => {
  const navigate=useNavigate();
  // const  {
  //   search,
  //   serviceLocationsReducer: { query},
  // } = useServiceLocations();
  const {
    search,
    membershipReducer: { query },
  } = useMembership();
  const RolesManagementValidationSchema = Yup.object().shape({
    id: Yup.number().nullable(),
    // name: Yup.string().max(255).required("Required"),
    // state_code: Yup.string().required("Required").nullable(),
  });

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };
  const formik = useFormik({
    initialValues: {
      id: data?.id,
      refund_amount: "",
      Partial_amount:""

    },
    validationSchema: RolesManagementValidationSchema,
    onSubmit: async (values) => {
      if(values) {
        await DeletedMemberRequestv({
          id: values.id,
          refund_amount:values.refund_amount,
          Partial_amount:values.Partial_amount
    
        }).then((resp) => {
          Swal.fire({
            toast: true,
            icon: "success",
            text: "Member Deleted Successfully",
            title: "Success!",
            timer: 4000,
            customClass: {
              title: "swal-text",
              popup:"swal-text"
              
            },
            position: "top-end",
            timerProgressBar: true,
            showConfirmButton: false
          });
          search(query);
          handleClose();
          navigate('/app/members')

        }).catch((error) => {
          Toast.fire({
            icon: "error",
            title: "Error",
            timer:3000,
            position:'top-right',
            text: error?.response?.data?.message||'Something went wrong while updating user! Try again',
            customClass: {
              title: "swal-text",
              popup: "swal-text"
            }
          });
        });
      }
    }
  });

  useEffect(() => {

      formik.setValues({
        ...formik.values,
        id:data?.id
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // id , refund_amount, Partial_amount

  const setDialogTitle = () => {
    //if (serviceLocation) return `Manage service location`;
    return "Delete Member";
  };

  if (!open) return null;
  return (
    <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
      <DialogTitle>{setDialogTitle()}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={6}>
            <RefundC
              required
              name="refund_amount"
              label="Refund Amount"
              value={formik.values.refund_amount}
              onChange={(e) => formik.setFieldValue('refund_amount', e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              error={Boolean(formik.errors.refund_amount)}
              helperText={formik.errors.refund_amount}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {formik.values.refund_amount === "CUSTOM" && (
              <FormField
                label="Partial Amount"
                value={formik.values.name}
                onChange={formik.handleChange}
                name="Partial_amount"
                required
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              
            )}
            {formik.values.refund_amount === "CUSTOM" && (
             <span style={{fontSize:'12px'}}>Amount should be equals or less than <strong>$ {data?.amount}</strong></span>
            )}
          </Grid>
          
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          loading={formik.isSubmitting}
          loadingPosition="center"
          variant="contained"
          onClick={formik.submitForm}
        >
          {serviceLocation?.id ? "Update" : "Delete"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

MemberDialog.defaultProps = {
  serviceLocation: null,
  onClose: () => {}
};

export default MemberDialog;


// Params – id , refund_amount, Partial_amount

