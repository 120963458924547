import React from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { IconButton, Box, Typography } from "@mui/material";

const AddNew = (props) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      mt={props.nomargin ? 0 : 2}
      mb={props.nomargin ? 0 : 2}
      style={{ userSelect: "none" }}
    >
      <IconButton color="primary" onClick={props.onClick}>
        <AddCircleOutlineIcon />
      </IconButton>
      <Typography cursor="pointer" variant="subtitle2" component="span">
        {props.label}
      </Typography>
    </Box>
  );
};

AddNew.defaultProps = {
  nomargin: false,
};

export default AddNew;
