import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
  Checkbox,
  Button,
} from "@mui/material";
import dayjs from "dayjs";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import FormField from "components/FormField";
import { acceptEstimate } from "api/jobs";
import { useNavigate } from "react-router-dom";
import Toast from "components/Toast";

const EstimateModal = ({ job, selected, handleClose }) => {
  const { estimates } = job;
  const [estimate, setEstimate] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (selected !== false) {
      setEstimate(estimates[selected]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const handleChange = (name, value) => {
    setEstimate({ ...estimate, [name]: value });
  };

  const displayContractor = () => {
    const { contractor } = estimate;
    return (
      <Box display="flex">
        <Typography fontWeight="bold">{contractor.name}</Typography>
        <Typography pl={4} flexGrow={1} color="gray">
          {contractor.email}
        </Typography>
      </Box>
    );
  };

  const displayEmail = () => {
    const { mailer_email } = estimate;
    return (
      <Box
        mt={2}
        max={200}
        overflow="auto"
        pt={2}
        pb={2}
        pl={1}
        pr={1}
        border="solid 1px #ddd"
        dangerouslySetInnerHTML={{ __html: mailer_email.content }}
      />
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    acceptEstimate({
      id: estimate.id,
      notes: estimate.notes,
      description: estimate.description,
      terms: estimate.terms,
      accepted: estimate.accepted,
      total_cost: estimate.total_cost,
    }).then((res) => {
      Toast.fire({
        title: estimate.accepted ? "Estimate Accepted" : "Notes Updated",
        icon: "success",
      });
      handleClose();
      navigate(`/app/members/${job.membership_id}`);
    });
  };

  return (
    <Dialog open fullWidth maxWidth="md" scroll="body">
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <Typography fontWeight="bold">{`Estimate for job No. ${estimate.job_id} - ${job.code.description}`}</Typography>
          <Typography pt={-2} m={0} component={"p"} variant="caption">
            Date: {dayjs(estimate.created_at).format("MM/DD/YYYY")}
          </Typography>
        </div>
        <IconButton onClick={handleClose}>
          <Icon path={mdiClose} size={1} color="primary" />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item sm={9}>
              {estimate.contractor && displayContractor()}
              {estimate.mailer_email && displayEmail()}
              <Box pt={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      name="accepted"
                      checked={estimate.accepted}
                      onChange={(e) =>
                        handleChange("accepted", e.target.checked)
                      }
                    />
                  }
                  label={"Accept Estimate"}
                />
              </Box>
              <Box pt={2}>
                <Typography
                  fontSize={14}
                  mb={1}
                  borderBottom="solid 1px lightgray"
                  width={150}
                  color="gray"
                >
                  Notes
                </Typography>
                <FormField
                  multiline
                  value={estimate.notes}
                  onChange={(e) => handleChange("notes", e.target.value)}
                  fontSize={12}
                  rows={4}
                />
              </Box>
            </Grid>
            <Grid item sm={3}>
              <Button fullWidth variant="contained" disabled>
                Attach File
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            disabled={!estimate.accepted || loading}
            role="submit"
            type="submit"
            variant="contained"
          >
            {loading ? "Please Wait" : "Finalize Acceptance"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EstimateModal;
