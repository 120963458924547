import React, { Fragment } from "react";

import classNames from "classnames";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import styles from "./breadCrumb-jss";
import { withStyles } from "@mui/styles";
import useBreadcrumb from "hooks/useBreadcrumb";

const Breadcrumbs = (props) => {
  const { classes, theme, separator } = props;

  const location = useLocation();
  const { breadcrumbReducer } = useBreadcrumb();
  let parts = location.pathname.split("/");
  const place = parts[parts.length - 1];
  parts = parts.slice(1, parts.length - 1);

  if (parts.length === 0) {
    return null;
  }

  return (
    <section
      className={classNames(
        theme === "dark" ? classes.dark : classes.light,
        classes.breadcrumbs
      )}
    >
      <p style={{ color: "#930723" }}>
        <span>
          {parts.map((part, partIndex) => {
            const path = ["", ...parts.slice(0, partIndex + 1)].join("/");
            return (
              <Fragment key={path}>
                <Link style={{ color: "#930723" }} to={path}>
                  {part}
                </Link>
                {((breadcrumbReducer.breadcrumbCustomPlace === "" &&
                  partIndex !== 1) ||
                  breadcrumbReducer.breadcrumbCustomPlace !== "") &&
                  separator}
              </Fragment>
            );
          })}
          &nbsp;
          {breadcrumbReducer.breadcrumbCustomPlace !== null
            ? breadcrumbReducer.breadcrumbCustomPlace
            : place}
        </span>
      </p>
    </section>
  );
};

Breadcrumbs.defaultProps = {
  separator: "/",
  breadCrumbTitle: "",
};

// Breadcrumbs.propTypes = {
//   classes: PropTypes.object.isRequired,
//   location: PropTypes.object.isRequired,
//   theme: PropTypes.string.isRequired,
//   separator: PropTypes.string.isRequired,
//   breadCrumbTitle: PropTypes.string,
// };

export default withStyles(styles)(Breadcrumbs);
