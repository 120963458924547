import { makeStyles } from "@mui/styles";

const rootWraper = {
  display: "flex",
  width: "100%",
  zIndex: 1,
  position: "relative",
};

const wrapper = (theme) => ({
  padding: theme.spacing(3),
  textAlign: "center",
  backgroundColor: theme.palette?.background?.paper,
  backgroundRepeat: "no-repeat",
  color: theme.palette.text.primary,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundAttachment: "fixed",
});

const styles = makeStyles((theme) => ({
  root: {
    ...rootWraper,
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      overflow: "hidden",
    },
  },
  userFormWrap: {
    maxWidth: 850,
    [theme.breakpoints.up("md")]: {
      width: 500,
    },
    [theme.breakpoints.down("sm")]: {
      with: "80%",
      marginBottom: theme.spacing(3),
    },
  },
  paperWrap: {
    ...wrapper(theme),
  },
  checkoutForm: {
    padding: theme.spacing(4),
  },
  caption: {
    color: "gray",
  },
}));

export default styles;
