import React, { useEffect } from "react";
import ContentBlock from "components/ContentBlock";
import useApplication from "hooks/useApplication";

export default function Reports() {
  const { setPage } = useApplication();
  useEffect(() => {
    setPage({
      title: "Reports",
      path: "/app/reports",
    });
    // eslint-disable-next-line
  }, []);
  return (
    <ContentBlock title="Reports" icon="ion-ios-clipboard-outline">
      Reports Page
    </ContentBlock>
  );
}
