import { makeStyles } from "@mui/styles";

const appFrame = {
  display: "flex",
  width: "100%",
  minHeight: "100%",
  zIndex: 1,
};

const styles = makeStyles((theme) => ({
  appFrameInner: {
    color: theme.palette.text.primary,
    background: '#ecedf2',
    ...appFrame,
  },
  sideNav: {
    flexDirection: "row",
  },
  content: {
    width: "100%",
    padding: theme.spacing(2),
    marginTop: theme.spacing(6),
    minHeight: "100%",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  contentPaddingLeft: {
    paddingLeft: theme.spacing(10),
  },
}));

export default styles;
