import React, { Fragment, useState, useEffect } from "react";
import classNames from "classnames";
import {
  AppBar,
  Fab,
  Hidden,
  Toolbar,
  Typography,
} from "@mui/material";
import useStyle from "./header-jss";
import { Menu } from "@mui/icons-material";
import UserMenu from "../UserMenu";
import useSystem from "../../../../WebR/hooks/useSystem";


const Header = ({ toggleDrawerOpen, app }) => {
  const { data } = useSystem();
  const classes = useStyle();
  const [open] = useState(false);
  const [turnDarker, setTurnDarker] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  let flagDarker = false;

  let flagTitle = false;

  const handleScroll = () => {
    const doc = document.documentElement;
    const scroll = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    const newFlagDarker = scroll > 30;
    const newFlagTitle = scroll > 40;
    if (flagDarker !== newFlagDarker) {
      setTurnDarker(newFlagDarker);
      flagDarker = newFlagDarker;
    }
    if (flagTitle !== newFlagTitle) {
      setShowTitle(newFlagTitle);
      flagTitle = newFlagTitle;
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <AppBar
        className={classNames(
          classes.appBar,
          classes.floatingBar,
          classes.appBarShift,
          classes.left,
          turnDarker && classes.darker
        )}
      >
        <Toolbar className={classes.toolbar} disableGutters={!open}>
          <Hidden smDown>
            <div className={classes.headerProperties}>
              {data.state !== "in-call" && (
                <>
                  <Typography
                    component="h2"
                    className={classNames(
                      classes.headerTitle,
                      showTitle && classes.show
                    )}
                  >
                    {app.activePage?.title}
                  </Typography>
                </>
              )}
            </div>
          </Hidden>
          <Hidden smUp>
            <Fab
              size="small"
              className={classes.menuButton}
              color="primary"
              aria-label="Menu"
              onClick={toggleDrawerOpen}
            >
              <Menu/>
            </Fab>
          </Hidden>
          <div className={classes.searchWrapper}>
            <div className={classNames(classes.wrapper, classes.light)}>
              <div className={classes.search}></div>
            </div>
          </div>
          <UserMenu app={app} />
        </Toolbar>
      </AppBar>
    </Fragment>
  );
};

Header.defaultProps = {
  inCall: false,
};
export default Header;
