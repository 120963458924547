import React, { useEffect } from "react";
import { MenuItem } from "@mui/material";
import FormField from "components/FormField";
import useUtils from "hooks/useUtils";

const RefundC = ({ ...others }) => {
  const { utils, getStates } = useUtils();

  useEffect(() => {
    if (!utils.statesLoaded) {
      getStates({ all: 1 });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormField select {...others}>
        {["FULL", "NO", "HALF", "CUSTOM"].map((state) => (
          <MenuItem 
          //key={others.name + state.code} 
          value={state}>
            {state}
          </MenuItem>
        ))}
      </FormField>
    </>
  );
};

export default RefundC;
