import axios from "utils/axios";
import { SEARCH_CONTRACTOR_ROUTE } from "./routes";

// const SEARCH_CONTRACTOR_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractors`;

const GET_CONTRACTOR_BY_EMAIL = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractors/search-by-email`;
const CREATE_CONTRACTOR = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractors`;
const DELETE_CONTRACTOR = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractors`;
const GET_CONTRACTOR = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractors`;
const UPDATE_CONTRACTOR = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractors`;

export const searchContractorsRequest = (search, limit = 10) =>
  axios.get(SEARCH_CONTRACTOR_ROUTE, { params: { search, limit } });

export const getContractorsRequest = (search) =>
  axios.get(SEARCH_CONTRACTOR_ROUTE, { params: search });

export const getContractorByEmail = (email) =>
  axios.post(GET_CONTRACTOR_BY_EMAIL, { email });

export const createContractor = (data) =>
  axios.post(CREATE_CONTRACTOR, { ...data });

export const deleteContractor = (contractorId) =>
  axios.delete(`${DELETE_CONTRACTOR}/${contractorId}`);

export const getContractor = (id) => axios.get(`${GET_CONTRACTOR}/${id}`);

export const updateContractor = (id, data) =>
  axios.post(`${UPDATE_CONTRACTOR}/${id}`, { ...data });
