import React, { useState } from 'react';
import { Box, Tab, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ActivityTrackingMembership from './ActivityTrackingMembership';
import ActivityTrackingJob from './ActivityTrackingJob';
import ActivityTrackingInvoice from './ActivityTrackingInvoice';
import ActivityTrackingNote from './ActivityTrackingNote';

const History = ({ user }) => {
  const [tabValue, setTabValue] = useState("0");

  return (
    <Box sx={{ width: { sx: "100%", md: "100%" } }}>
      <Typography variant="h6" fontWeight="bold" color="primary" sx={{ mb: 2 }}>
        History
      </Typography>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>

          <TabList onChange={(_event, newValue) => setTabValue(newValue)}>
            <Tab label="Memberships" value="0" />
            <Tab label="Job Sheets" value="1" />
            <Tab label="Notes" value="2" />
            <Tab label="Invoices" value="3" />
          </TabList>
        </Box>
        <TabPanel value="0">
          <ActivityTrackingMembership user={user} />
        </TabPanel>
        <TabPanel value="1">
          <ActivityTrackingJob user={user} />
        </TabPanel>
        <TabPanel value="2">
          <ActivityTrackingNote user={user} />
        </TabPanel>
        <TabPanel value="3">
          <ActivityTrackingInvoice user={user} />
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default History;
