import React from "react";
import { Typography, Box } from "@mui/material";

const HeadingLine = ({ title, boxProps, actionButton }) => {
  return (
    <Box
      display={"flex"}
      justifyContent="space-between"
      alignItems="center"
      sx={{ borderBottom: 1 }}
      {...boxProps}
    >
      <Typography variant="subtitle2">{title}</Typography>
      {actionButton && actionButton}
    </Box>
  );
};

HeadingLine.defaultProps = {
  boxProps: {
    mb: 1,
  },
  actionButton: false,
};

export default HeadingLine;
