import {
  SET_ACTIVITIES_LOADING,
  SET_ACTIVITIES,
  SET_ACTIVITIES_MORE_LOADING,
  SET_ACTIVITIES_PER_PAGE,
  SET_MORE_ACTIVITIES,
  SET_ACTIVITIES_PAGE
} from "../actions";

const initialState = {
  loading: false,
  loadingMore: false,
  data: [],
  meta: {
    current_page: 1,
    per_page: 10
  },
};

export default function activityTracking(state = initialState, action = {}) {
  switch (action.type) {
    case SET_ACTIVITIES:
      return {
        ...state,
        loading: false,
        data: action.payload.result,
        meta: action.payload.meta,
      };
    case SET_ACTIVITIES_LOADING:
      return { ...state, loading: action };
    case SET_ACTIVITIES_PER_PAGE:
      return { ...state, meta: { ...state.meta, per_page: action.payload } };
      case SET_ACTIVITIES_PAGE:
      return { ...state, meta: { ...state.meta, current_page: action.payload } };
    case SET_MORE_ACTIVITIES:
      return {
        ...state,
        loadingMore: false,
        data: [ ...state.data, ...action.payload.result ],
        meta: { ...state.meta, ...action.payload.meta },
      };
    case SET_ACTIVITIES_MORE_LOADING:
      return { ...state, loadingMore: action };
    default:
      return state;
  }
}
