import React from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { TableRow, TableCell, Button, Chip } from "@mui/material";
import { callStausColors } from "utils/queue";

const ContractorRow = ({ data, deleteContractor }) => {
  const navigate = useNavigate();
  return (
    <TableRow
    // key={contractor.uuid}
    >
      {/* <TableCell component="th" scope="row" width="5%">
        {data.id}
      </TableCell> */}
      <TableCell component="th" scope="row" width="25%">
        {data.from.name}
      </TableCell>
      <TableCell component="th" scope="row" width="20%">
        {data.from.phoneNumber || data.from.extensionNumber}
      </TableCell>
      <TableCell component="th" scope="row" width="25%">
        {/* {data.result} */}
        <Chip
          sx={{
            mt: 1,
            justifyContent: "flex-start",
            background: callStausColors[data.result],
            color: "#fff",
          }}
          label={data.result}
        />
      </TableCell>
      <TableCell component="th" scope="row" width="25%">
        {data.duration}
      </TableCell>
      <TableCell component="th" scope="row" width="25%">
        {/* {data.startTime} */}
        {`${dayjs(data.startTime).format("MM/DD/YYYY hh:mm a")}`}
      </TableCell>
      {/* <TableCell component="th" scope="row" align="right" width="20%">
        <Button
          variant="contained"
          color="primary"
          // sx={{ mr: 2 }}
          size="small"
          onClick={() => navigate(`/app/contractors/${data.contractor_id}`)}
        >
          Edit
        </Button>
        <Button
          variant="contained"
          color="error"
          size="small"
          onClick={() => deleteContractor(data.contractor_id)}
        >
          Delete
        </Button>
      </TableCell> */}
    </TableRow>
  );
};

export default ContractorRow;
