import React from "react";
import { Box, Typography } from "@mui/material";
import FormField from "components/FormField";

const EstimateMessage = ({ estimate, handleChange, key }) => {
  if (!estimate.hasMessage) {
    return null;
  }
  return (
    <Box key={key}>
      <Typography variant="subtitle2" pb={1}>
        {estimate.contractor.name}
      </Typography>
      <FormField
        multiline
        rows={2}
        label="Enter additional message"
        value={estimate.message}
        onChange={(e) => handleChange(estimate.contractor.id, e.target.value)}
      />
    </Box>
  );
};

export default EstimateMessage;
