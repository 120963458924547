import React, { Fragment, useEffect, useState } from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
  Divider,
} from "@mui/material";
import { getCallLog } from "api/dashboard";
import useStyles from "./styles";
import useSystem from "WebR/hooks/useSystem";

function ContactList({ filterDate }) {
  const classes = useStyles();
  const [state, _setState] = useState({
    callLog: [],
    loading: true,
  });
  const setState = (values) => _setState({ ...state, ...values });

  // eslint-disable-next-line no-unused-vars
  const { data, call } = useSystem();

  useEffect(() => {
    setState({
      loading: true
    });
    getCallLog({ from: filterDate[0], to: filterDate[1] }).then((res) => {
      setState({
        logs: res.data,
        loading: false,
      });
    });

     // eslint-disable-next-line no-unused-vars
  }, [filterDate]);


  const getItem = (dataArray) =>
    dataArray.map((data) => (
      <Fragment key={data.id}>
        <ListItem button key={data.id}>
          <ListItemText
            primary={`${data?.contact ? data.contact.name : data.phone_number}`}
            secondary={`Status: ${data.status} ${data.status === "ended" ? " - Duration: 02:23" : ""}`}
          />
          <ListItemSecondaryAction>
            <Tooltip title="Call">
              <IconButton
                className={classes.tealText}
                aria-label="Tel"
              >
                <PhoneIcon />
              </IconButton>
            </Tooltip>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
      </Fragment>
    ));
  if (state.loading) {
    return null;
  }
  return <List>{getItem(state.logs)}</List>;
}

export default ContactList;
