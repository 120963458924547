
import { Table, TableCell, TableContainer, TableRow } from "@mui/material";
import { TableHead } from "mui-datatables";
import React from "react";
const PdeUi = () => {
    return (
        <>
            <table style={{ width: " 1000px", margin: " auto" }}>
                <tr>
                    <td>
                        <table style={{ width: "100%" }}>
                            <tr>
                                <td style={{ textAlign: "end" }}>
                                    <img src="hoc-logo.png" alt="" />
                                </td>
                                <td style={{ paddingLeft: "23px" }}>
                                    <table>
                                        <tr>
                                            <td style={{ fontWeight: "600" }}>
                                                7525 se 20th street, suite 408
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: "600" }}>7525 se 20th</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: "600" }}>7525 se 20th street, suite</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: "600" }}>7525 se 20th</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td style={{ padding: "0px 80px" }}>
                        <table style={{ width: "100%" }}>
                            <tr>
                                <td style={{ width: "70%" }}>
                                    <table style={{ width: "100%", marginTop: "10px" }}>
                                        <tr>
                                            <td style={{ fontWeight: "600" }}>Acct #: 8014</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: "600" }}>Acct #: 8014</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: "600" }}>Acct #: 8014</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: "600" }}>Acct #: 8014</td>
                                        </tr>
                                        <tr style={{ display: "block", marginTop: "20px" }}>
                                            <td>
                                                Please return this part of statment and note any change in
                                                address or phone number.
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td style={{ width: "30%" }}>
                                    <table style={{ verticalAlign: "baseline", width: "100%" }}>
                                        <tr>
                                            <td
                                                style={{
                                                    fontWeight: "600",
                                                    fontSize: "16px",
                                                    textAlign: "center"
                                                }}

                                            >
                                                4/4/2024
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <table style={{ width: "100%", border: "1px solid" }}>
                                                    <tr>
                                                        <th style={{ background: "#000", color: "#fff" }}>
                                                            BALANCE DUE
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                height: "20px",
                                                                fontWeight: "600",
                                                                textAlign: "center",
                                                                fontSize: "20px",
                                                                padding: "7px"
                                                            }}

                                                        >
                                                            $654.19
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ background: "#000", color: "#fff" }}>
                                                            BALANCE DUE
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                height: "20px",
                                                                fontWeight: "600",
                                                                textAlign: "center",
                                                                fontSize: "20px",
                                                                padding: "7px"
                                                            }}

                                                        >
                                                            $654.19
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td
                        style={{
                            border: "1px solid ",
                            height: " 300px",
                            verticalAlign: "baseline"
                        }}

                    >
                        <table style={{ width: "100%" }}>
                            <tr
                                style={{ background: "#000", color: "#fff", borderCollapse: "collapse" }}
                            >
                                <th>DATE</th>
                                <th colspan="2">DESCRIPTION</th>
                                <th>CHARGES</th>
                                <th>CREDITS</th>
                                <th colspan="3" style={{ width: "40%", textAlign: "justify" }}>
                                    BALANCE
                                </th>
                            </tr>
                            <tr>
                                <td>3/12/2024</td>
                                <td colspan="2">452456</td>
                                <td>165.15</td>
                                <td>0.00</td>
                                <td colspan="3">165.15 Three/Year Spry</td>
                            </tr>
                            <tr>
                                <td>3/12/2024</td>
                                <td colspan="2">452456</td>
                                <td>165.15</td>
                                <td>0.00</td>
                                <td colspan="3">165.15 Three/Year Spry</td>
                            </tr>
                            <tr>
                                <td>3/12/2024</td>
                                <td colspan="2">452456</td>
                                <td>165.15</td>
                                <td>0.00</td>
                                <td colspan="3">165.15 Three/Year Spry</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td colspan="2"></td>
                                <td style={{ borderTop: "1px solid " }}>4544</td>
                                <td style={{ bordertop: "1px solid" }}>0.00</td>
                                <td colspan="3">
                                    165.15 Three/Year Spry
                                    <p style={{ margin: "0.5rem 0px 0px 7rem" }}>
                                        Lorem ipsum dolor sit amet consectetur non, sapiente dicta
                                        magni molestias iusto?
                                    </p>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>




                <tr>
                    <td>
                        <table style={{ width: "100%" }}>
                            <tr>
                                <td colspan="2" style={{ textAlign: "center" }}>
                                    <p style={{ margin: "0" }}>
                                        Please see reverse side for important information
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "end" }}>
                                    <img src="hoc-logo.png" alt="" />
                                </td>
                                <td style={{ paddingLeft: "23px" }}>
                                    <table>
                                        <tr>
                                            <td style={{ fontWeight: 600 }}>
                                                PO BOX 1760, MEMRICER ISLAND WA 98040 + 206-622-3500
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td style={{ textAlign: "end" }}>
                        <table style={{ width: "100%" }}>
                            <tr>
                                <td
                                    style={{ fontSize: "30px", fontWeight: "600", letterSpacing: "2px" }}
                                >
                                    455602
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingRight: " 16rem" }}>117229</td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <table
                                        style={{
                                            border: "1px solid",
                                            backgroundColor: "#eee",
                                            float: "right",
                                            borderCollapse: "collapse",
                                        }}

                                    >
                                        <tr>
                                            <td style={{ border: "1px solid", }}>INVOICE NO.</td>

                                            <td style={{ border: "1px solid", }}>INVOICE NO.</td>
                                            <td style={{ border: "1px solid", }}>INVOICE NO.</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table style={{ width: "100%" }}>
                            <tr>
                                <td style={{ width: "50%" }}>
                                    <table>
                                        <tr>
                                            <th>SERIVCE PERFORMANCE FOR.</th>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span>MEMBER: </span>
                                                <span>JAMES HADISTY</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span>MEMBER: </span>
                                                <span>JAMES HADISTY</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span>MEMBER: </span>
                                                <span>JAMES HADISTY</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span>MEMBER: </span>
                                                <span>JAMES HADISTY</span>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td style={{ width: "50%" }}>
                                    <table>
                                        <tr>
                                            <th>SERIVCE PERFORMANCE FOR.</th>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span>MEMBER: </span>
                                                <span>JAMES HADISTY</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span>MEMBER: </span>
                                                <span>JAMES HADISTY</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span>MEMBER: </span>
                                                <span>JAMES HADISTY</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span>MEMBER: </span>
                                                <span>JAMES HADISTY</span>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table
                            style=
                            {{
                                width: "100%",
                                marginTop: "10px",
                                border: "1px solid",
                                borderCollapse: "collapse",
                            }}
                        >
                            <tr style={{ backgroundColor: "#eee", color: "#000" }}>
                                <th style={{ border: "1px solid", }}>MATRIAL</th>
                                <th style={{ border: "1px solid", }}>AMOUNT</th>
                                <th style={{ border: "1px solid", }}>DESCRIPTION OF WORK LABOR</th>
                                <th style={{ border: "1px solid", }}>AMOUNT</th>
                            </tr>
                            <tr>
                                <td style={{ border: "1px solid", }}></td>
                                <td style={{ border: "1px solid", }}></td>
                                <td style={{ border: "1px solid", }}>
                                    DIAGNOSTIC ON NON-WORKING FURANCE; MAINTENACE ON 2ND FURANCE
                                </td>
                                <td style={{ border: "1px solid", }}>191.00</td>
                            </tr>
                            <tr>
                                <td style={{ border: "1px solid", }}></td>
                                <td style={{ border: "1px solid", }}></td>
                                <td style={{ border: "1px solid", }}>
                                    DIAGNOSTIC ON NON-WORKING FURANCE; MAINTENACE ON 2ND FURANCE
                                </td>
                                <td style={{ border: "1px solid", }}>191.00</td>
                            </tr>
                            <tr>
                                <td style={{ border: "1px solid", }}></td>
                                <td style={{ border: "1px solid", }}></td>
                                <td style={{ border: "1px solid", }}>
                                    DIAGNOSTIC ON NON-WORKING FURANCE; MAINTENACE ON 2ND FURANCE
                                </td>
                                <td style={{ border: "1px solid", }}>191.00</td>
                            </tr>
                            <tr>
                                <td style={{ border: "1px solid", }}></td>
                                <td style={{ border: "1px solid", }}></td>
                                <td style={{ border: "1px solid", }}>
                                    DIAGNOSTIC ON NON-WORKING FURANCE; MAINTENACE ON 2ND FURANCE
                                </td>
                                <td style={{ border: "1px solid", }}>191.00</td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table style={{ width: "100%" }}>
                            <tr>
                                <td style={{ width: "25%" }}>REMARK: #1756545445</td>
                                <td style={{ width: "25%" }}>APR 0 3 2024</td>
                                <td style={{ width: "25%" }}>
                                    <table>
                                        <tr>
                                            <td>
                                                <table
                                                    style=
                                                    {{
                                                        border: "1px solid",
                                                        borderCollapse: "collapse",
                                                        width: "100%",
                                                    }}
                                                >
                                                    <tr>
                                                        <th style={{ border: "1px solid", }}>ARRIVED</th>
                                                        <th style={{ border: "1px solid", }}>ARRIVED</th>
                                                        <th style={{ border: "1px solid", }}>ARRIVED</th>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: "1px solid", }}>ARRIVED</td>
                                                        <td style={{ border: "1px solid", }}>ARRIVED</td>
                                                        <td style={{ border: "1px solid", }}>ARRIVED</td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img src="hoc-logo.png" alt="" />
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td style={{ width: "25%", verticalAlign: "baseline" }}>
                                    <table
                                        style={{
                                            border: "1px solid ",
                                            borderCollapse: "collapse",
                                            width: "100%",
                                            textAlign: "end"
                                        }}

                                    >
                                        <tr>
                                            <td style={{ background: "#eee", border: "1px solid" }}>
                                                TOTAL LABOR
                                            </td>
                                            <td style={{ border: "1px solid", }}>191 .00</td>
                                        </tr>
                                        <tr>
                                            <td style={{ background: "#eee", border: "1px solid" }}>
                                                TOTAL LABOR
                                            </td>
                                            <td style={{ border: "1px solid", }}>191 .00</td>
                                        </tr>
                                        <tr>
                                            <td style={{ background: "#eee", border: "1px solid" }}>
                                                TOTAL LABOR
                                            </td>
                                            <td style={{ border: "1px solid", }}>191 .00</td>
                                        </tr>
                                        <tr>
                                            <td
                                                style={{
                                                    background: "#eee",
                                                    border: "1px solid",
                                                    fontWeight: 600
                                                }}

                                            >
                                                TOTAL LABOR
                                            </td>
                                            <td style={{ border: "1px solid", }}>191 .00</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>


            <table style={{ width: " 1000px ", margin: "4rem  auto" }}>
                <tr>
                    <td>
                        <table style={{ width: " 100%", margin: "2rem 0rem" }}>
                            <tr>
                                <td><img src="hoc-logo.png" alt="" /></td>
                                <td>
                                    <table>
                                        <tr>
                                            <td>Home Comfort Allince</td>
                                        </tr>
                                        <tr>
                                            <td>Home Comfort Allince</td>
                                        </tr>
                                        <tr>
                                            <td>Home Comfort Allince</td>
                                        </tr>
                                        <tr>
                                            <td>Home Comfort Allince</td>
                                        </tr>
                                    </table>
                                </td>
                                <td>
                                    <table>
                                        <tr>
                                            <td style={{ fontWeight: "600" }}>INVOICE</td>
                                            <td>INVOICE</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: "600" }}>INVOICE</td>
                                            <td>INVOICE</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: "600" }}>INVOICE</td>
                                            <td>INVOICE</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table style={{ width: "100%" }}>
                            <tr>
                                <td>
                                    <table>
                                        <tr>
                                            <td style={{ fontWeight: "600" }}>Billing Address</td>
                                        </tr>
                                        <tr>
                                            <td>HARDISTY, JAMES 30062</td>
                                        </tr>
                                        <tr>
                                            <td>5036 84th Avenue Southeast</td>
                                        </tr>
                                        <tr>
                                            <td>Mercer Island, WA 980404 USA</td>
                                        </tr>
                                    </table>
                                </td>
                                <td>
                                    <table style={{ width: "100%", textAlign: "center" }}>
                                        <tr>
                                            <td style={{ fontWeight: "600" }}>Billing Address</td>
                                        </tr>
                                        <tr>
                                            <td>HARDISTY, JAMES 30062</td>
                                        </tr>
                                        <tr>
                                            <td>5036 84th Avenue Southeast</td>
                                        </tr>
                                        <tr>
                                            <td>Mercer Island, WA 980404 USA</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td
                                    colspan="2"
                                    style={{ textAlign: "center", fontSize: "18px", fontWeight: "600" }}
                                >
                                    Description of work
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table style={{ borderTop: "2px , solid" }} >
                            <tr>
                                <td>
                                    <p style={{ fontWeight: "600" }}>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                        Quidem eaque perspiciatis architecto saepe explicabo porro
                                        maiores optio? Dignissimos sapiente, illo ratione itaque enim
                                        labore dolores est, distinctio nemo, aperiam porro? Lorem
                                        ipsum dolor sit amet consectetur adipisicing elit. Quidem
                                        eaque perspiciatis architecto saepe ratione itaque enim labore
                                        dolores est, distinctio nemo, aperiam porro?
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table style={{ width: "100%", textAlign: "left", borderTop: " 1px , solid" }}>
                                        <tr>
                                            <th>Task #</th>
                                            <th style={{ padding: "0px ,21px" }}>Description</th>
                                            <th>Quantity</th>
                                        </tr>
                                        <tr>
                                            <td>T885511</td>
                                            <td>
                                                <p style={{ padding: "5px, 21px", margin: "0" }}>
                                                    Lorem ipsum dolor sit amet consectetur, adipisicing
                                                    elit. Deserunt exercitationem quasi ferendis, molestias
                                                    in fugiat asperiores optio dolores!
                                                </p>
                                            </td>
                                            <td>1.00</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>
                                                <p style={{ padding: "5px, 21px", margin: "0" }}>
                                                    Lorem ipsum dolor sit amet consectetur, adipisicing
                                                    elit. Deserunt exercitationem quasi ferendis, molestias
                                                    in fugiat asperiores optio dolores!
                                                </p>
                                            </td>
                                            <td>1.00</td>
                                        </tr>
                                        <tr>
                                            <td>T45245</td>
                                            <td>
                                                <p style={{ padding: "5px, 21px", margin: "0" }}>
                                                    Lorem ipsum dolor sit amet consectetur, adipisicing
                                                    elit. Deserunt exercitationem quasi ferendis, molestias
                                                    in fugiat asperiores optio dolores!
                                                </p>
                                            </td>
                                            <td>1.00</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <table style={{ width: "100%", borderTop: "1px solid " }}>
                            <tr>
                                <td style={{ width: "100%" }}>
                                    <table style={{ float: "right" }}>
                                        <tr>
                                            <td style={{ fontWeight: " 600" }}>Potenial Saving</td>
                                            <td>$0.00</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: " 600" }}>Potenial Saving</td>
                                            <td>$0.00</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: " 600" }}>Potenial Saving</td>
                                            <td>$0.00</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: " 600" }}>Potenial Saving</td>
                                            <td>$0.00</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: " 600" }}>Potenial Saving</td>
                                            <td>$0.00</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "center" }}>Thanku you for choosing home comfort allince</td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table>
                            <tr>
                                <td>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo hic fuga, laudantium porro corporis quaerat expedita, laboriosam consequatur, a illo doloribus quos eaque. Ut nisi quia error, sequi sed eveniet!
                                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Qui sequi distinctio deleniti dignissimos maxime rerum sunt obcaecati! Nihil necessitatibus odit ducimus suscipit quae nisi, assumenda quaerat laboriosam similique, iste eum!
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla velit reprehenderit aliquid modi. Eligendi, mollitia hic est reprehenderit alias corrupti omnis facere dolor, temporibus voluptates veniam tenetur quas, natus odit!
                                    </p>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>

        </>
    )
}
export default PdeUi;