import React from "react";
import { Box, Typography, Link, Divider } from "@mui/material";

export default function NotesComponent() {
  return (
    <>
      <Typography variant="h6">Notes</Typography>
      <Box mb={2} mt={1}>
        <Typography variant="body2" sx={{ color: "#999" }}>
          By: Janet 2/3/22
        </Typography>
        <Typography variant="body2">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled{" "}
        </Typography>
      </Box>

      <Box display="flex">
        <Link href="#" underline="none">
          View all notes
        </Link>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ mx: 2 }}
          color="primary"
        />
        <Link href="#" underline="none">
          Add note
        </Link>
      </Box>
    </>
  );
}
