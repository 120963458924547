import React, { useState, useEffect, createRef } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import LoadingButton from '@mui/lab/LoadingButton';
import brand from "theme/brand";
import {
  Button,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import { Box } from "@mui/system";
import useStyles from "../main-jss";
import useAuth from "hooks/useAuth";
import LinkBtn from "components/LinkBtn";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const user = useSelector((store) => store.auth);
  const classes = useStyles();
  const { validateMfa, resendMfaOtp } = useAuth();
  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false);
  const [otpResending, setOtpResending] = useState(false);
  const [otp, setOtp] = useState(Array(6).fill(''));
  const inputsRef = Array(6).fill().map(() => createRef());



  // ********************STARTS*****************************
  const [time, setTime] = useState(30);
  const [start, setStart] = useState(true);
  // ************************ENDS*************************

  const handleSubmit = (e) => {
    setSubmitting(true);
    e.preventDefault()
    if (otp.join('')?.length === 6) {
      validateMfa(Number(otp.join('')), (success, error) => {
        if (!error) {
          navigate('/app')
        }
        else {
          Swal.fire({
            toast: true,
            position: 'top-right',
            title: error.response.data.message || 'Invalid OTP',
            showConfirmButton: false,
            timer: 3000,
            icon: 'error'
          })
          setSubmitting(false);
          navigate('/mfa')
        }
      })
    }
    // const parsedOtp = parseInt(otp.replace(/,/g, ''))   
  }

  // Resend MFA OTP
  const resendOtp = () => {
    setOtpResending(true)
    resendMfaOtp((success, error) => {
      if (!error) {
        Swal.fire({
          toast: true,
          position: 'top-right',
          title: success.data.message || 'OTP resent',
          showConfirmButton: false,
          timer: 3000,
          icon: 'success'
        })
        setTime(30);
        setStart(true)
      }
      else {
        Swal.fire({
          toast: true,
          position: 'top-right',
          title: error.response.data.message || 'Failed to resend OTP! Try again',
          showConfirmButton: false,
          timer: 3000,
          icon: 'error'
        })
        setOtpResending(false)
      }
    })
  }

  // OTP HANDLING
  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
  };

  const handleKeyUp = (element, index) => {
    if (element.keyCode === 8 && index !== 0) {
      inputsRef[index - 1].current.focus();
    }
  };

  // Handle Countdown
  useEffect(() => {
    let timer = null;
    if (start && time > 0) {
      timer = setInterval(() => setTime(time - 1), 1000);
    } else if (start && time === 0) {
      setStart(false);
    }
    setOtpResending(false)
    return () => clearInterval(timer);
  }, [start, time]);

  useEffect(() => {
    if (user.uuid == null || user.uuid == '') {
      navigate('/')
      return
    }
    otp.forEach((digit, index) => {
      if (digit !== '') {
        if (index < otp.length - 1) {
          inputsRef[index + 1].current.focus();
        }
      }
    });
  }, [otp]);

  useEffect(() => {
    if (otp.join('')?.length < 6) {
      setSubmitting(true);
    }
    else {
      setSubmitting(false)
    }

  }, [otp])


  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.userFormWrap}>
          <Paper className={classes.paperWrap}>
            <div className={classes.topBar}>
              <NavLink to="/" className={classes.brand}>
                <img src={brand.logo} alt={brand.name} />
              </NavLink>
            </div>
            <Typography variant="h5" className={classes.title} gutterBottom>
              Enter OTP sent to your registered Email-ID
            </Typography>
            {/* TODO: socmedLogin */}

            <section className={classes.formWrap}>
              <form onSubmit={handleSubmit}>
                <Box pt={2}>

                  {otp.map((data, index) => {
                    return (
                      <TextField
                        key={index}
                        type="tel"
                        name="otp"
                        variant="outlined"
                        value={data}
                        onChange={e => handleChange(e.target, index)}
                        onKeyUp={e => handleKeyUp(e, index)}

                        style={{ width: '10%', marginRight: 12 }}
                        inputProps={{ maxLength: 1 }}
                        inputRef={inputsRef[index]} // Assign ref to input
                      />
                    );
                  })}

                </Box>
                <Box pt={2}>
                  {time === 0 ? <LoadingButton loading={otpResending} loadingIndicator="Resending.." onClick={resendOtp} variant="text">Resend OTP</LoadingButton> : <span>You can request for OTP again after : <strong>{time}</strong>  sec</span>}
                </Box>

                <div className={classes.btnArea}>
                  <Button
                    disabled={submitting}
                    variant="contained"
                    fullWidth
                    color="primary"
                    size="large"
                    type="submit"
                  >
                    SUBMIT
                  </Button>
                </div>
              </form>
              <Box justifyContent="space-between" display="flex" pt={2} pb={2} fontSize={18} >
                <LinkBtn style={{ color: 'blue', textDecoration: 'none', fontFamily: ['Open Sans', 'sans-serif'] }} to="/">Back to login</LinkBtn>
              </Box>
            </section>
          </Paper>
        </div>
      </div>
    </div>
  );
}
