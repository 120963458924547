import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { Box } from "@mui/material";
import useFileManager from "hooks/useFileManager";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export default function UploadFiles({
  onUploaded,
  onUploading,
  fileable_type,
  fileable_id,
  parent_id,
}) {
  const { uploadFile } = useFileManager();
  const onDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      onUploading(file);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileable_type", fileable_type);
      formData.append("fileable_id", fileable_id);
      formData.append("parent_id", parent_id || null);
      uploadFile(formData).then((res) => {
        onUploaded(res.data);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({ onDrop });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <Box sx={{ height: "200px" }}>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>Drag & drop files here, or click to select files</p>
      </div>
    </Box>
  );
}
