import { useState } from "react";
import dayjs from "dayjs";
import {
  Box,
  Typography,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FolderIcon from "@mui/icons-material/Folder";

const DirectoryItem = ({
  file,
  deleteFile,
  selectFile,
  isSelected,
  renameFile,
  openDir,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (e) => {
    if (e.detail === 2) {
      openDir(file.uuid);
    }
  };

  return (
    <TableRow
      key={file.uuid}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        cursor: "pointer",
        "& td": { opacity: !file.loading ? 1 : 0.5 },
      }}
      hover
      selected={isSelected}
    >
      <TableCell padding="checkbox">
        {/* {!file.loading && file.content_type === "directory" && (
          <Checkbox
            color="primary"
            checked={isSelected}
            onChange={() => selectFile(file.uuid)}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        )} */}
      </TableCell>
      <TableCell align="left" onClick={(event) => handleClick(event)}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FolderIcon />
          <Typography sx={{ ml: 2 }}>{file.label}</Typography>
        </Box>
      </TableCell>
      <TableCell align="right" onClick={(event) => handleClick(event)}>
        {dayjs(file.created_at).format("MM/DD/YYYY hh:mma")}
      </TableCell>
      <TableCell align="right">
        {file.content_type === "directory" && (
          <Tooltip title="Delete">
            <IconButton
              aria-label="delete"
              onClick={(event) => setAnchorEl(event.currentTarget)}
              disabled={file?.loading}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Preview">
          <IconButton
            aria-label="preview"
            disabled={file?.loading}
            onClick={() => openDir(file.uuid)}
          >
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              renameFile(file.uuid);
              handleClose();
            }}
          >
            Rename
          </MenuItem>
          <MenuItem
            onClick={() => {
              deleteFile(file.uuid);
              handleClose();
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
};

export default DirectoryItem;
