import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: 10,
    height: 190,
    marginBottom: 6,
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      height: 126,
      marginBottom: -1,
      alignItems: "flex-end",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
    "& > *": {
      padding: "0 5px",
    },
  },
  title: {
    color: theme.palette.common.white,
    fontSize: 14,
    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
    },
    fontWeight: 400,
  },
  counter: {
    color: theme.palette.common.white,
    fontSize: 28,
    fontWeight: 500,
  },
  customContent: {
    textAlign: "right",
  },
}));

export default styles;
