import React, { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";
import useApplication from "hooks/useApplication";
import TableFooterWithPagination from "components/TableFooterWithPagination";
import SearchInput from "components/SearchInput";
import useServiceLocations from "hooks/useServiceLocations";
import ServiceLocationRow from "./components/ServiceLocationRow";
import ServiceLocationManagementDialog from "../ServiceLocationManagementDialog";
import "../../../../../utils/sweetalert.css"
import Toast from "components/Toast";

export default function ServiceLocationsIndex() {
  const { setPage } = useApplication();
  const { deleteServiceLocation } = useServiceLocations();
  const [state, _setState] = useState({
    serviceLocations: [],
    serviceLocation: null,
    loading: false,
    activeTab: 0,
    activeRole: 0,
    open: false,
  });
  const setState = (newState) => _setState({ ...state, ...newState });

  const {
    serviceLocationsReducer: { results, query, loading, meta },
    getServiceLocations,
    search,
    changePage,
    changePerPage,
  } = useServiceLocations();

  useEffect(() => {
    setPage({
      title: "Service Locations",
      path: "/app/settings/service-locations",
    });
    search(query);
    // eslint-disable-next-line
  }, []);

  const handleSearch = (searchValue) => {
    search(searchValue);
  };

  const handleChangePage = (_event, newPage) => {
    changePage(newPage);
    getServiceLocations({ search: query, page: newPage + 1, per_page: meta.per_page });
  };

  const handleChangeRowsPerPage = (event) => {
    changePerPage(event);
    getServiceLocations({
      search: query,
      page: meta.current_page,
      per_page: parseInt(event.target.value),
    });
  };

  const onDeleteHandler = (venodrId) => {
    Swal.fire({
      title: "Delete service location?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        title: "swal-text",
        popup: "swal-text"

      },
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteServiceLocation(venodrId)
          .then((res) => {
            Toast.fire({
              icon: "success",
              title:res.data.message,
              position:"top-right",
              timer:3000
            })
            search();
          })
          .catch((e) => { });
      }
    });
  };

  return (
    <>
      <Box justifyContent="flex-end" className="memberSearchBox custmFlex gap-2">
        <SearchInput
          defaultValue={query}
          onChange={handleSearch}
          placeholder="By Name, City"
        />
        <Button
          sx={{ whiteSpace: "nowrap", minWidth: 'auto' }}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setState({ open: true })}
        >
          Add service Location
        </Button>
      </Box>
      <Box sx={{ overflow: "auto" }}>
        <Table size="medium" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>City</TableCell>
              <TableCell>Enabled</TableCell>
              <TableCell align="right" sx={{ paddingRight: 14 }} >Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map((serviceLocation, index) => (
              <ServiceLocationRow
                key={serviceLocation.id}
                rowNumber={(meta.current_page - 1) * meta.per_page + index + 1}
                data={serviceLocation}
                onDelete={onDeleteHandler}
                onEdit={(data) => setState({ open: true, serviceLocation: data })}
              />
            ))}
            {!results.length && (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  {loading ? "Loading ..." : "No data"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {results.length > 0 && (
            <TableFooterWithPagination
              count={meta.total}
              rowsPerPage={meta.per_page}
              page={meta.current_page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
          <ServiceLocationManagementDialog
            serviceLocation={state.serviceLocation}
            open={state.open}
            onClose={() => setState({ open: false, serviceLocation: null })}
          />
        </Table>
      </Box>
    </>
  );
}
