import {
  getQuickBooksAuthorizationCodeURLRequest,
  getQuickBooksRefreshTokenStatusRequest,
  quickBooksConnectCallbackRequest
} from "../api/quickBooks";

const useQuickBooks = () => {

  const quickBooksConnectCallback = (data) => {
    return quickBooksConnectCallbackRequest(data);
  };

  const getQuickBooksRefreshTokenStatus = () => {
    return getQuickBooksRefreshTokenStatusRequest();
  };

  const getQuickBooksAuthorizationCodeURL = () => {
    return getQuickBooksAuthorizationCodeURLRequest();
  };

  return {
    quickBooksConnectCallback,
    getQuickBooksRefreshTokenStatus,
    getQuickBooksAuthorizationCodeURL,
  };
};

export default useQuickBooks;
