import React from "react";
import PropTypes from "prop-types";
import DateRangePicker from "@mui/lab/DateRangePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import FormField from "components/FormField";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

const DateRange = ({ value, onChange, calendars }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateRangePicker
        calendars={calendars}
        value={value}
        onChange={(newValue) => {
          onChange(newValue);
        }}
        renderInput={(startProps, endProps) => (
          <React.Fragment>
            <FormField
              {...startProps}
              autoComplete="off"
              type="date"
              label="Start Date"
              color="secondary"
              sx={{ marginLeft: 5, height: "10px" }}
              InputProps={{
                endAdornment: <CalendarTodayIcon fontSize="small" />,
              }}
            />
            <FormField
              {...endProps}
              autoComplete="off"
              type="date"
              label="End Date"
              color="secondary"
              sx={{ marginLeft: 2 }}
              InputProps={{
                endAdornment: <CalendarTodayIcon fontSize="small" />,
              }}
            />
          </React.Fragment>
        )}
      />
    </LocalizationProvider>
  );
};

DateRange.defaultProps = {
  calendars: 1
}

DateRange.propTypes = {
  calendars: PropTypes.number
}

export default DateRange;
