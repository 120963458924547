import React from "react";
import { Typography, Box, Divider, Chip, Checkbox } from "@mui/material";

const MembershipComponent = ({ membership }) => {
  const setStatusColor = (status) => {
    let color = "success";
    if (status === "ACTIVE") color = "success";
    if (status === "PENDING") color = "warning";
    if (status === "DECLINED") color = "error";

    return color;
  };
  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        py={0.5}
				px={1}
      >
        <Typography variant="body2">Membership type</Typography>
        <Typography variant="body2">{membership.membership_type}</Typography>
      </Box>
      <Divider />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        py={0.5}
				px={1}
      >
        <Typography variant="body2">Status</Typography>
        <Chip
          label={membership.status}
          size="small"
          color={setStatusColor(membership.status)}
        />
      </Box>
      <Divider />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
				py={0.5}
				px={1}
      >
        <Typography variant="body2">Payment method</Typography>
        <Typography variant="body2">
          {membership.payment_method === "STRIPE" ? "Stripe" : "Check"}
        </Typography>
      </Box>
      <Divider />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        py={0.5}
				px={1}
      >
        <Typography variant="body2">Mortgage holder</Typography>
        <Typography variant="body2">{membership.mortgage_holder}</Typography>
      </Box>
      <Divider />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
				py={0.5}
				px={1}
      >
        <Typography variant="body2">Subscription type</Typography>
        <Typography variant="body2">
          {membership.subscription_type === "single_year"
            ? "Single year"
            : `Multiple year(${membership.subscription_no_years})`}
        </Typography>
      </Box>
      <Divider />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        py={0.5}
				px={1}
      >
        <Typography variant="body2">Branch</Typography>
        <Typography variant="body2">{membership.branch}</Typography>
      </Box>
      <Divider />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        py={0.5}
				px={1}
      >
        <Typography variant="body2">Years in home</Typography>
        <Typography variant="body2">{membership.years_in_home}</Typography>
      </Box>
      <Divider />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
				py={0.5}
				px={1}
      >
        <Typography variant="body2">Paperless communication</Typography>
        <Checkbox
          color="success"
          checked={membership.paperless_communication}
          sx={{ p: 0 }}
        />
      </Box>
    </Box>
  );
};

export default MembershipComponent;
