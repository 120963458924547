import {
  SEARCH_VENDORS_LOADING,
  SEARCH_VENDORS_RESULTS,
  SEARCH_VENDORS,
  CHANGE_PAGE,
  CHANGE_PER_PAGE,
} from "redux/actions/vendors";

const initialState = {
  timestamp: null,
  query: "",
  loading: false,
  results: [],
  meta: {
    current_page: 1,
    from: null,
    last_page: 1,
    per_page: 10,
    to: null,
    total: 0,
  },
};

export default function vendors(state = initialState, action = {}) {
  switch (action.type) {
    case SEARCH_VENDORS_RESULTS: {
      return {
        ...state,
        results: action.results.result,
        meta: action.results.meta,
        loading: false,
      };
    }

    case SEARCH_VENDORS_LOADING:
      return { ...state, results: [], loading: action.loading };
    case SEARCH_VENDORS:
      return { ...state, results: [], query: action.query, loading: true };
    case CHANGE_PAGE:
      return {
        ...state,
        meta: {
          ...state.meta,
          current_page: action.page,
        },
      };
    case CHANGE_PER_PAGE:
      return {
        ...state,
        meta: {
          ...state.meta,
          per_page: action.perPage,
        },
      };
    default:
      return state;
  }
}
