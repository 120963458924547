import { BATCH_LOADING, BATCH_SET_PENDING } from "../actions";
const initialState = {
  loading: false,
  pending: 0,
  processed: 0,
  completed: 0,
  total: 0,
  batch_id: null,
  data: [],
  search: "",
};

export default function batch(state = initialState, action = {}) {
  switch (action.type) {
    case BATCH_SET_PENDING:
      return {
        ...state,
        loading: false,
        pending: action.pending,
        processed: action.processed,
        total: action.total,
        batch_id: action.batch_id,
        completed: action.completed,
      };
    case BATCH_LOADING:
      return { ...state, loading: action.loading };
    default:
      return state;
  }
}
