import React, { useEffect, useState } from "react";
import useAuth from "hooks/useAuth";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress, Typography } from "@mui/material";
import Broadcasting from "components/Broadcasting";
import RcProvider from "../../WebR/system/Provider";
const Authenticated = ({ children }) => {
  const { userData, user } = useAuth();
  const navigate = useNavigate();
  const [pageLoaded, setPageLoaded] = useState(false);
  useEffect(() => {
    if (Cookies.get("access_token")) {
      if (!user.loggedIn) {
        userData(() => setPageLoaded(true));
      } else {
        setPageLoaded(true);
      }
    } else {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.loggedIn]);

  if (pageLoaded) {
    return (
      <RcProvider>
        <Broadcasting.Provider>{children}</Broadcasting.Provider>
      </RcProvider>
    );
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={"100vh"}
    >
      <Box textAlign="center">
        <CircularProgress color="primary" size={40} />
        <Typography pt={2} variant="caption" component="p">
          Please wait...
        </Typography>
      </Box>
    </Box>
  );
};

export default Authenticated;
