import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

import { Typography, Button, Chip } from "@mui/material";
import { invoiceStatusColors } from "utils/statusColors";
import { useNavigate } from "react-router-dom";

import columns from "./tableData";
import useStyles from "./styles";
import useAccessControl from "hooks/useAccessControl";

const Invoices = ({ invoices, openView }) => {
  const classes = useStyles();
  const { canViewInvoice } = useAccessControl();
  const [invoicesData, setInvoicesData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setInvoicesData(invoices);
  }, [invoices]);

  const handleOpen = (row) => {
    if (row.status === "PAID") {
      navigate(`/app/invoices/${row.id}`);
    } else {
      navigate(`/app/members/checkout/${row.uuid}`);
    }
  };

  const renderStatus = (data) => {
    return (
      // <Typography
      //   style={{ backgroundColor: invoiceStatusColors[data.row.status] }}
      //   className={classes.status}
      // >
      //   {data.row.status}
      // </Typography>
      <Chip
      sx={{
        background: invoiceStatusColors[data.row.status],
        color: "white",
      }}
      label={data.row.status}
    />
    );
  };

  const renderAction = (data) => (
    <Button size="small" onClick={() => handleOpen(data.row)}>
      {data.row.status === "PAID" ? (canViewInvoice() && "View") : "Pay Now"}
    </Button>
  );

  return (
    <React.Fragment>
      <DataGrid
        rows={invoicesData}
        columns={columns(renderStatus, renderAction)}
        disableSelectionOnClick
        autoHeight
        className={classes.dataGrid}
        density="compact"
      />
    </React.Fragment>
  );
};

export default Invoices;
