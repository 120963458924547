import React from "react";
import { Grid, FormControlLabel, Checkbox } from "@mui/material";
import HeadingLine from "components/HeadingLine";
import FormField from "components/FormField";
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles(() => ({
  signature: {
    "& input": {
      fontFamily: "Open Sans, sans-serif;",
    },
    "& input::placeholder": {
      fontFamily: "Open Sans, sans-serif;",
    },
  },
}));
const Signature = ({ setForm, form, loggedIn }) => {
  const classes = useStyle();
  const handleChange = (e) => {
    const _membership = { ...form.membership };
    _membership[e.target.name] =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setForm({ membership: _membership });
  };

  const { membership } = form;

  return (
    <>
      <Grid item md={12} sm={12} xs={12}>
        <HeadingLine title="Communications &amp; Disclosures" />
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              name="paperless_communication"
              onChange={handleChange}
              checked={membership.paperless_communication}
            />
          }
          label={"Opt into paperless communications"}
        />
        <br />
        {!loggedIn && (
          <FormControlLabel
            control={
              <Checkbox
                required
                color="secondary"
                name="terms_acceptance"
                onChange={handleChange}
                checked={membership.terms_acceptance}
              />
            }
            label={
              "I have read and accept the Club's Terms, Conditions, Disclosures & Processing Fees."
            }
          />
        )}
      </Grid>
      <Grid item md={4} sm={12} xs={12}>
        <FormField
          label="Signature"
          name="signature"
          classes={{ root: classes.signature }}
          onChange={handleChange}
          required
          value={membership.signature.trim()}
        />
      </Grid>
      <Grid item md={4} sm={12} xs={12}>
        <FormField
          label="Date"
          type="date"
          name="date"
          onChange={handleChange}
          value={membership.date}
          disabled
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
    </>
  );
};

export default Signature;
