import {
  AUTH,
  UPDATE_USER_DATA,
  UPDATE_USER_PERMISSIONS,
  UPDATE_MFA_STATE,
  MFA_OTP
} from "redux/actions";

const initialState = {
  loggedIn: false,
  mfa: false,
  uuid: null,
  permissions: [],
  data: {},
  otp: null
};

export default function Auth(state = initialState, action = {}) {
  switch (action.type) {
    case AUTH:
      const newState = { ...state };
      newState.loggedIn = true;
      newState.permissions = action.permissions;
      newState.data = action.user;
      return newState;
    case UPDATE_USER_PERMISSIONS: {
      const newState = { ...state };
      newState.permissions = action.permissions;
      return newState;
    }
    case UPDATE_USER_DATA: {
      const newState = { ...state };
      if (newState.data.id === action.user.id) {
        newState.data = action.user;
      }
      return newState;
    }
    case UPDATE_MFA_STATE: {
      const newState = { ...state };
      newState.mfa = action.mfa;
      newState.uuid = action.uuid;
      newState.forgotPassToken = action.token;
      return newState;
    }
    case MFA_OTP: {
      const newState = { ...state };
      newState.otp = action.otp;
      return newState;
    }
    default:
      return state;
  }
}
