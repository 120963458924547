import React from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import brand from "theme/brand";
import MainMenu from "./Menu.js";
import useStyles from "./Sidebar-jss";
import { Avatar, Badge, Box, Menu, MenuItem, Typography } from "@mui/material";
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import Fade from '@mui/material/Fade';
import useWebr from "../../../../WebR/hooks/useWebr.js";
import Toast from "components/Toast";
import useAuth from "hooks/useAuth";
import { useRcState } from "WebR/system/Provider.js";

const SidebarContent = ({ app }) => {
  const classes = useStyles();
  const { initialize,unregister} = useWebr();
  const { user } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { callService} = useRcState();

  const handleClick = (event) => {
    if ("101") {
      setAnchorEl(event.currentTarget);
    } 
    // else {
    //    Toast.fire({
    //       title: "You account does not support calls. Please contact your administrator.",
    //       icon: "error",
    //     });
    // }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  

  const [status, setStatus] = React.useState("offline");

  const handleStatus = (status) => {
    setStatus(status);
    if (status === "online") {
      initialize(
      {
        name: `${user.data.first_name} ${user.data.last_name}`,
        username: "+19294732631",
        extension: user.data.extension,
        password: user.data.rc_pass,
      },
      () => {}
    );
    } else {
      unregister();
    }
    handleClose();
  };

  const getStatusColor = () => {
    if (callService.states === "online") {
      return status === "online" ? "success" : "error";
    }
    return "black";
  };

  const getTitle = () => {
    if (callService.states === "online") {
      return status === "online" ? "You are  receiving calls" : "You are  not receiving calls";
    }
    // return "You account does not support calls";
  };

  return (
    <div className={classes.drawerInner}>
      <div className={classes.drawerHeader}>
        <NavLink to="/app" className={classes.brand}>
          <img src={brand.logo} alt={brand.name} />
        </NavLink>
      </div>
      <Box pt={2} pl={2} pr={2}>
        <Box display="flex" >
          <Badge
            title={getTitle()}
            sx={{ cursor: 'pointer' }} 
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            onClick={handleClick}
            overlap="circular"
            badgeContent={<ArrowDropDownCircleIcon color={getStatusColor()} />}
            variant="standard"
          >
            <Avatar style={{ background: user.data.bgColor }}>{user.data.first_name[0]} {user.data.last_name[0]}</Avatar>
          </Badge>
          <Box pl={2}>
            <Typography fontWeight="bold">{user.data.first_name} {user.data.last_name}</Typography>
            <Typography>1(929) 473 2631</Typography>
          </Box>
        </Box>
      </Box>
       <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {status === "offline" && <MenuItem onClick={() => handleStatus('online')}>online</MenuItem>}
      </Menu>
      <div
        id="sidebar"
        className={classNames(classes.menuContainer, classes.rounded)}
      >
        <MainMenu app={app} />
      </div>
    </div>
  );
};

export default SidebarContent;
