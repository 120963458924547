export const LOGIN_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/auth/login`;
export const LOGOUT_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/auth/logout`;
export const FORGOT_PASSWORD_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/forgot-password`;
export const RESET_PASSWORD_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/reset-password`;
export const RESEND_MFA_OTP = `${process.env.REACT_APP_API_DOMAIN}/api/v1/auth/resend-mfa-code`;

export const VALIDATE_MFA_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/auth/mfa-validate`;

export const PERSONAL_DATA_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/users/me`;

export const CHECK_ZIPCODE_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/utils/valid-zip`;

export const MEMBERSHIP_TYPES_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/utils/membership-types`;

export const DELETED_MEMBER = `${process.env.REACT_APP_API_DOMAIN}/api/v1/membership/deleted-member`;

export const DELETED_MEMBERT = `${process.env.REACT_APP_API_DOMAIN}/api/v1/membership/delete/id`;
export const DELETED_MEMBERTV = `${process.env.REACT_APP_API_DOMAIN}/api/v1/membership/restore-deleted/id`;


export const SEARCH_ZIP_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/utils/search-zip-details`;

export const DEFAULTS_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/utils/defaults`;

export const CITIES_BY_STATE_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/utils/cities-by-state`;

export const STATES_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/utils/states`;

export const SERVICE_LOCATIONS_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/utils/service-locations`;

export const GET_USERS_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/users`;

export const REGISTER_USER_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/register`;

export const USER_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/user`;

export const CALCULATE_PRICE_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/payment/calculate-price`;

export const ASSIGN_TASK_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/tasks/assign`;

export const VIEW_INVOICE_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/payments/checkout`;
export const SETUP_INTENT_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/payments/intent`;
export const PAY_INVOICE_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/payments/pay-invoice`;

export const SEARCH_CONTRACTOR_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractors`;
export const VENDORS_SEARCH_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/vendors`;

// NOTES ROUTES
export const CREATE_NOTE_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/notes/create`;
export const GET_NOTES_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/notes/index`;
export const UPDATE_NOTE_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/notes/update`;
export const DELETE_NOTE_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/notes`;

// FILE MANAGER
export const VIEW_FILE_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/files/delete-multiple`;
export const GET_FILES_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/files/delete-multiple`;
export const DELETE_FILE_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/files`;
export const DELETE_FILES_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/files/delete-multiple`;
export const CREATE_DIRECTORY_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/files/create-directory`;
export const RENAME_FILE_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/files/rename`;

export const DOWNLOAD_FILES_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/files/delete-multiple`;
export const DOWNLOAD_FILE_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/files/delete-multiple`;


export const GET_CALL_LOG = `${process.env.REACT_APP_API_DOMAIN}/api/v1/ring-central/user-call-record`;
export const GET_CALL_LOG_VOICE_MAIL = `${process.env.REACT_APP_API_DOMAIN}/api/v1/calls/voicemail`;
export const GET_ALLCALL_LOG = `${process.env.REACT_APP_API_DOMAIN}/api/v1/ring-central/all-call-record`;
export const SEND_MSG_REQ = `${process.env.REACT_APP_API_DOMAIN}/api/v1/ring-central/sms`;
export const presence = `${process.env.REACT_APP_API_DOMAIN}/api/v1/ring-central/presence-user`;
export const Create_Ext = `${process.env.REACT_APP_API_DOMAIN}/api/v1/ring-central/create-extention`;
export const Bringpresence = `${process.env.REACT_APP_API_DOMAIN}/api/v1/ring-central/create-extention`
export const Delete_Ext = `${process.env.REACT_APP_API_DOMAIN}/api/v1/ring-central/delete-extension`;
export const GET_VOICEMAIL = `${process.env.REACT_APP_API_DOMAIN}/api/v1/calls/voicemail`;


