import React, { useEffect, useState } from "react";
import {
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  FormControl,
} from "@mui/material";
import HeadingLine from "components/HeadingLine";
import FormField from "components/FormField";
import { calculatePriceRequest } from "api/utilities";

const PaymentInformation = ({ setForm, form }) => {
  const [cost, setCost] = useState(null);
  const [costYear, setCostPerYear] = useState(null);
  const [loadingPrice, setLoadingPrice] = useState(false);

  const handleChange = (e) => {
     const inputValue = e.target.value;
     if (!isNaN(inputValue) && parseFloat(inputValue) > 0) {
      const _membership = { ...form.membership };
      _membership[e.target.name] = e.target.value;
      setForm({ membership: _membership });
    }
  };
  

  const handleChangeSubType = (e) => {
    const _membership = { ...form.membership };
    _membership[e.target.name] = e.target.value;
    _membership.subscription_no_years = 1;
    setForm({ membership: _membership });
  };
  const { membership, properties } = form;

  useEffect(() => {
    if (membership.membership_type) {
      setLoadingPrice(true);
      calculatePriceRequest({
        additional_properties: properties.length - 1,
        membership_type: membership.membership_type,
        number_of_years: membership.subscription_no_years,
      })
        .then((res) => {
          setCost(res.data.price.total_cost_usd / 100);
          setCostPerYear(res.data.price.membership_year_price / 100);
          setLoadingPrice(false);
        })
        .catch(() => {
          setLoadingPrice(false);
        });
    }
  }, [
    properties.length,
    membership.membership_type,
    membership.subscription_no_years,
  ]);

  return (
    <>
      <Grid item md={12} sm={12} xs={12}>
        <HeadingLine title="Memberships Dues:" />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">
          Membership in the Club costs just ${costYear} per year. Additional
          residential properties you own may be included for $10 each.
        </Typography>
      </Grid>
      <Grid item md={6} sm={12} xs={12}>
        <FormControl>
          <RadioGroup
            name="subscription_type"
            value={membership.subscription_type}
            onChange={handleChangeSubType}
          >
            <FormControlLabel
              value="single_year"
              control={<Radio color="primary" size="small" />}
              label={"Single year subscription"}
            />
            <FormControlLabel
              value="multiple_years"
              control={<Radio color="primary" size="small" />}
              label={"Multiple year subscription"}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {membership.subscription_type === "multiple_years" && (
        <Grid item md={6} sm={12} xs={12}>
          <FormField
            //InputLabelProps={{ shrink: true }}
            InputLabelProps={{ shrink: true,inputProps: { min: 0, max: 10 } }}
            label="Number of years"
            type="number"
            //min={0}
            value={membership.subscription_no_years}
            name="subscription_no_years"
            onChange={handleChange}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        {!loadingPrice && cost && (
          <Typography variant="subtitle2" color="success.main">
            Your total membership dues are ${cost.toFixed(2)}
          </Typography>
        )}
        {loadingPrice && (
          <Typography variant="subtitle2">
            Calculating membership price ...
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <RadioGroup
            name="payment_method"
            value={membership.payment_method}
            onChange={handleChange}
          >
            <FormControlLabel
              value="STRIPE"
              control={<Radio color="primary" size="small" />}
              label={"Pay by Credit Card"}
            />
            <FormControlLabel
              value="CHECK"
              control={<Radio color="primary" size="small" />}
              label={"Pay by Check"}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
    </>
  );
};

export default PaymentInformation;
