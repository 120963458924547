import React, { useState, useEffect } from "react";
import Loading from "@mui/material/LinearProgress";
import { create } from "jss";
import { StylesProvider, jssPreset } from "@mui/styles";
import { ThemeProvider } from "@mui/material/styles";
import rtl from "jss-rtl";
import { createTheme } from "@mui/material/styles";
import appTheme from "theme/appTheme";
import useStyles from "./styles-jss";
import { ThemeContext } from "theme/ThemeContext";
import useApplication from "hooks/useApplication";
import "utils/i18n";

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

function ThemeWrapper({ children }) {
  const { app, changeMode } = useApplication();
  const { color, mode } = app;
  const [progress, setProgress] = useState(0);
  const [theme, setTheme] = useState(createTheme(appTheme(color, mode)));

  const classes = useStyles();

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
        }
        const diff = Math.random() * 40;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleChangeMode = (mode) => {
    setTheme(createTheme(appTheme(color, mode)));
    changeMode(mode);
  };

  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <Loading
            variant="determinate"
            value={progress}
            style={{ display: progress >= 100 ? "none" : "block" }}
            classes={{
              root: classes.loading,
              colorPrimary: classes.loadingWrap,
              barColorPrimary: classes.bar,
            }}
          />
          <ThemeContext.Provider value={handleChangeMode}>
            {children}
          </ThemeContext.Provider>
        </div>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default ThemeWrapper;
