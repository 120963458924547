const columns = (renderStatus, renderAction) => [
  {
    field: "id",
    headerName: "Job#",
    sortable: true,
    type: "string",
    flex: 1,
  },
  {
    field: "name",
    headerName: "Job Category",
    sortable: true,
    flex: 2,
  },
  {
    field: "date",
    headerName: "Created",
    type: "date",
    editable: false,
    sortable: true,
    flex: 1,
  },
  {
    field: "contractor",
    headerName: "Contractor",
    editable: false,
    type: "string",
    sortable: true,
    flex: 1,
  },
  {
    field: "status",
    headerName: "Status",
    type: "string",
    flex: 1.2,
    renderCell: renderStatus,
  },
  {
    field: "dateRecorded",
    headerName: "Date Rec",
    type: "date",
    editable: false,
    sortable: true,
    flex: 1.5,
  },
  {
    field: "dateInv",
    headerName: "Date Inv",
    type: "date",
    editable: false,
    sortable: true,
    flex: 1,
  },
  {
    field: "amount",
    headerName: "Amount",
    type: "integer",
    editable: false,
    sortable: true,
    flex: 1,
  },
  {
    field: "invoice",
    headerName: "Inv #",
    type: "string",
    editable: false,
    sortable: true,
    flex: 1,
  },
  {
    field: "action",
    headerName: "Action",
    type: "string",
    flex: 0.7,
    sortable: false,
    renderCell: renderAction,
  },
];

export default columns;
