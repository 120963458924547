import { useImperativeHandle, forwardRef, useState } from "react";
import { Popover } from "@mui/material";

const ErrorPopover = forwardRef(({ children }, ref) => {
  useImperativeHandle(ref, () => ({
    open: (event) => handlePopoverOpen(event),
    close: () => handlePopoverClose(),
  }));

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Popover
      id="mouse-over-popover"
      sx={{
        pointerEvents: "none",
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      {children}
    </Popover>
  );
});

export default ErrorPopover;
