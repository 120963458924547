import React from "react";

import { Box, Button, Grid, Typography } from "@mui/material";

import TaskStatus from "./TaskStatus";
import { Call } from "@mui/icons-material";
import useSystem from "WebR/hooks/useSystem";
import { displayAsPhone } from "utils/functions";


const MissedCallTask = ({ data, handleChange, handleClose }) => {
  const { call } = useSystem();

  const callMember = () => {
    call(data.taskable.phone_number,  "+1");
    handleClose();
  }
  return (
    <Grid container spacing={2}>
      <Grid item sm={8} md={9}>
        <Typography variant="body2">
          <Typography variant="body2">
            You missed a call! Please call back or close this task.
          </Typography>
          <Typography variant="body2">
            <p>Number: {displayAsPhone(data.taskable.phone_number)}</p>
            <p>Call Type: {data.taskable.type}</p>
            <p>Call Status: {data.taskable.status}</p>
          </Typography>
          <Box display="flex" justifyContent="center">
            <Button onClick={callMember} variant="contained" color="success">
              <Call /> Call Back
            </Button>
          </Box>
        </Typography>
      </Grid>
      <Grid item sm={4} md={3}>
        <TaskStatus data={data} handleChange={handleChange} />
      </Grid>
      
    </Grid>
  );
};

export default MissedCallTask