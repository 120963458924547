import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import root, { createReducer } from "./reducers";
import members from "./reducers/admin/members";

const store = createStore(root, applyMiddleware(thunk));

export const injectMemberReducers = (store) => {
  store.others = members;
  store.replaceReducer(createReducer({ others: store.members }));
};

export const injectAdminReducers = (store) => {
  store.members = members;
  store.replaceReducer(createReducer({ members: store.members }));
};

export default store;
