import axios from "utils/axios";

const GET_DASH_TASKS = `${process.env.REACT_APP_API_DOMAIN}/api/v1/dashboard/my-tasks`;
const GET_DASH_LOGS = `${process.env.REACT_APP_API_DOMAIN}/api/v1/dashboard/logs`;
const GET_DASH_CONTACTS = `${process.env.REACT_APP_API_DOMAIN}/api/v1/dashboard/contacts`;
const GET_DASH_WIDGETS = `${process.env.REACT_APP_API_DOMAIN}/api/v1/dashboard/widgets`;
const GET_CALL_LOG = `${process.env.REACT_APP_API_DOMAIN}/api/v1/dashboard/call-log`;
const GET_ACTIVITY_TRACKING = `${process.env.REACT_APP_API_DOMAIN}/api/v1/dashboard/activity-tracking`;

export const getDashTasks = (params) => axios.post(GET_DASH_TASKS, params);
export const getDashLogs = () => axios.get(GET_DASH_LOGS);
export const getDashContacts = (parmas) =>
  axios.post(GET_DASH_CONTACTS, parmas);
export const getDashWidgets = (params) => axios.post(GET_DASH_WIDGETS, params);

export const getCallLog = (params) => axios.post(GET_CALL_LOG, params);
export const getActivityTrackingRequest = (params) => axios.post(GET_ACTIVITY_TRACKING, params);
