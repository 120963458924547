import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce";
import axios from "utils/axios";
import {
  getTasksRequest,
  changeTaskStatusRequest,
  changeTaskUserRequest,
  changePriority,
} from "api/tasks";
import {
  CHANGE_ESTIMATES_VALUES,
  ESTIMATES_RESET,
  CHANGE_TASK_STATUS,
  CHANGE_TASK_USER,
  QUEUE_SET_DATA,
  // SEARCH_TASK,
  CHANGE_TASK_VALUE,
  CHANGE_PAGE,
  CHANGE_PER_PAGE,
} from "redux/actions/taskActions";

const useQueue = () => {
  const dispatch = useDispatch();
  const queue = useSelector((state) => state.queue);
  const newEstimate = useSelector((state) => state.newEstimate);

  const getTasks = debounce((searchValue, dontLoad = false) => {
    // if (!dontLoad) {
    //   dispatch({ type: SEARCH_TASK, search: searchValue, perPage, page });
    // }
    getTasksRequest(searchValue).then((res) => {
      dispatch({ type: QUEUE_SET_DATA, response: res.data });
    });
    // if (callback) callback();
  }, 600);

  const changeTaskStatus = (task_id, status) => {
    dispatch({ type: CHANGE_TASK_STATUS, id: task_id, status });
    changeTaskStatusRequest({ id: task_id, status });
  };

  const changeTaskUser = (task_id, user_id) => {
    dispatch({ type: CHANGE_TASK_USER, id: task_id, user_id });
    changeTaskUserRequest({ id: task_id, user_id });
  };

  const setNewEstimate = (field, value) =>
    dispatch({ type: CHANGE_ESTIMATES_VALUES, field, value });

  const resetEstimates = () => dispatch({ type: ESTIMATES_RESET });

  const changeTask = (taskId, value) => {
    dispatch({ type: CHANGE_TASK_VALUE, id: taskId, field: "priority", value });
    changePriority(taskId, value);
  };

  const addCheckNumber = (taskId, data) =>
    axios.post(
      `${process.env.REACT_APP_API_DOMAIN}/api/v1/tasks/add-check-no/${taskId}`,
      data
    );

  const changePage = (newPage) => {
    dispatch({ type: CHANGE_PAGE, page: newPage + 1 });
  };
  const changePerPage = (event) => {
    dispatch({ type: CHANGE_PER_PAGE, perPage: parseInt(event.target.value) });
  };

  return {
    queue,
    newEstimate,
    getTasks,
    changeTaskStatus,
    changeTaskUser,
    setNewEstimate,
    resetEstimates,
    changeTask,
    addCheckNumber,
    changePage,
    changePerPage,
  };
};

export default useQueue;
