import React, { Fragment } from "react";
import { Drawer, Hidden, SwipeableDrawer } from "@mui/material";
import classNames from "classnames";
import useStyles from "./Sidebar-jss";
import SidebarContent from "./SidebarContent";

const Sidebar = ({ toggleDrawerOpen, app }) => {
  const classes = useStyles();
  return (
    <Fragment>
      <Hidden lgUp>
        <SwipeableDrawer
          onClose={toggleDrawerOpen}
          onOpen={toggleDrawerOpen}
          open={!app.sidebarOpen}
          anchor="left"
        >
          <div className={classes.swipeDrawerPaper}>
            <SidebarContent app={app} />
          </div>
        </SwipeableDrawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          variant="permanent"
          onClose={toggleDrawerOpen}
          className={app.sidebarOpen ? classes.drawer : ""}
          classes={{
            paper: classNames(
              classes.drawer,
              classes.drawerPaper,
              !app.sidebarOpen ? classes.drawerPaperClose : ""
            ),
          }}
          open={app.sidebarOpen}
          anchor={"left"}
        >
          <SidebarContent app={app} />
        </Drawer>
      </Hidden>
    </Fragment>
  );
};

export default Sidebar;
