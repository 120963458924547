import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  MenuItem,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio
} from "@mui/material";
import ".././../../../../../utils/sweetalert.css"
import LoadingButton from "@mui/lab/LoadingButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FormField from "components/FormField";
import ApiSelect from "components/ApiSelect";
import { membershipTypesRequest, searchZipDataRequest } from "api/utilities";
import CityPicker from "components/CityPicker";
import StatePicker from "components/StatePicker";
import useMembership from "hooks/useMembership";
import MembersListUpdate from "../MembersListUpdate";
import useAccessControl from "hooks/useAccessControl";
import HeadingLine from "components/HeadingLine";
import ServiceLocationPicker from "components/ServiceLocationPicker";
import { ShippingFields } from "pages/App/Batch/Process/components";


const UpdateMembershipDetailsValidationSchema = Yup.object().shape({
  status: Yup.string().nullable(),
  membership_type: Yup.string().required("This field is required!"),
  members: Yup.array().of(
    Yup.object().shape({
      id: Yup.number().nullable(),
      first_name: Yup.string().required("This field is required!"),
      last_name: Yup.string().required("This field is required!"),
      email: Yup.string().required("This field is required!"),
      home_phone: Yup.string().nullable(),
      cell_phone: Yup.string().required("This field is required!"),
      // work_phone: Yup.string().required("This field is required!"),
      work_phone: Yup.string().nullable(),
      work_phone_extension: Yup.string().nullable(),
      occupation: Yup.string().nullable(),
      company: Yup.string().nullable(),
    })).required("This field is required!"),
  date: Yup.date().nullable(),
  expires_at: Yup.date().nullable(),
  out_of_network: Yup.bool(),
  mailing_address: Yup.object({
    address: Yup.string().required("This field is required"),
    state: Yup.string().required(`This field is required`),
    zip: Yup.string().required(`This field is required`),
    city: Yup.object().required('This field is required').nullable()
  }),
});

const MembershipDetails = ({ form, setForm, addMember }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { updateMembership } = useMembership();
  const { canUpdateMember, canManageServiceLocationUser } = useAccessControl();
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleChangeShipping = (data) => {
    const _shipping = { ...form.shipping, ...data };
    // setForm({ shipping:  _shipping  });
    formik.setFieldValue('shipping', _shipping);
  };

  const formik = useFormik({
    initialValues: {
      id: form.membership.id,
      members: form.members,
      shipping: form.shipping,
      status: form.membership.status,
      membership_type: form.membership.membership_type,
      service_location_id: form.membership.service_location.id,
      service_location: form.membership.service_location,
      date: form.membership.date,
      membership_card: form.membership.membership_card,
      newsletter: form.membership.newsletter,
      communications: form.membership.communications,
      invoice_preference: form.membership.invoice_preference,
      expires_at: form.membership.expires_at,
      mailing_address: form.mailing_address,
      out_of_network: form.membership.out_of_network,
    },
    validationSchema: UpdateMembershipDetailsValidationSchema,
    onSubmit: async (values) => {
      await updateMembership(values).then(({ data }) => {
        setForm(data);
        Swal.fire({
          title: "Success",
          text: "Membership successfully updated!",
          customClass: {
            title: "swal-text",
            popup:"swal-text"
            
          },
          confirmButtonColor: theme.palette.primary.main,
        });
      });
    },
  });

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      shipping: form.shipping,
      status: form.membership.status,
      membership_card: form.membership.membership_card,
      newsletter: form.membership.newsletter,
      communications: form.membership.communications,
      invoice_preference: form.membership.invoice_preference,
      members: form.members,
      service_location_id: form.membership.service_location.id,
      service_location: {
        id: form.membership.service_location.id,
        label: form.membership.service_location.name
      },
      mailing_address: form.mailing_address,
      membership_type: form.membership.membership_type,
      date: form.membership.date,
      expires_at: form.membership.expires_at,
      out_of_network: form.membership.out_of_network,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getColor = (status) => {
    switch (status) {
      case "ACTIVE":
        return "lightgreen";
      case "PENDING":
        return "orange";
      case "EXPIRED":
        return "red";
      default:
        return "gray";
    }
  };

  const handleZipChange = (e) => {
    searchZipDataRequest(e.target.value)
      .then(({ data }) => {
        formik.setValues({
          ...formik.values,
          mailing_address: {
            ...formik.values.mailing_address,
            state: data.details.state.code,
            zip: data.details.zip,
            city: {
              id: data.details.city.id,
              label: data.details.city.city,
            }
          }
        }).then(() => {
          formik.setErrors({ ...formik.errors, zip: undefined});
        })
      })
      .catch((error) => {
        formik.setValues({
          ...formik.values,
          mailing_address: {
            ...formik.values.mailing_address,
            state: '',
            city: null
          },
        }).then(() => {
          if (error?.response?.data?.errors) {
            Object.keys(error.response.data.errors).forEach((field) => {
              if (error.response.data.errors[field][0] !== undefined)
                formik.setFieldError(`mailing_address.${field}`, error.response.data.errors[field][0]);
            })
          }
        })
      });
  };

  return (
    <React.Fragment>
      <Grid container width="100%" sx={{ marginTop: 1 }}>
        <form style={{ width: "100%" }} onSubmit={handleSubmit}>
          <Grid container item spacing={2} mb={2}>
            <Grid item md={2} xs={12} lg={2}>
              <FormField
                variant="outlined"
                name="status"
                value={formik.values.status}
                onChange={(e) => formik.setFieldValue('status', e.target.value)}
                select
                sx={{
                  "& fieldset": {
                    borderColor: getColor(formik.values.status),
                  },
                }}
                label={t("memberDirectory:member_status")}
                size="small"
                fullWidth
              >
                <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                <MenuItem value="PENDING">PENDING</MenuItem>
                <MenuItem value="EXPIRED">EXPIRED</MenuItem>
                <MenuItem value="INACTIVE">INACTIVE</MenuItem>
                <MenuItem value="BLACKLISTED">BLACKLISTED</MenuItem>
              </FormField>
            </Grid>
            <Grid item md={2} xs={12} lg={2}>
              <ApiSelect
                inputProps={{
                  error: Boolean(formik.errors.membership_type),
                  helperText: formik.errors.membership_type,
                  required: true,
                  label: "Property Type",
                  value: formik.values.membership_type,
                  onChange: (e) => formik.setFieldValue('membership_type', e.target.value)
                }}
                source={membershipTypesRequest}
                reducer="membership_type"
              />
            </Grid>
            <Grid item md={2} xs={12} lg={2}>
              <FormField
                disabled
                label={t("memberDirectory:dues_rate")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {formik.values.membership_type}
                    </InputAdornment>
                  ),
                }}
                value={form.invoices.length ? form.invoices[0].amount / 100 : 0}
              />
            </Grid>
            <Grid item md={2} xs={12} lg={2}>
              <FormField
                label="Join Date"
                type="date"
                value={formik.values.date}
                onChange={(e) => formik.setFieldValue('date', e.target.value)}
                name="date"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={2} xs={12} lg={2}>
              <FormField
                label="Expiration Date"
                type="date"
                value={formik.values.expires_at}
                onChange={(e) => formik.setFieldValue('expires_at', e.target.value)}
                name="expires_at"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={2} xs={12} lg={2}>
              <FormField
                variant="outlined"
                label={t("memberDirectory:cr_terms")}
                disabled
                value="NET"
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} direction="row" spacing={2} mb={2}>
            <Grid item md={3} xs={12}>
              <FormField
                label="Mailing Address"
                required
                value={formik.values.mailing_address?.address}
                onChange={(e) => formik.setFieldValue('mailing_address.address', e.target.value)}
                name="address"
                error={Boolean(formik.errors.mailing_address?.address)}
                helperText={formik.errors.mailing_address?.address}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <CityPicker
                label="City"
                required
                InputLabelProps={{ shrink: true }}
                value={formik.values.mailing_address?.city}
                name="city"
                state_code={formik.values.mailing_address?.state}
                onChange={(val) => formik.setFieldValue('mailing_address.city', val)}
                error={Boolean(formik.errors.mailing_address?.city)}
                helperText={formik.errors.mailing_address?.city}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <StatePicker
                InputLabelProps={{ shrink: true }}
                required
                name="state"
                label="State"
                value={formik.values.mailing_address?.state}
                onChange={(e) => formik.setFieldValue('mailing_address.state', e.target.value)}
                error={Boolean(formik.errors.mailing_address?.state)}
                helperText={formik.errors.mailing_address?.state}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <FormField
                label="Zip Code"
                name="zip"
                required
                value={formik.values.mailing_address?.zip}
                onBlur={handleZipChange}
                error={Boolean(formik.errors.mailing_address?.zip)}
                helperText={formik.errors.mailing_address?.zip}
                onChange={(e) => formik.setFieldValue('mailing_address.zip', e.target.value)}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    name="out_of_network"
                    checked={formik.values.out_of_network}
                    onChange={(e) => formik.setFieldValue("out_of_network", e.target.checked)}
                  />
                }
                label="Out of network"
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12} mt={2}>
              <HeadingLine title="Communications Preferences" />
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={3}>
                  <FormControl>
                    <FormLabel id="radio-buttons-membership-card">Membership card</FormLabel>
                    <RadioGroup
                      aria-labelledby="radio-buttons-membership-card"
                      name="membership_card"
                      value={formik.values.membership_card}
                      onChange={(e) => formik.setFieldValue('membership_card', e.target.value)}
                    >
                      <FormControlLabel value="1" control={<Radio />} label="hard" />
                      <FormControlLabel value="2" control={<Radio />} label="digital" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl>
                    <FormLabel id="radio-buttons-newsletter">Newsletter</FormLabel>
                    <RadioGroup
                      aria-labelledby="radio-buttons-newsletter"
                      name="newsletter"
                      value={formik.values.newsletter}
                      onChange={(e) => formik.setFieldValue('newsletter', e.target.value)}
                    >
                      <FormControlLabel value="1" control={<Radio />} label="hard" />
                      <FormControlLabel value="2" control={<Radio />} label="digital" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl>
                    <FormLabel id="radio-buttons-communications">Communications</FormLabel>
                    <RadioGroup
                      aria-labelledby="radio-buttons-communications"
                      name="communications"
                      value={formik.values.communications}
                      onChange={(e) => formik.setFieldValue('communications', e.target.value)}
                    >
                      <FormControlLabel value="1" control={<Radio />} label="hard" />
                      <FormControlLabel value="2" control={<Radio />} label="digital" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl>
                    <FormLabel id="radio-buttons-invoice-preference">Invoice preference</FormLabel>
                    <RadioGroup
                      aria-labelledby="radio-buttons-invoice-preference"
                      name="invoice_preference"
                      value={formik.values.invoice_preference}
                      onChange={(e) => formik.setFieldValue('invoice_preference', e.target.value)}
                    >
                      <FormControlLabel value="1" control={<Radio />} label="hard" />
                      <FormControlLabel value="2" control={<Radio />} label="digital" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            {canManageServiceLocationUser() && (
              <Grid item md={12} sm={12} xs={12} mt={2}>
                <HeadingLine title="Service Location" />
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12} md={3}>
                    <ServiceLocationPicker
                      label="Service Location"
                      InputLabelProps={{ shrink: true }}
                      value={formik.values?.service_location}
                      name="service_location_id"
                      onChange={(val) => {
                        formik.setFieldValue('service_location', val);
                        formik.setFieldValue('service_location_id', val.id);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <MembersListUpdate
            members={formik.values.members}
            onChange={(values) => formik.setFieldValue('members', values)}
          />
          <Box mt={2}>
            <ShippingFields
              shippingData={formik.values?.shipping}
              withActions={true}
              onChange={handleChangeShipping}
            />
          </Box>
          {canUpdateMember() && (
          <Grid item md={12} xs={12}>
            <Box mb={2} display="flex" justifyContent="flex-end">
              <LoadingButton
                loading={formik.isSubmitting}
                loadingPosition="center"
                variant="contained"
                type="submit"
                // disabled={!formik.dirty}
                onClick={formik.submitForm}
              >
                Update
              </LoadingButton>
            </Box>
          </Grid>
          )}
        </form>
      </Grid>
    </React.Fragment>
  );
};

export default MembershipDetails;
