import { Box, MenuItem, Typography } from "@mui/material";
import FormField from "components/FormField";
import React from "react";

const Property = ({ property, properties, property_id, setState }) => {
  const handleChange = (e) => {
    // eslint-disable-next-line eqeqeq
    const prop = properties.findIndex((p) => p.id == e.target.value);
    setState({
      property_id: e.target.value,
      property: properties[prop],
    });
  };
  return (
    <>
      <FormField
        required
        label="Property Address"
        select
        value={property_id}
        onChange={handleChange}
      >
        {properties.map((prop, index) => (
          <MenuItem key={`property-${prop.id}`} value={prop.id}>
            Property {index + 1}
          </MenuItem>
        ))}
      </FormField>
      <Box pl={1} pb={2}>
        <Typography gutterBottom paddingTop={1} variant="body2">
          {property.address}
        </Typography>
        <Typography gutterBottom variant="body2">
          {property.city?.label}, {property.state} {property.zip}
        </Typography>
      </Box>
    </>
  );
};

export default Property;
