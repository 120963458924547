import React from 'react';
import {
  Box,
  Grid,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import Swal from 'sweetalert2';
import { useTheme } from "@mui/material/styles";
import LoadingButton from 'components/LoadingButton';
import useUser from 'hooks/useUser';
import ".././../utils/sweetalert.css"


const Settings = ({ user }) => {
  const { updateUser } = useUser();
  const theme = useTheme();
  const [defaultDateRange, setDefaultDateRange] = React.useState(user?.default_date_range);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleSubmit = () => {
    setIsSubmitting(true);
    updateUser({ default_date_range: defaultDateRange }, window.location.pathname === "/app/profile" ? null : user.uuid).then(() => {
      Swal.fire({
        title: "Success",
        text: "Settings successfully updated!",
        customClass: {
          title: "swal-text",
          popup:"swal-text"
          
        },
        confirmButtonColor: theme.palette.primary.main,
      });
    })
    .finally(() => {
      setIsSubmitting(false);
    });
  };

  return (
    <Box sx={{ width: { sx: "100%", md: "40%" } }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" fontWeight="bold" color="primary" sx={{ mb: 2 }}>
            Settings
          </Typography>
          <TextField
            id="select-billing-address"
            size="small"
            select
            fullWidth
            label="Default date ranges"
            value={defaultDateRange}
            onChange={(event) => setDefaultDateRange(event.target.value)}
          >
            {[1,7,15,30,60,90,365].map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </TextField>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
        </Grid>
        <Grid item xs={12} md={12}>
          <LoadingButton
            loading={isSubmitting}
            loadingPosition="center"
            type="submit"
            size="small"
            onClick={handleSubmit}
          >
            Update settings
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Settings;