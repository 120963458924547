import React from "react";
import InputMask from "react-input-mask";
import FormField from "components/FormField";
import InputAdornment from "@mui/material/InputAdornment";
import { Button } from "@mui/material";
import useSystem from "WebR/hooks/useSystem";
import { parsePhoneNo } from "utils/functions";//CallSystem viewComponent

const CallableField = ({ value, onChange, ...others }) => {
  const { data, call } = useSystem();

  const callContact = (member, number) => {
    let phone = parsePhoneNo(number);
  
    call(phone, "+1");
  };

  return (
    <InputMask value={value} onChange={onChange} mask="(+1) 999-999-9999">
      {() => (
        <FormField
          {...others}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {!data.state && (
                  <Button
                    onClick={() => callContact(null, value)}
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    Call
                  </Button>
                )}
              </InputAdornment>
            ),
          }}
        />
      )}
    </InputMask>
  );
};

export default CallableField;
