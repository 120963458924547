import React, { useState } from "react";
import { arrayMoveImmutable } from "array-move";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  FormHelperText,
  Grid,

} from "@mui/material";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import BillingTableRow from "./BillingTableRow";
import NewBillingRow from "./NewBillingRow";
import { border } from "@mui/system";
import useInvoices from "hooks/useInvoicePdf";
import useInvoicesPdfs from "hooks/useInvoicePdf";

// const SortableItem = sortableElement(
//   ({ service, number, deleteService, withActions }) => {
//     console.log('sssssss '+service);
//     return (
//       <BillingTableRow
//         service={service}
//         index={number}
//         onDelete={deleteService}
//         withActions={withActions}
//       />
//     );
//   }
// );

// const SortableList = sortableContainer(
//   ({
//     services,
//     deleteService,
//     discardNewService,
//     saveNewService,
//     withActions,
//     showAddService
//   }) => {
//     return (
//       <TableBody>
//         {services.map((service, index) => {
//           return Object.keys(service).length > 0 && (
//             <SortableItem
//               key={`service-item-${index}`}
//               index={index}
//               service={service}
//               number={index}
//               deleteService={deleteService}
//               withActions={withActions}
//             />);
//         })}
//         {showAddService && (
//           <NewBillingRow
//             discardNewService={discardNewService}
//             saveNewService={saveNewService}
//           />
//         )}
//       </TableBody>
//     );
//   }
// );

export default function BillingTableComponent({
  services = [],
  onChange,
  withActions,
  deleteService,
  deleteServices,
  saveNewService,
  error
}) {

  const [showAddService, setShowAddService] = useState(false);
  const onSortEnd = ({ oldIndex, newIndex }) => {
    onChange && onChange(arrayMoveImmutable(services, oldIndex, newIndex))
  };

  const handleSaveNewService = (data) => {
    saveNewService(data);
    setShowAddService(false);
  };

  return (
    <Box>
      {/* <Box>
        <Grid container spacing={2} sx={{ justifyContent: "center", alignItems: "center" }}>
          <Grid item xs={4}>
            <Box>
              <img src="http://localhost:3000/images/hoc-logo.png" width={296} height={188} alt="logo" />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="logoRIghtText">
              <p className="m-0">7525 SE 24th street, suite 408</p>
              <p className="m-0">po box 1760</p>
              <p className="m-0">Mercer Island, Wa 98040</p>
              <p className="m-0">206-622-3500</p>
            </Box>
          </Grid>

        </Grid>
        <Grid container spacing={2} className="mb-4">
          <Grid item xs={8}>
            <Box className="shipDetails">
              <p className="m-0">Acct#: <span>8014</span></p>
              <p className="m-0">James Hardisty</p>
              <p className="m-0">David Hardisty</p>
              <p className="m-0">5036 84th Ave SE</p>
              <p className="m-0">Mercer Island, WA 98040-4618</p>
            </Box>
            <p className="mt-2 mb-3">Please return this part of statement note any Changes in address or phone number.</p>
          </Grid>
          <Grid item xs={4}>
            <h4 style={{ textAlign: "center" }}>04/04/2024</h4>
            <Box className="logoRIghtText" sx={{ border: "1px solid #000" }}>
              <label className="bgDark-custm">BALANCE DUE</label>
              <span style={{ fontWeight: 600, padding: "6px", textAlign: "center", display: "block" }}>$645.19</span>
            </Box>
            <Box className="logoRIghtText" sx={{ border: "1px solid #000" }}>
              <label className="bgDark-custm">AMOUNT ENCLOSED</label>
              <span style={{ fontWeight: 600, padding: "6px", display: "block" }}>$</span>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table size="small" aria-label="simple table" sx={{border: "1px solid #000"}}>
                <TableHead >
                  <TableRow>
                    <TableCell sx={{background: "#000", color: "#fff"}}>DATE</TableCell>
                    <TableCell sx={{background: "#000", color: "#fff"}}>DESCRIPTION</TableCell>
                    <TableCell sx={{background: "#000", color: "#fff"}}>CHARGES</TableCell>
                    <TableCell sx={{background: "#000", color: "#fff"}}>CREDITS</TableCell>
                    <TableCell sx={{background: "#000", color: "#fff"}}>BALANCE</TableCell>
                    <TableCell sx={{background: "#000", color: "#fff"}}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>04/04/2024</TableCell>
                    <TableCell>454710</TableCell>
                    <TableCell>165.15</TableCell>
                    <TableCell>0.00</TableCell>
                    <TableCell sx={{whiteSpace: "nowrap"}}>165.15 Tree/Yard Spray</TableCell>
                    <TableCell rowSpan={10} sx={{verticalAlign: "middle", textAlign: "center"}}> Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>04/04/2024</TableCell>
                    <TableCell>454710</TableCell>
                    <TableCell>165.15</TableCell>
                    <TableCell>0.00</TableCell>
                    <TableCell sx={{whiteSpace: "nowrap"}}>165.15 Tree/Yard Spray</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>04/04/2024</TableCell>
                    <TableCell>454710</TableCell>
                    <TableCell>165.15</TableCell>
                    <TableCell>0.00</TableCell>
                    <TableCell sx={{whiteSpace: "nowrap"}}>165.15 Tree/Yard Spray</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{marginTop: "20px",textAlign:"center"}}>Please see reverse side for important information</Box>
            <Box sx={{textAlign: "center"}}><img src="http://localhost:3000/images/hoc-logo.png" width={296} height={188} alt="logo" /> <span style={{marginLeft: "10px"}}>PO BOX 1760, MERCER ISLAND, WA 98040 206-622-3500</span></Box>
          </Grid>
        </Grid>
      </Box> */}

      <table style={{ width: " 1000px", margin: " auto" }}>
        <tr>
          <td>
            <table style={{ width: "100%" }}>
              <tr>
                <td style={{ textAlign: "center" }}>
                  <img src="http://localhost:3000/images/hoc-logo.png" alt="" />
                </td>
                <td style={{ paddingLeft: "23px" }}>
                  <table>
                    <tr>
                      <td style={{ fontWeight: "600" }}>
                        7525 se 20th street, suite 408
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "600" }}>7525 se 20th</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "600" }}>7525 se 20th street, suite</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "600" }}>7525 se 20th</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td style={{ padding: "0px 80px" }}>
            <table style={{ width: "100%" }}>
              <tr>
                <td style={{ width: "70%" }}>
                  <table style={{ width: "100%", marginTop: "10px" }}>
                    <tr>
                      <td style={{ fontWeight: "600" }}>Acct #: 8014</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "600" }}>Acct #: 8014</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "600" }}>Acct #: 8014</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "600" }}>Acct #: 8014</td>
                    </tr>
                    <tr style={{ display: "block", marginTop: "20px" }}>
                      <td>
                        Please return this part of statment and note any change in
                        address or phone number.
                      </td>
                    </tr>
                  </table>
                </td>
                <td style={{ width: "30%" }}>
                  <table style={{ verticalAlign: "baseline", width: "100%" }}>
                    <tr>
                      <td style={{ fontWeight: "600", fontSize: "16px", textAlign: "center" }} >
                        4/4/2024
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table style={{ width: "100%", border: "1px solid" }}>
                          <tr>
                            <th style={{ background: "#000", color: "#fff", textAlign: "center" }}>
                              BALANCE DUE
                            </th>
                          </tr>
                          <tr>
                            <td style={{ height: "20px", fontWeight: "600", textAlign: "center", fontSize: "20px", padding: "7px" }} >
                              $654.19
                            </td>
                          </tr>
                          <tr>
                            <th style={{ background: "#000", color: "#fff", textAlign: "center" }}> AMOUNT ENCLOSED</th>
                          </tr>
                          <tr>
                            <td style={{ height: "20px", fontWeight: "600", textAlign: "center", fontSize: "20px", padding: "7px" }} >
                              $_______________
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td style={{ border: "1px solid ", height: " 300px", verticalAlign: "baseline" }}>
            <table style={{ width: "100%" }}>
              <tr
                style={{ background: "#000", color: "#fff", borderCollapse: "collapse" }}
              >
                <th>DATE</th>
                <th colspan="2">DESCRIPTION</th>
                <th>CHARGES</th>
                <th>CREDITS</th>
                <th colspan="3" style={{ width: "40%", textAlign: "justify" }}>
                  BALANCE
                </th>
              </tr>
              <tr>
                <td>3/12/2024</td>
                <td colspan="2">452456</td>
                <td>165.15</td>
                <td>0.00</td>
                <td colspan="3">165.15 Three/Year Spry</td>
              </tr>
              <tr>
                <td>3/12/2024</td>
                <td colspan="2">452456</td>
                <td>165.15</td>
                <td>0.00</td>
                <td colspan="3">165.15 Three/Year Spry</td>
              </tr>
              <tr>
                <td>3/12/2024</td>
                <td colspan="2">452456</td>
                <td>165.15</td>
                <td>0.00</td>
                <td colspan="3">165.15 Three/Year Spry</td>
              </tr>
              <tr>
                <td></td>
                <td colspan="2"></td>
                <td style={{ borderTop: "1px solid " }}>4544</td>
                <td style={{ bordertop: "1px solid" }}>0.00</td>
                <td colspan="3">
                  165.15 Three/Year Spry
                  <p style={{ margin: "0.5rem 0px 0px 7rem" }}>
                    Lorem ipsum dolor sit amet consectetur non, sapiente dicta
                    magni molestias iusto?
                  </p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table style={{ width: "100%" }}>
              <tr>
                <td colspan="2" style={{ textAlign: "center" }}>
                  <p style={{ margin: "0" }}>
                    Please see reverse side for important information
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "end" }}>
                  <img src="http://localhost:3000/images/hoc-logo.png" alt="" />
                </td>
                <td style={{ paddingLeft: "23px" }}>
                  <table>
                    <tr>
                      <td style={{ fontWeight: 600 }}>
                        PO BOX 1760, MEMRICER ISLAND WA 98040 + 206-622-3500
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td style={{ textAlign: "end" }}>
            <table style={{ width: "100%" }}>
              <tr>
                <td
                  style={{ fontSize: "30px", fontWeight: "600", letterSpacing: "2px" }}
                >
                  455602
                </td>
              </tr>
              <tr>
                <td style={{ paddingRight: " 17.5rem", marginBottom: "10px" }}><h3>117229</h3></td>
              </tr>
              <tr>
                <td colspan="2">
                  <table
                    style={{
                      border: "1px solid",
                      backgroundColor: "#eee",
                      float: "right",
                      borderCollapse: "collapse",
                      marginBottom: "15px"
                    }}
                  >
                    <tr>
                      <td style={{ border: "1px solid", paddingLeft: "15px", paddingRight: "15px" }}>INVOICE NO.</td>
                      <td style={{ border: "1px solid", paddingLeft: "15px", paddingRight: "15px" }}>TRACKING NO.</td>
                      <td style={{ border: "1px solid", paddingLeft: "15px", paddingRight: "15px" }}>CLUB NO.</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table style={{ width: "100%", marginBottom: "15px" }}>
              <tr>
                <td style={{ width: "50%" }}>
                  <table>
                    <tr>
                      <th>SERIVCE PERFORMANCE FOR.</th>
                    </tr>
                    <tr>
                      <td>
                        <span>MEMBER: </span>
                        <span>JAMES HADISTY</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>MEMBER: </span>
                        <span>JAMES HADISTY</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>MEMBER: </span>
                        <span>JAMES HADISTY</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>MEMBER: </span>
                        <span>JAMES HADISTY</span>
                      </td>
                    </tr>
                  </table>
                </td>
                <td style={{ width: "50%" }}>
                  <table>
                    <tr>
                      <th>SERIVCE PERFORMANCE FOR.</th>
                    </tr>
                    <tr>
                      <td>
                        <span>MEMBER: </span>
                        <span>JAMES HADISTY</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>MEMBER: </span>
                        <span>JAMES HADISTY</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>MEMBER: </span>
                        <span>JAMES HADISTY</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>MEMBER: </span>
                        <span>JAMES HADISTY</span>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table style={{ width: "100%", marginTop: "10px", border: "1px solid", borderCollapse: "collapse", marginBottom: "15px" }} >
              <tr style={{ backgroundColor: "#eee", color: "#000" }}>
                <th style={{ border: "1px solid", }}>MATRIAL</th>
                <th style={{ border: "1px solid", }}>AMOUNT</th>
                <th style={{ border: "1px solid", }}>DESCRIPTION OF WORK LABOR</th>
                <th style={{ border: "1px solid", }}>AMOUNT</th>
              </tr>
              <tr>
                <td style={{ border: "1px solid", }}></td>
                <td style={{ border: "1px solid", }}></td>
                <td style={{ border: "1px solid", }}>
                  DIAGNOSTIC ON NON-WORKING FURANCE; MAINTENACE ON 2ND FURANCE
                </td>
                <td style={{ border: "1px solid", }}>191.00</td>
              </tr>
              <tr>
                <td style={{ border: "1px solid", }}></td>
                <td style={{ border: "1px solid", }}></td>
                <td style={{ border: "1px solid", }}>
                  DIAGNOSTIC ON NON-WORKING FURANCE; MAINTENACE ON 2ND FURANCE
                </td>
                <td style={{ border: "1px solid", }}>191.00</td>
              </tr>
              <tr>
                <td style={{ border: "1px solid", }}></td>
                <td style={{ border: "1px solid", }}></td>
                <td style={{ border: "1px solid", }}>
                  DIAGNOSTIC ON NON-WORKING FURANCE; MAINTENACE ON 2ND FURANCE
                </td>
                <td style={{ border: "1px solid", }}>191.00</td>
              </tr>
              <tr>
                <td style={{ border: "1px solid", }}></td>
                <td style={{ border: "1px solid", }}></td>
                <td style={{ border: "1px solid", }}>
                  DIAGNOSTIC ON NON-WORKING FURANCE; MAINTENACE ON 2ND FURANCE
                </td>
                <td style={{ border: "1px solid", }}>191.00</td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table style={{ width: "100%" }}>
              <tr>
                <td style={{ width: "25%" }}>REMARK: #1756545445</td>
                <td style={{ width: "25%" }}>APR 0 3 2024</td>
                <td style={{ width: "25%" }}>
                  <table style={{ marginRight: "15px" }}>
                    <tr>
                      <td>
                        <table style={{ border: "1px solid", borderCollapse: "collapse", width: "100%", marginRight: "100px" }} >
                          <tr>
                            <th style={{ border: "1px solid", paddingLeft: "15px", paddingRight: "15px" }}>ARRIVED</th>
                            <th style={{ border: "1px solid", paddingLeft: "15px", paddingRight: "15px" }}>ARRIVED</th>
                            <th style={{ border: "1px solid", paddingLeft: "15px", paddingRight: "15px" }}>ARRIVED</th>
                          </tr>
                          <tr>
                            <td style={{ border: "1px solid", paddingLeft: "15px", paddingRight: "15px" }}>ARRIVED</td>
                            <td style={{ border: "1px solid", paddingLeft: "15px", paddingRight: "15px" }}>ARRIVED</td>
                            <td style={{ border: "1px solid", paddingLeft: "15px", paddingRight: "15px" }}>ARRIVED</td>
                          </tr>
                        </table>
                        <td style={{ textAlign: "center" }}>
                          <img src="http://localhost:3000/images/hoc-logo.png" alt="" />
                        </td>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img src="hoc-logo.png" alt="" />
                      </td>
                    </tr>
                  </table>
                </td>
                <td style={{ width: "25%", verticalAlign: "baseline" }}>
                  <table style={{ border: "1px solid ", borderCollapse: "collapse", width: "100%", textAlign: "end" }} >
                    <tr>
                      <td style={{ background: "#eee", border: "1px solid" }}>
                        TOTAL LABOR
                      </td>
                      <td style={{ border: "1px solid", }}>191 .00</td>
                    </tr>
                    <tr>
                      <td style={{ background: "#eee", border: "1px solid" }}>
                        TOTAL LABOR
                      </td>
                      <td style={{ border: "1px solid", }}>191 .00</td>
                    </tr>
                    <tr>
                      <td style={{ background: "#eee", border: "1px solid" }}>
                        TOTAL LABOR
                      </td>
                      <td style={{ border: "1px solid", }}>191 .00</td>
                    </tr>
                    <tr>
                      <td style={{ background: "#eee", border: "1px solid", fontWeight: 600 }} >
                        TOTAL LABOR
                      </td>
                      <td style={{ border: "1px solid", }}>191 .00</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>

      <table style={{ width: " 1000px ", margin: "4rem  auto" }}>
        <tr>
          <td>
            <table style={{ width: " 100%", margin: "2rem 0rem" }}>
              <tr>
                <td><img src="http://localhost:3000/images/hoc-logo.png" alt="" /></td>
                <td>
                  <table>
                    <tr>
                      <td>Home Comfort Allince</td>
                    </tr>
                    <tr>
                      <td>9680 153rd Ave NE</td>
                    </tr>
                    <tr>
                      <td>Redmond, WA 98052</td>
                    </tr>
                    <tr>
                      <td>(425)881-7920</td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table>
                    <tr>
                      <td style={{ fontWeight: "600" }}>INVOICE : </td>
                      <td>179104887</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "600" }}>INVOICE DATE : </td>
                      <td>12/19/2023</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "600" }}> COMPLETED DATE : </td>
                      <td>12/19/2023</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "600" }}> CUSTOMER PO : </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table style={{ width: "100%" }}>
              <tr>
                <td>
                  <table>
                    <tr>
                      <td style={{ fontWeight: "600" }}>Billing Address</td>
                    </tr>
                    <tr>
                      <td>HARDISTY, JAMES 30062</td>
                    </tr>
                    <tr>
                      <td>5036 84th Avenue Southeast</td>
                    </tr>
                    <tr>
                      <td>Mercer Island, WA 980404 USA</td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table style={{ width: "100%", textAlign: "center" }}>
                    <tr>
                      <td style={{ fontWeight: "600" }}>Billing Address</td>
                    </tr>
                    <tr>
                      <td>HARDISTY, JAMES 30062</td>
                    </tr>
                    <tr>
                      <td>5036 84th Avenue Southeast</td>
                    </tr>
                    <tr>
                      <td>Mercer Island, WA 980404 USA</td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td
                  colspan="2"
                  style={{ textAlign: "center", fontSize: "18px", fontWeight: "600" }}
                >
                  Description of work
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table style={{ borderTop: "2px , solid" }} >
              <tr>
                <td>
                  <p style={{ fontWeight: "600" }}>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Quidem eaque perspiciatis architecto saepe explicabo porro
                    maiores optio? Dignissimos sapiente, illo ratione itaque enim
                    labore dolores est, distinctio nemo, aperiam porro? Lorem
                    ipsum dolor sit amet consectetur adipisicing elit. Quidem
                    eaque perspiciatis architecto saepe ratione itaque enim labore
                    dolores est, distinctio nemo, aperiam porro?
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <table style={{ width: "100%", textAlign: "left", borderTop: " 1px , solid" }}>
                    <tr>
                      <th>Task #</th>
                      <th style={{ padding: "0px ,21px" }}>Description</th>
                      <th>Quantity</th>
                    </tr>
                    <tr>
                      <td>T885511</td>
                      <td>
                        <p style={{ padding: "5px, 21px", margin: "10px" }}>
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit. Deserunt exercitationem quasi ferendis, molestias
                          in fugiat asperiores optio dolores!
                        </p>
                      </td>
                      <td>1.00</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <p style={{ padding: "5px, 21px", margin: "10px" }}>
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit. Deserunt exercitationem quasi ferendis, molestias
                          in fugiat asperiores optio dolores!
                        </p>
                      </td>
                      <td>1.00</td>
                    </tr>
                    <tr>
                      <td>T45245</td>
                      <td>
                        <p style={{ padding: "5px, 21px", margin: "10px" }}>
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit. Deserunt exercitationem quasi ferendis, molestias
                          in fugiat asperiores optio dolores!
                        </p>
                      </td>
                      <td>1.00</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <table style={{ width: "100%", borderTop: "1px solid " }}>
              <tr>
                <td style={{ width: "100%" }}>
                  <table style={{ float: "right" }}>
                    <tr>
                      <td style={{ fontWeight: " 600" }}>Potenial Saving</td>
                      <td>$0.00</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: " 600" }}>Potenial Saving</td>
                      <td>$0.00</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: " 600" }}>Potenial Saving</td>
                      <td>$0.00</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: " 600" }}>Potenial Saving</td>
                      <td>$0.00</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: " 600" }}>Potenial Saving</td>
                      <td>$0.00</td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>Thanku you for choosing home comfort allince</td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table>
              <tr>
                <td>
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo hic fuga, laudantium porro corporis quaerat expedita, laboriosam consequatur, a illo doloribus quos eaque. Ut nisi quia error, sequi sed eveniet!
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Qui sequi distinctio deleniti dignissimos maxime rerum sunt obcaecati! Nihil necessitatibus odit ducimus suscipit quae nisi, assumenda quaerat laboriosam similique, iste eum!
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla velit reprehenderit aliquid modi. Eligendi, mollitia hic est reprehenderit alias corrupti omnis facere dolor, temporibus voluptates veniam tenetur quas, natus odit!
                  </p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>

      <TableContainer>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              {withActions && <TableCell></TableCell>}
              <TableCell>#</TableCell>
              <TableCell>PRODUCT/SERVICE</TableCell>
              <TableCell>DESCRIPTION</TableCell>
              {/* <TableCell>DUE</TableCell> */}
              <TableCell>QTY</TableCell>
              <TableCell>AMOUNT</TableCell>
              {withActions && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          {/* <SortableList
            onSortEnd={onSortEnd}
            useDragHandle
            services={services}
            showAddService={showAddService}
            discardNewService={() => setShowAddService(false)}
            saveNewService={handleSaveNewService}
            deleteService={deleteService}
            withActions={withActions}
          /> */}
        </Table>
        {error && (
          <FormHelperText error>{error}</FormHelperText>
        )}
      </TableContainer>
      {withActions && (
        <Box mt={2}>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            sx={{ mr: 1 }}
            onClick={() => setShowAddService(true)}
            disabled={showAddService}
          >
            Add Line
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            sx={{ mr: 1 }}
            onClick={() => deleteServices()}
            disabled={!services.length}
          >
            Clear all Lines
          </Button>
        </Box>
      )}
    </Box>
  );
}

BillingTableComponent.defaultProps = {
  widthActions: false,
  deleteService: () => { },
  deleteServices: () => { },
  discardNewService: () => { },
  saveNewService: () => { },
  error: '',
};
