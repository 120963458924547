import React, { useState } from "react";

import { Grid, Typography, Box, Chip } from "@mui/material";

import TaskStatus from "./TaskStatus";
import FileViewer from "components/FileViewer";

const EstimateTask = ({ data, handleChange, mail }) => {
  const [viewFile, setViewFile] = useState(false);
  const { taskable: task } = data;

  const renderAttachments = (attachments) => {
    return attachments.map((attachment) => {
      return (
        <Box key={attachment.id} m={1}>
          <Chip
            label={attachment.label}
            onClick={() => setViewFile(attachment)}
          />
        </Box>
      );
    });
  };

  return (
    <Grid container spacing={4}>
      <Grid item sm={8} md={9}>
        <Typography variant="body2">
          <b>{task.from_name}</b> -{" "}
          <a rel="noreferrer" href={`mailto:${task.from}`} target="_blank">
            {task.from}
          </a>
        </Typography>
        <Box>
          <Typography pt={2} pb={2} fontWeight="bold" variant="body2">
            Subject: {task.subject}
          </Typography>
          <Box
            border="solid 1px #ddd"
            boxShadow="1px 0px 24px #ddd"
            maxHeight={300}
            p={2}
            style={{ overflow: "auto" }}
            pt={2}
            dangerouslySetInnerHTML={{ __html: task.content }}
          />
          {task?.attachments?.length > 0 && (
            <>
              <Typography
                fontWeight="bold"
                pt={2}
                variant="body2"
                component="p"
              >
                Attachments:
              </Typography>
              <Box mt={1}>{renderAttachments(mail.attachments)}</Box>
            </>
          )}
        </Box>
      </Grid>
      <Grid item sm={4} md={3}>
        <TaskStatus data={data} handleChange={handleChange} />
      </Grid>
      {viewFile && (
        <FileViewer handleClose={() => setViewFile(false)} file={viewFile} />
      )}
    </Grid>
  );
};

export default EstimateTask;
