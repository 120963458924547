import { TextField } from "@mui/material";
import React from "react";

const FormField = (props) => {
  if (props.select) {
    const { children, ...others } = props;
    return <TextField {...others}>{children}</TextField>;
  }
  return <TextField {...props} />;
};

FormField.defaultProps = {
  fullWidth: true,
  size: "small",
  variant: "outlined",
  color: "secondary",
};

export default FormField;
