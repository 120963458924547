import { useDispatch, useSelector } from "react-redux";
import {
  SEARCH_CONTRACTORS,
  SEARCH_CONTRACTORS_RESULTS,
  CHANGE_PAGE,
  CHANGE_PER_PAGE,
  SEARCH_CONTRACTORS_LOADING,
} from "redux/actions/contractorsActions";
import { getContractorsRequest } from "api/contractors";
import debounce from "lodash.debounce";

const useContractors = () => {
  const dispatch = useDispatch();
  const contractorsReducer = useSelector((state) => state.contractors);

  const getContractors = (params) => {
    dispatch({ type: SEARCH_CONTRACTORS_LOADING, loading: true });
    return getContractorsRequest({ ...params })
      .then((res) => {
        dispatch({ type: SEARCH_CONTRACTORS_RESULTS, results: res.data });
      })
      .finally(() => {
        if (contractorsReducer.loading) {
          dispatch({ type: SEARCH_CONTRACTORS_LOADING, loading: false });
        }
      });
  };

  const search = debounce((query) => {
    dispatch({ type: SEARCH_CONTRACTORS, query });

    getContractorsRequest({ search: query }).then((res) => {
      dispatch({ type: SEARCH_CONTRACTORS_RESULTS, results: res.data });
    });
  }, 600);

  // const getContractors = debounce((query) => {
  //   dispatch({ type: SEARCH_CONTRACTORS, query });

  //   getContractorsRequest(query).then((res) => {
  //     dispatch({ type: SEARCH_CONTRACTORS_RESULTS, results: res.data });
  //   });
  // }, 600);

  const changePage = (newPage) => {
    dispatch({ type: CHANGE_PAGE, page: newPage + 1 });
  };

  const changePerPage = (event) => {
    dispatch({ type: CHANGE_PER_PAGE, perPage: parseInt(event.target.value) });
  };

  return {
    contractorsReducer,
    search,
    changePerPage,
    changePage,
    getContractors,
  };
};

export default useContractors;
