import React from "react";
import { Grid, Typography } from "@mui/material";
import FormField from "components/FormField";
import JobCategoryPicker from "components/Inputs/JobCategoryPicker";
import JobCodePicker from "components/Inputs/JobCodePicker";

const JobDetails = ({ category_id, code_id, description, setJob }) => {
  return (
    <Grid container spacing={2}>
      <Grid item sm={4}>
        <JobCategoryPicker
          value={category_id}
          handleChange={(e) => setJob({ category_id: e.target.value })}
        />
      </Grid>
      <Grid item sm={8}>
        <JobCodePicker
          category_id={category_id}
          value={code_id}
          handleChange={(e) => setJob({ code_id: e.target.value })}
        />
      </Grid>
      <Grid item sm={12}>
        <Typography
          marginTop={2}
          gutterBottom
          borderBottom={"solid 1px #ddd"}
          variant="subtitle2"
        >
          Job Details<sup>*</sup>
        </Typography>
        <FormField
          required
          value={description}
          onChange={(e) => setJob({ description: e.target.value })}
          placeholder="Add job details"
          multiline
          rows={4}
        />
      </Grid>
    </Grid>
  );
};

export default JobDetails;
