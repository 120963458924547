import axios from "utils/axios";

const GET_QUICKBOOKS_AUTHORIZATION_CODE_URL_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/qbe/oauth/authorize`;
const QUICKBOOKS_CONNECT_CALLBACK_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/qbe/oauth/callback`;
const GET_QUICKBOOKS_REFRESH_TOKEN_STATUS_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/qbe/refresh-token-status`;

const getQuickBooksAuthorizationCodeURLRequest = () => axios.get(GET_QUICKBOOKS_AUTHORIZATION_CODE_URL_ROUTE);
const getQuickBooksRefreshTokenStatusRequest = () => axios.get(GET_QUICKBOOKS_REFRESH_TOKEN_STATUS_ROUTE);
const quickBooksConnectCallbackRequest = (data) => axios.get(`${QUICKBOOKS_CONNECT_CALLBACK_ROUTE}/${data.queryString}`);

export {
  quickBooksConnectCallbackRequest,
  getQuickBooksAuthorizationCodeURLRequest,
  getQuickBooksRefreshTokenStatusRequest,
};
