import debounce from "lodash.debounce";
import { useDispatch, useSelector } from "react-redux";
import { searchRequest, getMembershipContractorsRequest } from "api/membershipCallLog";

import {
    CHANGE_PAGE_CALL_LOG,
    CHANGE_PER_PAGE_CALL_LOG,
    SEARCH_MEMBERS_CALL_LOG,
    SEARCH_MEMBERS_LOADING_CALL_LOG,
    SEARCH_MEMBERS_RESULTS_CALL_LOG
} from "redux/actions/membersCallLogActions";

const useMembershipshipCallLog = () => {
    const dispatch = useDispatch();
    const membershipCallLogReducer = useSelector((state) => state.membershipCall);

    const getMemberships = (params) => {
        dispatch({ type: SEARCH_MEMBERS_LOADING_CALL_LOG, loading: true });
        return searchRequest({ ...params })
            .then((res) => {
                dispatch({ type: SEARCH_MEMBERS_RESULTS_CALL_LOG, results: res.data });
            })
            .finally(() => {
                if (membershipCallLogReducer.loading) {
                    dispatch({ type: SEARCH_MEMBERS_LOADING_CALL_LOG, loading: false });
                }
            });
    };

    const search = debounce((query) => {
        dispatch({ type: SEARCH_MEMBERS_CALL_LOG, query });

        searchRequest({ search: query }).then((res) => {
            dispatch({ type: SEARCH_MEMBERS_RESULTS_CALL_LOG, results: res.data });
        });
    }, 600);

    const getMembershipContractors = (data) =>
        getMembershipContractorsRequest(data);

    const changePage = (newPage) => {
        dispatch({ type: CHANGE_PAGE_CALL_LOG, page: newPage + 1 });
    };

    const changePerPage = (event) => {
        dispatch({ type: CHANGE_PER_PAGE_CALL_LOG, perPage: parseInt(event.target.value) });
    };

    return {
        membershipCallLogReducer,
        search,
        getMembershipContractors,
        changePage,
        changePerPage,
        getMemberships,
    };
};

export default useMembershipshipCallLog;
