import React from 'react'
import { SDK } from '@ringcentral/sdk';

const Callback = () => {
    SDK.handleLoginRedirect(undefined, undefined);
  return (
    <div></div>
  )
}

export default Callback