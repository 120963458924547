import { useDispatch, useSelector } from "react-redux";
import { SET_BREADCRUMB_CUSTOM_PLACE } from "redux/actions";

const useBreadcrumb = () => {
  const dispatch = useDispatch();
  const breadcrumbReducer = useSelector((store) => store.breadcrumb);

  const setBreadcrumbCustomPlace = (placeName) => {
    dispatch({
      type: SET_BREADCRUMB_CUSTOM_PLACE,
      breadcrumbCustomPlace: placeName,
    });
  };
  return {
    breadcrumbReducer,
    setBreadcrumbCustomPlace,
  };
};

export default useBreadcrumb;
