import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Layout from "./Layout";
import "./NotFound.css";
import useAuth from "hooks/useAuth";


export default function NotFound(props) {

  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if(user && user.data.role == 'SYSTEM_ADMINISTRATOR'){
      return navigate('/app/users')
    }
    else if(user && user.data.role == 'SYSTEM_FRIDAY_BILLING_JOB'){
      return navigate('/app/contractors')
    }else{
      return navigate('/app')
    }
  });

  return (
    <Layout  icon="ion-ios-warning-outline">
      {/* {props.message} */}
      <section class="page_404">
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <div class="col-sm-10 col-sm-offset-1  text-center">
                <div class="four_zero_four_bg">
                  <h1 class="text-center">404</h1>
                </div>

                <div class="contant_box_404">
                  <h3 class="h2">
                    Look like you're lost
                  </h3>

                  <p>the page you are looking for not avaible!</p>

                  {/* <a href="" class="link_404">Go to Home</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>

  );
}

NotFound.defaultProps = {
  title: "404 Page Not Found",
  message: "The page you are looking for does not exist.",
};
