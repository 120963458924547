import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme) => ({
  inputContainer: {
    width: 400,
    overflow: "hidden",
    transition: "width 0.2s",
    marginRight: theme.spacing(1),
  },
}));

export default styles;
