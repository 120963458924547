import debounce from "lodash.debounce";
import { useDispatch, useSelector } from "react-redux";
import {
  SEARCH_INVOICES,
  SEARCH_INVOICES_RESULTS,
  CHANGE_PAGE,
  CHANGE_PER_PAGE,
} from "redux/actions/invoicesActions";
import {
  getInvoicesRequest,
  viewInvoiceRequest,
  createInvoiceRequest,
  emailInvoiceRequest,
  downloadInvoiceRequest
} from "api/invoices";

const useInvoices = () => {
  const dispatch = useDispatch();
  const invoicesReducer = useSelector((state) => state.invoices);

  const searchInvoices = debounce((query) => {
    dispatch({ type: SEARCH_INVOICES, query });

    getInvoicesRequest(query).then((res) => {
      dispatch({ type: SEARCH_INVOICES_RESULTS, results: res.data });
    });
  }, 600);

  const getInvoices = debounce((query) => {
    dispatch({ type: SEARCH_INVOICES, query });

    getInvoicesRequest(query).then((res) => {
      dispatch({ type: SEARCH_INVOICES_RESULTS, results: res.data });
    });
  }, 600);

  const viewInvoice = (invId) => viewInvoiceRequest(invId);

  const emailInvoice = (invId) => emailInvoiceRequest(invId);

  const downloadInvoice = (invId) => downloadInvoiceRequest(invId);

  const changePage = (newPage) => {
    dispatch({ type: CHANGE_PAGE, page: newPage + 1 });
  };

  const changePerPage = (event) => {
    dispatch({ type: CHANGE_PER_PAGE, perPage: parseInt(event.target.value) });
  };

  const crateInvoice = (params) => {
    return createInvoiceRequest(params);
  };

  return {
    invoicesReducer,
    searchInvoices,
    crateInvoice,
    viewInvoice,
    changePerPage,
    changePage,
    getInvoices,
    emailInvoice,
    downloadInvoice,
  };
};

export default useInvoices;
