import React from "react";
import { Box, LinearProgress, Typography } from "@mui/material";

const Processing = ({ percent }) => {
  return (
    <Box m={2}>
      <LinearProgress
        variant={percent ? "determinate" : "indeterminate"}
        value={percent}
      />
      <Typography variant="body2" textAlign={"center"}>
        Please wait...
      </Typography>
    </Box>
  );
};

Processing.defaultProps = {
  percent: null,
};

export default Processing;
