import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme) => ({
  rootCounter: {
    flexGrow: 1,
    marginTop: 20,
  },
  counterIcon: {
    color: theme.palette.common.white,
    opacity: 0.7,
    fontSize: 64,
  },
  dashWidgets: {
    marginTop: "20px",
  },
}));

export default styles;
