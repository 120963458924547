import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ContentBlock from "components/ContentBlock";
import useApplication from "hooks/useApplication";
import { createContractor } from "../../../../api/contractors";
import ContractorsForm from "../components/Form";
import ".././../../../utils/sweetalert.css"

export default function Create() {
  const { setPage } = useApplication();
  const navigate = useNavigate();

  useEffect(() => {
    setPage({
      title: "Contractors",
      path: "/app/contractors",
    });
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (values) => {
    try {
      if (typeof values.city_id === "object") {
        values.city_id = values.city_id?.id;
      }
      const response = await createContractor(values);
      if (response.data) {
        Swal.fire({
          icon: "success",
          text: "Contractor created",
          title: "Success!",
          timer: 4000,
          timerProgressBar: true,
          customClass: {
            title: "swal-text",
            popup:"swal-text"
            
          },
        }).then((result) => {
          navigate("/app/contractors");
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: "Try again later",
        title: "Something went wrong!",
        timer: 4000,
        customClass: {
          title: "swal-text",
          popup:"swal-text"
          
        },
        timerProgressBar: true,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/app/contractors");
        }
      });
    }
  };

  return (
    <ContentBlock title="Create Contractor" icon="ion-ios-clipboard-outline">
      <ContractorsForm onSubmitHandler={onSubmit} />
    </ContentBlock>
  );
}
