import React from "react";
import { Button, CircularProgress } from "@mui/material";

const LoadingButton = ({ disabled, loading, children, ...others }) => {
  return (
    <Button
      variant="contained"
      disabled={disabled || loading}
      sx={{ fontWeight: "600" }}
      {...others}
    >
      {loading ? <CircularProgress color="primary" size={20} /> : children}
    </Button>
  );
};

export default LoadingButton;
