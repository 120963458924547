import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from "@mui/material";
import useQueue from "hooks/useQueue";
import useApplication from "hooks/useApplication";
import PaperBlock from "components/PaperBlock";
import SearchInput from "components/SearchInput";
import TaskPreview from "components/TaskPreview";
import TaskRow from "./Components/TaskRow";
import LinkBtn from "components/LinkBtn";
import { Button, Box } from "@mui/material";
import TableFooterWithPagination from "components/TableFooterWithPagination";
import useBatch from "hooks/useBatch";
import JobRequestModal from "components/JobSheetModal";

export default function TaskQueue() {
  const navigate = useNavigate();
  const {
    queue: { loading, results, search, meta },
    getTasks,
    changeTask,
    changePage,
    changePerPage,
  } = useQueue();
  const { setPage } = useApplication();
  const [open, setOpen] = useState(false);
  /**
   * TODO: Check if viewJob is in use.
   */
  // eslint-disable-next-line no-unused-vars
  const [viewJob, setViewJob] = useState(false);
  const { getPendingBatch } = useBatch();

  useEffect(() => {
    setPage({
      title: "Task Queue",
      path: "/app/task-queue",
    });
    // eslint-disable-next-line
  }, []);

  const handleSearch = (search) => {
    getTasks({
      search,
      per_page: meta.per_page,
    });
  };

  useEffect(() => {
    getTasks(search);
    getPendingBatch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changePriority = (taskId, priority) => {
    changeTask(taskId, priority);
  };

  const setJob = (job) => {
    setOpen(false);
    setViewJob(job);
  };

  const handleViewDetails = (open) => {
    if (open.task_type === "NEW_MEMBERSHIP_APPLICATION") {
      return navigate(`/app/members/${open.membership_id}`);
    }
    setOpen(open);
  };

  const handleClose = () => setOpen(false);

  const changeOpenTask = (values) => setOpen({ ...open, ...values });

  const handleChangePage = (_event, newPage) => {
    changePage(newPage);
    getTasks({ search, page: newPage + 1, per_page: meta.per_page });
  };

  const handleChangeRowsPerPage = (event) => {
    changePerPage(event);
    getTasks({
      search,
      page: meta.current_page,
      per_page: parseInt(event.target.value),
    });
  };

  return (
    <>
      <PaperBlock
        title="Task Queue"
        loading={loading}
        actions={
          <>
            <SearchInput
              defaultValue={search}
              onChange={handleSearch}
              placeholder="By ID, First Name, Last Name, Phone or Email"
            />
            <Button
              style={{ color: "white" }}
              component={LinkBtn}
              to={`/app/invoices/batches`}
              color="primary"
              size="small"
              variant="contained"
              sx={{ ml: 2, height: "40px", whiteSpace: "nowrap" }}
            >
              Pending Invoices
            </Button>
            {/* {batch.pending > 0 && (
              <Button
                component={LinkBtn}
                to={`/app/invoices/batch/${batch.batch_id}/process`}
                color="primary"
                size="small"
                variant="contained"
                sx={{ ml: 2 }}
              >
                + {batch.pending} Pending Invoices
              </Button>
            )} */}
          </>
        }
      >
        <Box sx={{ overflow: "auto" }}>
          <Table size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Date|Time</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Priority</TableCell>
                <TableCell>Assign to</TableCell>
                <TableCell align="right" >Action</TableCell>
                {/* <TableCell align="right"></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map((task) => (
                <TaskRow
                  data={task}
                  key={task.id}
                  handleViewDetails={handleViewDetails}
                  handleChangePriority={changePriority}
                />
              ))}
              {!results.length && (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    No data
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            {results.length > 0 && (
              <TableFooterWithPagination
                count={meta.total}
                rowsPerPage={meta.per_page}
                page={meta.current_page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Table>
        </Box>
        {open && (
          <TaskPreview
            getTasks={getTasks}
            data={open}
            handleClose={handleClose}
            handleChange={changeOpenTask}
            setJob={setJob}
          />
        )}
        {viewJob && (
          <JobRequestModal
            open
            onRefresh={getTasks}
            viewJob={viewJob}
            membership_id={viewJob.membership_id}
            handleClose={() => setViewJob(false)}
          />
        )}
      </PaperBlock>
    </>
  );
}
