import React from "react";
import ReactDOM from "react-dom";
import Pages from "pages";
import { Provider } from "react-redux";
import store from "redux/store";

ReactDOM.render(

  <React.StrictMode>
    <Provider store={store}>
      <Pages/>
    </Provider>
  </React.StrictMode>,

  document.getElementById("root")
);