import axios from "utils/axios";
import {
  CREATE_NOTE_ROUTE,
  GET_NOTES_ROUTE,
  UPDATE_NOTE_ROUTE,
  DELETE_NOTE_ROUTE,
} from "./routes";

export const createNoteRequest = (notable_id, notable_type, content) => {
  return axios.post(CREATE_NOTE_ROUTE, {
    notable_id,
    notable_type,
    content,
  });
};
export const getNotesRequest = (notable_type, notable_id) => {
  return axios.post(GET_NOTES_ROUTE, { notable_id, notable_type });
};
export const updateNoteRequest = (id, content) => {
  return axios.post(UPDATE_NOTE_ROUTE, { id, content });
};
export const deleteNoteRequest = (notable_id) => {
  return axios.delete(`${DELETE_NOTE_ROUTE}/${notable_id}`);
};
