import axios from "utils/axios";
import {
  GET_FILES_ROUTE,
  DELETE_FILES_ROUTE,
  DELETE_FILE_ROUTE,
  DOWNLOAD_FILE_ROUTE,
  DOWNLOAD_FILES_ROUTE,
  VIEW_FILE_ROUTE,
  CREATE_DIRECTORY_ROUTE,
  RENAME_FILE_ROUTE,
} from "./routes";

const getFilesRequest = () => axios.get(GET_FILES_ROUTE);
const deleteFileRequest = (uuid) =>
  axios.delete(`${DELETE_FILE_ROUTE}/${uuid}`);
const deleteFilesRequest = (uuids) =>
  axios.post(`${DELETE_FILES_ROUTE}`, { uuids });
const createDirectoryRequest = (files) =>
  axios.post(`${CREATE_DIRECTORY_ROUTE}`, files);
const renameFileRequest = (label, uuid) =>
  axios.post(`${RENAME_FILE_ROUTE}/${uuid}`, { label });
const downloadFileRequest = (uuid) =>
  axios.post(`${DOWNLOAD_FILE_ROUTE}/download${uuid}`);
const downloadFilesRequest = (search) =>
  axios.get(DOWNLOAD_FILES_ROUTE, { params: { search } });
const viewFileRequest = (search) =>
  axios.get(VIEW_FILE_ROUTE, { params: { search } });

export {
  getFilesRequest,
  deleteFileRequest,
  deleteFilesRequest,
  downloadFileRequest,
  downloadFilesRequest,
  viewFileRequest,
  createDirectoryRequest,
  renameFileRequest,
};
