import { makeStyles } from "@mui/styles";

const styles = makeStyles(() => ({
  cardContent: {
    padding: "0px !important",
    "&:last-child": {
      padding: 0,
    },
  },
  cardHearderCenter: {
    textAlign: "center",
    padding: 0,
  },
  title: {
    color: "inherit",
    fontSize: 18,
    fontWeight: 600,
  },
  subheader: {
    color: "inherit",
  },
}));

export default styles;
