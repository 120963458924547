import axios from "utils/axios";

export const VENDORS_SEARCH_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/vendors`;

export const getVendorsRequest = (search) =>
  axios.get(VENDORS_SEARCH_ROUTE, { params: { ...search } });

export const getVendorRequest = (id) =>
  axios.get(`${VENDORS_SEARCH_ROUTE}/${id}`);

export const createVendorRequest = (data) =>
  axios.post(VENDORS_SEARCH_ROUTE, data);

export const updateVendorRequest = (data, id) =>
  axios.post(`${VENDORS_SEARCH_ROUTE}/${id}`, data);

export const deleteVendorRequest = (id) =>
  axios.delete(`${VENDORS_SEARCH_ROUTE}/${id}`);
