import debounce from "lodash.debounce";
import { useDispatch, useSelector } from "react-redux";
import {
  SEARCH_PAYMENTS,
  SEARCH_PAYMENTS_RESULTS,
  CHANGE_PAGE,
  CHANGE_PER_PAGE,
} from "redux/actions/paymentsActions";
import { getPaymentsRequest, viewPaymentRequest } from "api/payments";

const usePayments = () => {
  const dispatch = useDispatch();
  const paymentsReducer = useSelector((state) => state.payments);

  const searchPayments = debounce((query) => {
    dispatch({ type: SEARCH_PAYMENTS, query });
    getPaymentsRequest(query).then((res) => {
      dispatch({ type: SEARCH_PAYMENTS_RESULTS, results: res.data });
    });
  }, 600);

  const viewPayment = (pid) => viewPaymentRequest(pid);

  const changePage = (newPage) => {
    dispatch({ type: CHANGE_PAGE, page: newPage + 1 });
  };

  const changePerPage = (event) => {
    dispatch({ type: CHANGE_PER_PAGE, perPage: parseInt(event.target.value) });
  };  

  return {
    paymentsReducer,
    searchPayments,
    viewPayment,
    changePage,
    changePerPage,
  };
};

export default usePayments;
