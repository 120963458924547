import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import ThemeWrapper from "theme/ThemeWrapper";
import Guest from "./Guest";
import UserPages from "./App";
import Authenticated from "components/Authenticated";

const Pages = () => {
  return (
    <ThemeWrapper>
      <BrowserRouter>
        <Routes>
          <Route
            path="/app/*"
            element={
              <Authenticated>
                <UserPages/>
              </Authenticated>
            }
          />
          <Route path="*" element={<Guest />} />
        </Routes>
      </BrowserRouter>
    </ThemeWrapper>
  );
};

export default Pages;
