/* eslint-disable no-unused-vars */
import React from "react";

import useQueue from "hooks/useQueue";

const ActionsHandler = () => {
  const { queue } = useQueue();
  return null;
};

export default ActionsHandler;
