import debounce from "lodash.debounce";
import { useDispatch, useSelector } from "react-redux";
import {
  searchRequest,
  viewMembershipRequest,
  updateMembershipRequest,
  updateMembershipPropertyRequest,
  getMembershipContractorsRequest,
  createMembershipPropertyRequest,
  deleteMembershipPropertyRequest,
  DeletedMemberRequestv,DeletedMemberRequest,DeletedMemberRequestf
} from "api/membership";
import {
  SEARCH_MEMBERS,
  SEARCH_MEMBERS_RESULTS,
  CHANGE_PAGE,
  CHANGE_PER_PAGE,
  SEARCH_MEMBERS_LOADING,DELETED_MEMBERS_RESULTS
} from "redux/actions/membersActions";

const useMembership = () => {
  const dispatch = useDispatch();
  const membershipReducer = useSelector((state) => state.membership);
  

  const getMemberships = (params) => {
    dispatch({ type: SEARCH_MEMBERS_LOADING, loading: true });
    return searchRequest({ ...params })
      .then((res) => {
        dispatch({ type: SEARCH_MEMBERS_RESULTS, results: res.data });
      })
      .finally(() => {
        if (membershipReducer.loading) {
          dispatch({ type: SEARCH_MEMBERS_LOADING, loading: false });
        }
      });
  };

  const search = debounce((query) => {
    dispatch({ type: SEARCH_MEMBERS, query });

    searchRequest({ search: query }).then((res) => {
      dispatch({ type: SEARCH_MEMBERS_RESULTS, results: res.data });
    });
  }, 600);

  // To get all deleted Member
  const getDeletedMembers = (params) => {
    dispatch({ type: SEARCH_MEMBERS_LOADING, loading: true });
    return DeletedMemberRequest({ ...params })
      .then((res) => {
        dispatch({ type: DELETED_MEMBERS_RESULTS, results: res.data });
      })
      .finally(() => {
        if (membershipReducer.loading) {
          dispatch({ type: SEARCH_MEMBERS_LOADING, loading: false });
        }
      });
  };
  // To restore deleted Member
  const restoreDeletedMembers = (params) => {
    dispatch({ type: SEARCH_MEMBERS_LOADING, loading: true });
    return DeletedMemberRequestf({ ...params })
      .then((res) => {
        return getDeletedMembers()
        
      })
      .finally(() => {
        if (membershipReducer.loading) {
          dispatch({ type: SEARCH_MEMBERS_LOADING, loading: false });
        }
      });
  };

  const viewMembership = (id) => viewMembershipRequest(id);

  const updateMembership = (data) => updateMembershipRequest(data);
  const deleteMembers = (params) => DeletedMemberRequestv(params);

  const updateMembershipProperty = (data) => updateMembershipPropertyRequest(data);
  const createMembershipProperty = (data) => createMembershipPropertyRequest(data);
  const deleteMembershipProperty = (data) => deleteMembershipPropertyRequest(data);

  const getMembershipContractors = (membership_id) =>
    getMembershipContractorsRequest(membership_id);

  const changePage = (newPage) => {
    dispatch({ type: CHANGE_PAGE, page: newPage + 1 });
  };

  const changePerPage = (event) => {
    dispatch({ type: CHANGE_PER_PAGE, perPage: parseInt(event.target.value) });
  };

  return {
    membershipReducer,
    search,
    viewMembership,
    updateMembership,
    updateMembershipProperty,
    createMembershipProperty,
    deleteMembershipProperty,
    getMembershipContractors,
    changePage,
    changePerPage,
    getMemberships,
    deleteMembers,getDeletedMembers,restoreDeletedMembers
  };
};

export default useMembership;
