import React, { useEffect, useState } from "react";
import FormField from "components/FormField";
import { MenuItem } from "@mui/material";
import useUtils from "hooks/useUtils";

const JobCodePicker = ({ value, handleChange, category_id }) => {
  const { getCategoryCodes } = useUtils();
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    if (category_id) {
      getCategoryCodes(category_id).then((res) => {
        setOptions(res.data);
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category_id]);

  if (!category_id) {
    return (
      <FormField
        required
        InputLabelProps={{ shrink: true }}
        placeholder="Please select a job category"
        disabled
      />
    );
  }
  return (
    <FormField
      required
      name="code_id"
      select
      InputLabelProps={{ shrink: true }}
      placeholder={loading ? "Please Wait..." : ""}
      label="Job Type"
      value={value}
      onChange={handleChange}
    >
      {options.map((code) => (
        <MenuItem
          key={`${code.id}-code`}
          value={code.id}
        >{`${code.description}`}</MenuItem>
      ))}
    </FormField>
  );
};

export default JobCodePicker;
