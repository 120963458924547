import React from "react";
import { useNavigate } from "react-router-dom";
import { TableRow, TableCell, Button, CircularProgress } from "@mui/material";
import dayjs from "dayjs";
import SendIcon from '@mui/icons-material/Send';
import Swal from "sweetalert2";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import '../../../../utils/sweetalert.css'

const PaymentRow = ({ data }) => {

  const navigate = useNavigate();

  const handleSubmit = async (e) => {

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      customClass: {
        title: "swal-text",
        popup: "swal-text"

      },
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Pay Refund'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.get(`http://localhost:8000/api/v1/payments/refund/${data.id}`)
          .then(res => {
            toast.success("Refund Successfully initiated");
          }).catch(err => {
            if (err.response) {
              toast.error('Refund unSuccessfully');
            }
          })
      }
    })
  }

  return (
    <>
      <TableRow>
        <TableCell component="th" scope="row">
          {data.id}
        </TableCell>
        <TableCell component="th" scope="row">
          {data?.payments[0]?.payer_name || "N/A"}
        </TableCell>
        <TableCell component="th" scope="row">
          {data.description}
        </TableCell>
        <TableCell component="th" scope="row">

          {data?.payments[0]?.company_name || "N/A"}
        </TableCell>
        <TableCell component="th" scope="row">
          ${data.amount / 100}
        </TableCell>
        <TableCell component="th" scope="row">
          {data.payments[0].payment_method || "N/A"}
        </TableCell>
        <TableCell component="th" scope="row">
          {`${dayjs(data.created_at).format("MM/DD/YYYY")}`}
        </TableCell>
        <TableCell component="th" scope="row">
          {data.status}
        </TableCell>
        <TableCell component="th" scope="row" align="right">
          {data.status === "REFUND" ? (
            <Button
            >
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginRight: "10px", backgroundColor: "#dc3545" }}
              onClick={handleSubmit}
              endIcon={<SendIcon />}
            >
              Refund
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => navigate(`/app/invoices/${data.payments[0].invoice_id}`)}
          >
            View
          </Button>
        </TableCell>
        <Toaster
          position="top-right"
          reverseOrder={false}
          toastOptions={{ duration: 5000 }}
        />
      </TableRow>
    </>
  );
};

export default PaymentRow;
