import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme) => ({
  activityWrap: {
    padding: 10,
    "& ul:before": {
      content: '""',
      position: "absolute",
      height: "100%",
      left: -2,
      borderLeft: `2px solid ${theme.palette.primary.main}`,
      [theme.breakpoints.up("sm")]: {
        left: 104,
      },
    },
  },
  activityList: {
    paddingLeft: theme.spacing(4),
    paddingRight: 0,
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  timeDot: {
    position: "relative",
    "& span": {
      border: `3px solid ${theme.palette.primary.main}`,
      width: 15,
      height: 15,
      borderRadius: "50%",
      position: "absolute",
      background: theme.palette.background.paper,
      top: 0,
      left: -27,
      [theme.breakpoints.up("sm")]: {
        left: 78,
      },
    },
    "& time": {
      fontSize: 12,
      textAlign: "left",
      whiteSpace: "pre-wrap",
      wordBreak: "break-word",
      display: "block",
      paddingLeft: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        textAlign: "right",
        width: 60,
        paddingLeft: 0,
      },
    },
  },
  activityText: {
    paddingLeft: theme.spacing(6),
    "& span": {
      fontSize: 12,
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& p": {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
}));

export default styles;
