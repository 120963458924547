import { SET_BREADCRUMB_CUSTOM_PLACE } from "redux/actions";

const initialState = {
  breadcrumbCustomPlace: null,
};

export default function ui(state = initialState, action = {}) {
  switch (action.type) {
    case SET_BREADCRUMB_CUSTOM_PLACE:
      return { ...state, breadcrumbCustomPlace: action.breadcrumbCustomPlace };
    default:
      return state;
  }
}
