import * as Yup from "yup";
import dayjs from "dayjs";

export const MembershipApplication = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .min(2, "Password too Short!")
    .max(50, "Password too Long!")
    .required("Required"),
  termsAgree: Yup.bool().oneOf([true], "Field must be checked"),
});

export const contact = {
  first_name: "",
  last_name: "",
  email: "",
  home_phone: "",
  cell_phone: "",
  work_phone: "",
  work_phone_extension: "",
  occupation: "",
  company: "",
};

export const property = {
  address: "",
  city: "",
  state: "",
  zip: "",
};

export const initialValues = {
  members: [{ ...contact }],
  properties: [{ ...property }],
  mailing_address: {
    address: "",
    city: null,
    state: null,
    zip: "",
  },
  membership: {
    membership_card: "2",
    newsletter: "2",
    communications: "2",
    invoice_preference: "2",
    membership_type: "",
    about_club: "",
    mortgage_holder: "",
    years_in_home: "",
    checking_savings: "",
    branch: "",
    subscription_type: "single_year",
    subscription_no_years: 1,
    payment_method: "STRIPE",
    paperless_communication: false,
    terms_acceptance: false,
    signature: "",
    mailing_address_as_home: false,
    out_of_network: false,
    date: dayjs().format("YYYY-MM-DD"),
  },
};
