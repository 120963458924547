import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Box, MenuItem } from "@mui/material";
import { useFormik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import InputMask from "react-input-mask";
import FormField from "components/FormField";
import HeadingLine from "components/HeadingLine";
import CityPicker from "components/CityPicker";
import StatePicker from "components/StatePicker";
import useUtils from "hooks/useUtils";
import { searchZipDataRequest } from "api/utilities";
import useAccessControl from "hooks/useAccessControl";
import ServiceLocationPicker from "components/ServiceLocationPicker";
import { useEffect } from "react";

const ContractorValidationSchema = Yup.object().shape({
  alpha_ref: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
  service_location_id: Yup.number().nullable(),
  phone: Yup.string().required("Required"),
});

const ContractorsForm = ({ form, onSubmitHandler, withHeadingLine }) => {
  const { canManageServiceLocationUser } = useAccessControl();
  const navigate = useNavigate();
  const { utils } = useUtils();

  const formik = useFormik({
    initialValues: {
      service_location_id: null,
      service_location: null,
      ...form
    },
    validationSchema: ContractorValidationSchema,
    onSubmit: async (values) => {
      await onSubmitHandler(values);
    },
  });

  useEffect(() => {
    if (form && form.service_location) {
      formik.setValues({
        ...formik.values,
        service_location_id: form.service_location.id,
        service_location: {
          id: form.service_location.id,
          label: form.service_location.name
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form])

  const handleZipChange = (e, formik) => {
    if (e.target.value.length <= 4) {
      return false;
    }
    searchZipDataRequest(e.target.value).then(({ data }) => {
      if (!formik.values.state_id !== data.details.state.code) {
        formik.setFieldValue("state_id", data.details.state.code);
        formik.setFieldValue("city_id", {
          id: data.details.city.id,
          label: data.details.city.city,
        });
      }
    });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box mb={4}>
        {withHeadingLine && (
          <HeadingLine title="Contractor Information" />
        )}
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={3}>
            <FormField
              label="Alpha Ref"
              disabled={formik.values.id}
              value={formik.values.alpha_ref}
              onChange={formik.handleChange}
              name="alpha_ref"
              required
              error={
                formik.touched.alpha_ref && Boolean(formik.errors.alpha_ref)
              }
              helperText={formik.touched.alpha_ref && formik.errors.alpha_ref}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormField
              label="Company name"
              value={formik.values.name}
              onChange={formik.handleChange}
              name="name"
              required
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <FormField
              label="Vendor type"
              value={formik.values.vendor_type_id}
              onChange={formik.handleChange}
              name="vendor_type_id"
              select
              error={
                formik.touched.vendor_type_id &&
                Boolean(formik.errors.vendor_type_id)
              }
              helperText={
                formik.touched.vendor_type_id && formik.errors.vendor_type_id
              }
            >
              {utils.vendor_types.map((val) => (
                <MenuItem key={val.id} value={val.id}>
                  {val.label}
                </MenuItem>
              ))}
            </FormField>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormField
              label="Category"
              value={formik.values.job_category_id}
              onChange={formik.handleChange}
              name="job_category_id"
              select
              error={
                formik.touched.job_category_id &&
                Boolean(formik.errors.job_category_id)
              }
              helperText={
                formik.touched.job_category_id && formik.errors.job_category_id
              }
            >
              {utils.categories.map((val) => (
                <MenuItem key={val.id} value={val.id}>
                  {val.name}
                </MenuItem>
              ))}
            </FormField>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <HeadingLine title="Address Details" />
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={3}>
            <FormField
              label="Street Address"
              value={formik.values.address_line}
              onChange={formik.handleChange}
              name="address_line"
              error={
                formik.touched.address_line &&
                Boolean(formik.errors.address_line)
              }
              helperText={
                formik.touched.address_line && formik.errors.address_line
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormField
              label="Zip code"
              value={formik.values.zip}
              onChange={formik.handleChange}
              onBlur={(e) => handleZipChange(e, formik)}
              name="zip"
              type="number"
              error={formik.touched.zip && Boolean(formik.errors.zip)}
              helperText={formik.touched.zip && formik.errors.zip}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <StatePicker
              InputLabelProps={{ shrink: true }}
              name="state_id"
              label="State"
              value={formik.values.state_id}
              onChange={(e) => formik.setFieldValue("state_id", e.target.value)}
              error={formik.touched.state_id && Boolean(formik.errors.state_id)}
              helperText={formik.touched.state_id && formik.errors.state_id}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <CityPicker
              label="City"
              InputLabelProps={{ shrink: true }}
              value={formik.values.city_id}
              name="city_id"
              state_code={formik.values.state_id}
              onChange={(val) => formik.setFieldValue("city_id", val)}
              error={formik.touched.city_id && Boolean(formik.errors.city_id)}
              helperText={formik.touched.city_id && formik.errors.city_id}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={4}>
        <HeadingLine title="Contact Details" />
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={3}>
            <FormField
              label="Contact"
              value={formik.values.contact}
              onChange={formik.handleChange}
              name="contact"
              error={formik.touched.contact && Boolean(formik.errors.contact)}
              helperText={formik.touched.contact && formik.errors.contact}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormField
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              name="email"
              type="email"
              required
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputMask
              value={formik.values.phone}
              onChange={formik.handleChange}
              mask="(+1) 999-999-9999"
            >
              {() => <FormField
                required
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
                label="Phone"
                name="phone"
              />}
            </InputMask>
          </Grid>
          <Grid item xs={12} md={3}>
          <InputMask
              value={formik.values.home_phone}
              onChange={formik.handleChange}
              mask="(+1) 999-999-9999"
            >
              {() => <FormField
                error={
                  formik.touched.home_phone && Boolean(formik.errors.home_phone)
                }
                helperText={formik.touched.home_phone && formik.errors.home_phone}
                label="Home phone"
                name="home_phone"
              />}
            </InputMask>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormField
              label="Fax"
              value={formik.values.fax}
              onChange={formik.handleChange}
              name="fax"
              error={formik.touched.fax && Boolean(formik.errors.fax)}
              helperText={formik.touched.fax && formik.errors.fax}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <FormField
              label="Pager"
              value={formik.values.pager}
              onChange={formik.handleChange}
              name="pager"
              error={formik.touched.pager && Boolean(formik.errors.pager)}
              helperText={formik.touched.pager && formik.errors.pager}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormField
              label="Web"
              value={formik.values.website}
              onChange={formik.handleChange}
              name="website"
              error={formik.touched.website && Boolean(formik.errors.website)}
              helperText={formik.touched.website && formik.errors.website}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={2}>
        <FormField
          label="Comment"
          value={formik.values.comment}
          onChange={formik.handleChange}
          name="comment"
          multiline
          minRows={3}
          error={formik.touched.comment && Boolean(formik.errors.comment)}
          helperText={formik.touched.comment && formik.errors.comment}
        />
      </Box>
      {canManageServiceLocationUser() && (
      <Box mt={4}>
        <HeadingLine title="Service Location" />
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={3}>
            <ServiceLocationPicker
              label="Service Location"
              InputLabelProps={{ shrink: true }}
              value="Seattle Service Location"
              //{formik.values?.service_location}
              name="service_location_id"
              onChange={(val) => {
                formik.setFieldValue('service_location', val);
                formik.setFieldValue('service_location_id', val.id);
              }}
            />
          </Grid>
        </Grid>
      </Box>
      )}
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Button
          variant="text"
          sx={{ mr: 1 }}
          onClick={() => navigate("/app/contractors")}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={formik.isSubmitting}
          loadingPosition="center"
          variant="contained"
          onClick={formik.submitForm}
          disabled={formik.isInvalid}
        >
          {form.alpha_ref ? "Update contractor" : "Create contractor"}
        </LoadingButton>
      </Box>
    </form>
  );
};

ContractorsForm.defaultProps = {
  withHeadingLine: true,
  form: {
    alpha_ref: "",
    name: "",
    address_line: "",
    address_line_2: "",
    city_id: null,
    state_id: null,
    zip: "",
    contact: "",
    vendor_type_id: null,
    job_category_id: null,
    communication_pref_id: null,
    email: "",
    email_2: "",
    phone: "",
    fax: "",
    cell_phone: "",
    pager: "",
    home_phone: "",
    website: "",
    expire: "",
    comment: "",
  },
};

export default ContractorsForm;
