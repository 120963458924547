import React from "react";
import { Grid, MenuItem } from "@mui/material";
import FormField from "components/FormField";
import HeadingLine from "components/HeadingLine";

const CreditInformation = ({ membership, form, setForm }) => {
  const handleChange = (e) => {
    const _membership = { ...form.membership };
    _membership[e.target.name] = e.target.value;
    setForm({ membership: _membership });
  };

  return (
    <>
      <Grid item md={12} sm={12} xs={12}>
        <HeadingLine title="Confidential Credit Information:" />
      </Grid>
      <Grid item md={5} sm={12} xs={12}>
        <FormField
          label="Mortgage Holder"
          onChange={handleChange}
          value={membership.mortgage_holder}
          name="mortgage_holder"
        />
      </Grid>
      <Grid item md={2} sm={12} xs={12}>
        <FormField
          label="Years in House"
          onChange={handleChange}
          value={membership.years_in_home}
          type="number"
          name="years_in_home"
        />
      </Grid>
      <Grid item md={3} sm={12} xs={12}>
        <FormField
          label="Checking/Savings"
          onChange={handleChange}
          value={membership.checking_savings}
          name="checking_savings"
          select
        >
          <MenuItem value="checking">Checking</MenuItem>
          <MenuItem value="savings">Savings</MenuItem>
          <MenuItem value="both">Both</MenuItem>
        </FormField>
      </Grid>
      <Grid item md={2} sm={12} xs={12}>
        <FormField
          label="Branch"
          onChange={handleChange}
          value={membership.branch}
          name="branch"
        />
      </Grid>
    </>
  );
};

export default CreditInformation;
