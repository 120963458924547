import React, { createRef } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Badge,
  Fab,
  Grid,
  ListItemText,
  List,
  ListItem,
  ListItemIcon,
  ListSubheader,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ErrorPopover from "components/ErrorPopup";

const ImportedInvoices = ({ data, messages }) => {
  const errorPopoverRef = createRef();
  const renderMessages = () => {
    return (
      <List
        dense
        sx={{ backgroundColor: "#f5f5f5" }}
        subheader={<ListSubheader>Process Feedback</ListSubheader>}
      >
        {messages.map((message, index) => (
          <ListItem key={`timeline-${index}`}>
            <ListItemIcon>
              <DoneIcon color="success" />
            </ListItemIcon>
            <ListItemText primary={message} />
          </ListItem>
        ))}
      </List>
    );
  };

  const renderErrors = (errors) => {
    const errorsCount = Object.keys(errors).length;
    if (errorsCount === 0) {
      return <span>No Errors</span>;
    }
    return (
      <>
        <Badge
          badgeContent={errorsCount}
          color="error"
          onMouseEnter={(event) => errorPopoverRef.current.open(event)}
          onMouseLeave={(event) => errorPopoverRef.current.close(event)}
        >
          <Fab size="small" color="primary">
            <WarningAmberIcon size="small" />
          </Fab>
        </Badge>
        <ErrorPopover ref={errorPopoverRef}>
          <List dense>
            {Object.entries(errors).map(([key, value], index) => (
              <ListItem key={`timeline-${index}`}>
                <ListItemIcon>
                  <WarningAmberIcon color="error" />
                </ListItemIcon>
                <ListItemText primary={value.message} />
              </ListItem>
            ))}
          </List>
        </ErrorPopover>
      </>
    );
  };
  return (
    <Grid container spacing={1}>
      <Grid item sm={12} md={12}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>#Job</TableCell>
              <TableCell>Member</TableCell>
              <TableCell>Contractor</TableCell>
              <TableCell>Errors</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length === 0 && (
              <TableRow>
                <TableCell colSpan={6}>No data</TableCell>
              </TableRow>
            )}
            {data.map((res, index) => (
              <TableRow key={`invoice-${index}`}>
                <TableCell>
                  {res.invoice.external_invoice_number
                    ? res.invoice.external_invoice_number
                    : "missing"}
                </TableCell>
                <TableCell>
                  {res.invoice.job_id ? res.invoice.job_id : "missing"}
                </TableCell>
                <TableCell>
                  {res.invoice.csv_mem_name
                    ? res.invoice.csv_mem_name
                    : "missing"}
                </TableCell>
                <TableCell>
                  {res.invoice.csv_con_biz
                    ? res.invoice.csv_con_biz
                    : "missing"}
                </TableCell>
                <TableCell>{renderErrors(res.errors)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
      <Grid item sm={12} md={12}>
        {renderMessages()}
      </Grid>
    </Grid>
  );
};

export default ImportedInvoices;
