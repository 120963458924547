import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme) => ({
	tabsTitle: {
		fontWeight: 'bold',
		marginBottom: theme.spacing(2),
		color: theme.palette.primary.main
	},
	formContainer: {
		'& .MuiFormControl-root': {
			marginBottom: theme.spacing(2)
		}
	}
}));

export default styles;
