import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useMembership from "hooks/useMembership";
import { statuses } from "utils/jobStatuses";
import columns from "./tableData";
import useStyles from "./styles";

const Contractors = ({ membershipId }) => {
  const classes = useStyles();
  const [state, _setState] = useState({
    loading: false,
    contractors: [],
  });
  const { getMembershipContractors } = useMembership();
  const navigate = useNavigate();

  const setState = (newState) => _setState({ ...state, ...newState });

  useEffect(() => {
    getMembershipContractors(membershipId)
      .then((response) => {
        setState({
          loading: false,
          contractors: response.data,
        });
      })
      .catch((error) => {
        setState({ loading: false });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderStatus = (props) => {
    return (
      <Chip
        label={props.row.job_status}
        style={{ background: statuses[props.row.job_status], color: "white" }}
      />
    );
  };

  const renderAction = (data) => (
    <>
      <Button
        size="small"
        onClick={() => navigate(`/app/contractors/${data.row.id}`)}
        sx={{ mr: 1 }}
      >
        View Contractor
      </Button>
      <Button
        size="small"
        onClick={() => navigate(`/app/jobs/${data.row.job_id}`)}
      >
        View Job
      </Button>
    </>
  );

  return (
    <React.Fragment>
      <DataGrid
        rows={state.contractors}
        columns={columns(renderStatus, renderAction)}
        disableSelectionOnClick
        autoHeight
        loading={state.loading}
        className={classes.dataGrid}
        density="compact"
      />
    </React.Fragment>
  );
};

export default Contractors;
