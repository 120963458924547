import { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  Grid,
  Box,
} from "@mui/material";
import { useFormik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { getJob } from "api/jobs";
import EmailPreview from "components/EmailPreview";
import Invoice from "../Invoice";
import useInvoices from "hooks/useInvoices";
import JobPickerTable from "components/JobPickerTable";
import dayjs from "dayjs";

const InvoiceValidationSchema = Yup.object().shape({
  // alpha_ref: Yup.string().required("Required"),
  // name: Yup.string().required("Required"),
  // email: Yup.string().email().required("Required"),
  // service_location_id: Yup.number().nullable(),
  // phone: Yup.string().required("Required"),
});

const InvoicesForm = () => {
  const location = useLocation();
  const { crateInvoice } = useInvoices();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [linkedJobs, setLinkedJobs] = useState([]);
  const { state } = location;
  const { data, jobId } = state || {};
  const { taskable: mail } = data || {};

  const formik = useFormik({
    initialValues: {
      invoice_date: dayjs().format('YYYY-MM-DD'),
      items: [],
    },
    validationSchema: InvoiceValidationSchema,
    onSubmit: async (values) => {
      await crateInvoice(values)
        .then((resp) => {
          navigate(`/app/invoices/${resp.data.result.id}`);
        })
        .catch((error) => {
          if (error?.response?.data?.errors) {
            Object.keys(error.response.data.errors).forEach((field) => {
              if (error.response.data.errors[field][0] !== undefined)
                formik.setFieldError(field, error.response.data.errors[field][0]);
            })
          }
        });
    },
  });

  useEffect(() => {
    if(jobId) {
      getJob(jobId).then((res) => {
        formik.setValues({
          ...formik.values,
          jobId,
          membership_id: res.data.membership_id
        })
        setLinkedJobs([ ...linkedJobs, res.data]);
      });
    }
  }, [jobId]);

  useEffect(() => {
    if(!data) {
      navigate('/app/task-queue');
    }
  }, []);


  const handleInvoiceChange = (invoice) => {
    formik.setValues({
      ...formik.values,
      ...invoice
    })
  };

  const handleSearchJob = (jobId, job = null) => {
    formik.setValues({
      ...formik.values,
      jobId,
      membership_id: job?.membership_id,
      contractor_id: job?.contractor_id
    });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {!jobId && (
        <Box>
          <JobPickerTable
            value={formik.values.jobId}
            handleChange={handleSearchJob}
            error={Boolean(formik.errors.jobId)}
            helperText={formik.errors.jobId}
          />
        </Box>
      )}
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={4}>
          {mail && (
            <EmailPreview
              mail={mail}
              file={file}
              onFileSelect={(attachment) => setFile(attachment)}
              attachmentTitle="Please select the attachment containing the invoice:"
            />
          )}
          {file?.uuid && (
            <Box mt={2}>
              <iframe
                title="invoice email attachment preview"
                width="100%"
                height="520px"
                src={`${process.env.REACT_APP_API_DOMAIN}/api/v1/file-preview/${file.uuid}`}
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={8}>
          <Invoice
            invoice={formik.values}
            onChange={handleInvoiceChange}
            withActions
            onChangeTotal={(total) => formik.setFieldValue('total', total)}
            errors={formik.errors}
          />
          <Box mt={2} display="flex" justifyContent="center">
            <LoadingButton
              sx={{ width: '200px' }}
              loading={formik.isSubmitting}
              loadingPosition="center"
              variant="contained"
              onClick={formik.submitForm}
              disabled={formik.isInvalid}
            >
              {formik.values.id ? "Update invoice" : "Create invoice"}
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};


export default InvoicesForm;
