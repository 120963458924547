import {
  SEARCH_USERS_RESULTS,
  SET_USERS_LOADING,
  SEARCH_USERS,
  UPDATE_USER_DATA,
  CHANGE_PAGE,
  CHANGE_PER_PAGE,
} from "redux/actions/usersActions";

const initialState = {
  timestamp: null,
  loading: true,
  query: "",
  results: [],
  meta: {
    current_page: 1,
    from: null,
    last_page: 1,
    per_page: 10,
    to: null,
    total: 0,
  },
};

export default function users(state = initialState, action = {}) {
  switch (action.type) {
    case SEARCH_USERS_RESULTS:
      return {
        ...state,
        results: action.results.result,
        meta: action.results.meta,
        loading: false,
      };
    case SET_USERS_LOADING:
      return { ...state, results: [], loading: action.loading };
    case SEARCH_USERS:
      return { ...state, results: [], query: action.query, loading: true };
    case UPDATE_USER_DATA: {
      const newState = { ...state };
      const slectedUserIndex = newState.results.findIndex(
        (user) => user.id === action.user.id
      );
      newState.results[slectedUserIndex] = action.user;
      return newState;
    }
    case CHANGE_PAGE:
      return {
        ...state,
        meta: {
          ...state.meta,
          current_page: action.page,
        },
      };
    case CHANGE_PER_PAGE:
      return {
        ...state,
        meta: {
          ...state.meta,
          per_page: action.perPage,
        },
      };
    default:
      return state;
  }
}
