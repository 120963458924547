import axios from "utils/axios";

export const SERVICE_LOCATIONS_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/service-locations`;

export const getServiceLocationsRequest = (search) =>
  axios.get(SERVICE_LOCATIONS_ROUTE, { params: { ...search } });

export const getServiceLocationRequest = (id) =>
  axios.get(`${SERVICE_LOCATIONS_ROUTE}/${id}`);

export const createServiceLocationRequest = (data) =>
  axios.post(`${SERVICE_LOCATIONS_ROUTE}/create`, data);

export const updateServiceLocationRequest = (data) =>
  axios.post(`${SERVICE_LOCATIONS_ROUTE}/update`, data);

export const deleteServiceLocationRequest = (id) =>
  axios.post(`${SERVICE_LOCATIONS_ROUTE}/delete`, { id });
