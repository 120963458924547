import { Box } from "@mui/material";
import JobDescription from "./JobDescription";
import JobLocation from "./JobLocation";

const InvoiceJobComponent = ({ job }) => {
  if (!Object.keys(job).length) return null;
  return (
    <>
      <Box mb={4}>
        <JobDescription description={job.description} jobId={job.id} />
      </Box>
      <Box mb={4}>
        <JobLocation location={job.location} />
      </Box>
    </>
  );
};

InvoiceJobComponent.defaultProps = {
  job: {}
};

export default InvoiceJobComponent;
