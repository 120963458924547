import NotFound from "pages/Errors/NotFound";
import React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import Login from "./Login";
import MFA from "./Login/MFAdialog";
import Register from "./Register";
import JobRequest from "./JobRequest";
import MembershipApplication from "./MembershipApplication";
import Checkout from "./Checkout";
import ForgotPassword from "./ForgotPassword/forgotPassword";
import ResetPassword from "./ResetPassword/resetPassword";

export default function Guest() {
  return (
    <Layout>
      <Routes>
        <Route path="/">
          <Route index element={<Login />} />
          <Route path="forgotPassword" element={<ForgotPassword />} />
          <Route path="resetPassword" element={<ResetPassword />} />

          <Route path="register" element={<Register />} />
          <Route path="job-request" element={<JobRequest />} />
          <Route
            path="mfa"
            element={<MFA />}
          />
          <Route
            path="membership-application"
            element={<MembershipApplication />}
          />
          <Route path="checkout/:order_uuid" element={<Checkout />} />
          <Route element={<NotFound />} />
        </Route>
      </Routes>
    </Layout>
  );
}
