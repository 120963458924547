import React, { useEffect } from "react";
import { Grid, IconButton } from "@mui/material";
import InputMask from "react-input-mask";
import FormField from "components/FormField";
import HeadingLine from "components/HeadingLine";
import { Close } from "@mui/icons-material";

const special = ["1st", "2nd", "3rd", "4th", "5th"];

const Member = ({
  index,
  member,
  membership,
  form,
  setForm,
  defaultLocation,
  noMailingAddress,
  loggedIn,
}) => {
  const handleChange = (e) => {
    const members = [...form.members];
    members[index][e.target.name] = e.target.value;
    setForm({ members });
  };

  const setDefaults = () => {
    const mailing_address = { ...form.mailing_address };
    mailing_address.city = {
      id: defaultLocation.city.id,
      label: defaultLocation.city.city,
    };
    mailing_address.state = defaultLocation.state.code;
    mailing_address.zip = defaultLocation.zip;
    setForm({ mailing_address });
  };

  useEffect(() => {
    if (index === 0 && !noMailingAddress) setDefaults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  const handleChangeMembershipFields = (e) => {
    const _membership = { ...form.membership };
    _membership[e.target.name] = e.target.value;
    setForm({ membership: _membership });
  };

  const removeMember = () => {
    const members = [...form.members];
    members.splice(index, 1);
    setForm({ members });
  };

  return (
    <>
      <Grid item md={12} sm={12} xs={12}>
        <HeadingLine
          title={
            index === 0
              ? "Primary Member Details"
              : `${special[index]} Member Details`
          }
          actionButton={
            index !== 0 && (
              <IconButton onClick={() => removeMember()} size="small">
                <Close color="secondary" />
              </IconButton>
            )
          }
        />
      </Grid>
      <Grid item md={3} sm={12} xs={12}>
        <FormField
          label="First Name"
          value={member.first_name.trim()}
          onChange={handleChange}
          name="first_name"
          required
        />
      </Grid>
      <Grid item md={3} sm={12} xs={12}>
        <FormField
          label="Last Name"
          value={member.last_name.trim()}
          onChange={handleChange}
          name="last_name"
          required
        />
      </Grid>
      <Grid item md={6} sm={12} xs={12}>
        <FormField
          label="Email"
          value={member.email}
          onChange={handleChange}
          name="email"
          type="email"
          required
        />
      </Grid>
      <Grid item md={3} sm={12} xs={12}>
        <InputMask
          value={member.home_phone}
          onChange={handleChange}
          mask="(+1) 999-999-9999"
        >
          {() => <FormField label="Home Phone" name="home_phone" />}
        </InputMask>
      </Grid>
      <Grid item md={3} sm={12} xs={12}>
        <InputMask
          value={member.cell_phone}
          onChange={handleChange}
          mask="(+1) 999-999-9999"
        >
          {() => <FormField required label="Cell Phone" name="cell_phone" />}
        </InputMask>
      </Grid>
      <Grid item md={3} sm={12} xs={12}>
        <InputMask
          value={member.work_phone}
          onChange={handleChange}
          mask="(+1) 999-999-9999"
        >
          {() => <FormField label="Work Phone" name="work_phone" />}
        </InputMask>
      </Grid>
      <Grid item md={3} sm={12} xs={12}>
        <FormField
          label="Extension"
          name="work_phone_extension"
          value={member.work_phone_extension}
          onChange={handleChange}
        />
      </Grid>
      <Grid item md={3} sm={12} xs={12}>
        <FormField
          label="Occupation"
          name="occupation"
          value={member.occupation}
          onChange={handleChange}
        />
      </Grid>
      <Grid item md={3} sm={12} xs={12}>
        <FormField
          label="Company"
          name="company"
          value={member.company}
          onChange={handleChange}
        />
      </Grid>
      {index === 0 && !loggedIn && (
        <Grid item md={6} sm={12} xs={12}>
          <FormField
            label="How did you hear about the Club?"
            name="about_club"
            value={membership.about_club}
            onChange={handleChangeMembershipFields}
          />
        </Grid>
      )}
    </>
  );
};

export default Member;
