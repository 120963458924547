import React from "react";
import { useNavigate } from "react-router-dom";
import { TableRow, TableCell, Button } from "@mui/material";
import useAccessControl from "hooks/useAccessControl";

const ContractorRow = ({ data, deleteContractor }) => {
  const navigate = useNavigate();
  const { 
    canUpdateContractor,
    canDeleteContractor } =  useAccessControl()
  return (
    <TableRow
    // key={contractor.uuid}
    >
      <TableCell component="th" scope="row" width="5%">
        {data.contractor_id}
      </TableCell>
      <TableCell component="th" scope="row" width="25%">
        {data.name}
      </TableCell>
      <TableCell component="th" scope="row" width="20%">
        {data.phone}
      </TableCell>
      <TableCell component="th" scope="row" width="25%">
        {data.email}
      </TableCell>
      <TableCell component="th" scope="row" align="right" width="20%" >
       <div style={{display:"flex",gap: "8px",width:"100%"}}>
       { canUpdateContractor() && 
           <Button
           variant="contained"
           color="primary"
           sx={{ mr: 0.5}}
           size="small"
           onClick={() => navigate(`/app/contractors/${data.contractor_id}`)}
         >
           Edit
         </Button>
        }
        { canDeleteContractor() && 
          <Button
          variant="contained"
          color="error"
          size="small"
          onClick={() => deleteContractor(data.contractor_id)}
        >
          Delete
        </Button>
        }
       </div>
      </TableCell>
    </TableRow>
  );
};

export default ContractorRow;
