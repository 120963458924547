import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

const PropertyDetailsComponent = ({ properties }) => {
  return (
    <Table aria-label="simple table" size="small">
      <TableHead>
        <TableRow>
          <TableCell>No.</TableCell>
          <TableCell>State</TableCell>
          <TableCell>City</TableCell>
          <TableCell>Address</TableCell>
          <TableCell>Zip code</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {properties.map((property, index) => (
          <TableRow
            key={`property-${index}`}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {index + 1}
            </TableCell>
            <TableCell>{property.state}</TableCell>
            <TableCell>{property.city.label}</TableCell>
            <TableCell>{property.address}</TableCell>
            <TableCell>{property.zip}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default PropertyDetailsComponent;
