import themePalette from "./themePaletteMode";
const primary = {
  main: "#116089",
  dark: "#116089",
  light: "#116089",
  contrastText: "#ddd",
};

const appTheme = (color, mode) => ({
  palette: {
    type: mode,
    primary: primary,
    secondary: themePalette(color, mode).palette.secondary,
    success: {
      main: "#66bb6a",
      contrastText: "#fff",
    },
    error: {
      main: "#ED1C16",
    },
    action: {
      hover: mode === "dark" ? "rgba(80,80,80, 0.9)" : "rgba(80,80,80, 0.05)",
      hoverOpacity: 0.05,
    },
    background: {
      paper: "#fff",
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: ["Open Sans", "sans-serif"].join(","),
    title: {
      fontWeight: 600,
    },
    body2: {
      fontWeight: 500,
    },
    fontWeightMedium: 600,
  },
  shade: {
    light: "0 10px 15px -5px rgba(62, 57, 107, .07)",
  },
  glow: {
    light: `0 2px 20px -5px ${themePalette(color, mode).palette.primary.main}`,
    medium: `0 2px 40px -5px ${themePalette(color, mode).palette.primary.main}`,
    dark: `0 2px 40px 0px ${themePalette(color, mode).palette.primary.main}`,
  },
  rounded: {
    small: "8px",
    medium: "12px",
    big: "20px",
  },
});

export default appTheme;
