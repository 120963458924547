import React from "react";
import { TableRow, TableCell, Button, Chip, Box } from "@mui/material";
import dayjs from "dayjs";
import { queueColors, taskTypeLabels } from "utils/queue";
import AssignedToDisplay from "components/AssignedToDisplay";
import Priority from "./Priority";
import AssignedTo from "components/AssignedTo";
import useQueue from "hooks/useQueue";
import Toast from "components/Toast";

const TaskRow = ({ data, handleViewDetails, handleChangePriority }) => {
  const { changeTaskUser } = useQueue();
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {data.id}
      </TableCell>
      <TableCell component="th" scope="row">
        {data.title}
      </TableCell>
      <TableCell component="th" scope="row" align="left">
        {taskTypeLabels[data.task_type]}
      </TableCell>
      <TableCell component="th" scope="row" align="left">
        {`${dayjs(data.created_at).format("MM/DD/YYYY hh:mm a")}`}
      </TableCell>
      <TableCell component="th" scope="row" align="left">
        <Chip
          sx={{
            mt: 1,
            justifyContent: "flex-start",
            background: queueColors[data.status],
            color: "#fff",
          }}
          label={data.status}
        />
      </TableCell>
      <TableCell component="th" scope="row">
        <Priority
          value={data.priority}
          onChange={(val) => handleChangePriority(data.id, val)}
        />
      </TableCell>
      <TableCell component="th" scope="row" align="left">
        {/* <AssignedToDisplay userId={data.assigned_to} /> */}
        <Box>
        <AssignedTo
          value={data.assigned_to ?? 0}
          onChange={(e) => {
            changeTaskUser(data.id, e.target.value);
            Toast.fire({
              title: 'Assigned to updated successfully.',
              icon: "success",
            });
            // handleChange({ assigned_to: e.target.value });
          }}
        />
      </Box>
      </TableCell>
      <TableCell component="th" scope="row" align="right">
        <Button
          size="small"
          variant="contained"
          onClick={() => handleViewDetails(data)}
        >
          VIEW
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default TaskRow;
