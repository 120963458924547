import { makeStyles } from "@mui/styles";
import { statuses } from "utils/jobStatuses";

const useStyles = makeStyles(() => ({
  modalHeader: {
    borderBottom: "solid 1px #ddd",
  },
  modalTitle: {
    padding: 0,
  },
  modalSubtitle: {
    color: "gray",
  },
  dialogContent: {
    overflow: "auto",
  },
  actionsLabel: {
    color: "gray",
  },
  whited: {
    "& div": {
      color: "white",
    },
  },
  COMPLETED: { background: statuses.COMPLETED },
  DISPATCH: { background: statuses.DISPATCH },
  ESTIMATE: { background: statuses.ESTIMATE },
  DUPLICATE: { background: statuses.DUPLICATE },
  CANCEL: { background: statuses.CANCEL },
  RESERVICE: { background: statuses.RESERVICE },
  OPEN: { background: statuses.OPEN },
  NEW: { background: statuses.OPEN },
}));

export default useStyles;
