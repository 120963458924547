import React from "react";
import { useNavigate } from "react-router-dom";
import { TableRow, TableCell, Button } from "@mui/material";
import dayjs from "dayjs";
import useUtils from "hooks/useUtils";
import useAccessControl from "hooks/useAccessControl";

const VendorRow = ({ rowNumber, data, onDelete }) => {
  const navigate = useNavigate();
  const { 
    canUpdateVendor,
    canDeleteVendor } =  useAccessControl()
  const {
    utils: { vendor_types },
  } = useUtils();

  return (
    <TableRow key={data.id}>
      <TableCell component="th" scope="row" width="5%">
        {data.id}
      </TableCell>
      <TableCell component="th" scope="row">
        {data.alpha}
      </TableCell>
      <TableCell component="th" scope="row">
        {data.name}
      </TableCell>
      <TableCell component="th" scope="row">
        {vendor_types.find((vendor) => vendor.id === data.vendor_type_id).label}
      </TableCell>
      <TableCell component="th" scope="row">
        {data.phone}
      </TableCell>
      <TableCell component="th" scope="row">
        {data.email}
      </TableCell>
      <TableCell component="th" scope="row">
        {data.last_check ? dayjs(data.last_check).format() : "--"}
      </TableCell>
      <TableCell component="th" scope="row">
        {data.last_voucher ? dayjs(data.last_voucher).format() : "--"}
      </TableCell>
      <TableCell component="th" scope="row" align="right" width="20%" >
       <div style={{display:"flex",gap: "8px",width:"100%"}}>
       { canUpdateVendor() &&
          <Button
          variant="contained"
          color="primary"
          size="small"
          sx={{ mr: 0.5}}
          onClick={() => navigate(`/app/vendors/${data.id}`)}
        >
          Edit
        </Button>
        }
        { canDeleteVendor() &&
          <Button
          variant="contained"
          color="error"
          size="small"
          onClick={() => onDelete(data.id)}
        >
          Delete
        </Button>
        }
       </div>
      </TableCell>
    </TableRow>
  );
};

export default VendorRow;
