import React, { useEffect, useState } from "react";

import { Chip, Avatar } from "@mui/material";
import useUtils from "hooks/useUtils";
import PersonIcon from "@mui/icons-material/Person";

const defaultUser = {
  bgColor: "lightgray",
  txtColor: "black",
  name: "Unassigned",
};

const AssignedToDisplay = ({ userId }) => {
  const { utils, userById } = useUtils();
  const [user, setUser] = useState({});

  useEffect(() => {
    let newUser = { ...userById(userId) };
    if (newUser) {
      setUser(newUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, utils.users]);

  return (
    <>
      <Chip
        sx={{
          mt: 1,
          justifyContent: "flex-start",
          background: user.bgColor ?? defaultUser.bgColor,
          color: user.txtColor ?? defaultUser.txtColor,
        }}
        avatar={
          <Avatar size="small">
            <PersonIcon sx={{ color: "#fff" }} />
          </Avatar>
        }
        label={user.name ?? defaultUser.name}
      />
    </>
  );
};

export default AssignedToDisplay;
